import Papa from "papaparse";
import * as api from "../../admin-api-web";

export function openXlsxFileSelectionDialog(onResolve: (file: File, url: string) => void, onError: (message: string) => void) {
	const input = document.createElement("input");

	input.setAttribute("type", "file");
	input.setAttribute("accept", ".csv");
	input.onchange = () => {
		if (input.files && input.files.length > 0) {
			const file = input.files[0];
			const url = URL.createObjectURL(file);
			onResolve(file, url);
		} else {
			onError("Arquivo selecionado é inválido");
		}
	};

	setTimeout(() => {
        input.click();
    }, 100);
}

interface ICSVUserResult extends Papa.ParseResult {
	data: api.IUserRowJson[];
}

export function getUsersDataFromCSVFile(onDataRead: (students: api.IUserRowJson[]) => void, onError?: (err: string) => void) {
	openXlsxFileSelectionDialog(
		async (file) => {
			Papa.parse(
				file,
				{
					encoding: "ISO-8859-1",
					complete: (results: ICSVUserResult) => {
						const formatedResults = getFormatedDataKeys(results);
						onDataRead(formatedResults);
					},
					header: true,
				},
			);
		}, (err) => {
			if (onError) {
				onError(err);
			}
		},
	);
}

// TODO FIND A BETTER WAY
function getFormatedDataKeys(results: ICSVUserResult): api.IUserRowJson[] {
	const allResults: api.IUserRowJson[] = [];
	for (const data of results.data) {
		const keys = Object.keys(data);
		let keysLength = keys.length;
		const resultsData: any = [];
		while (keysLength--) {
			let key = keys[keysLength];
			const result = data[key];
			key = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			if (key === "orgao expedidor") {
				key = "orgaoExpedidor";
			}
			resultsData[key] = result;
		}
		// tslint:disable-next-line: no-string-literal
		if (resultsData["cpf"] && resultsData["cpf"].length > 0) {
			allResults.push(resultsData);
		}

		console.log(allResults);
	}
	return allResults;
}
