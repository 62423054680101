import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { uiStore } from "../../stores/_rootStore";

// Components
import { IItem } from "../../components/Autosuggest";

export default class InstitutionAutocompleteStore extends VariableChangeHandler {
    // Control
    @observable public loading: boolean = false;

    // Variables
    @observable public institutions: api.Institution[] = [];
    @observable public institution: api.Institution | null = null;

    @computed
    public get autocompleteInstitutions(): IItem[] {
        return this.institutions.map((institution) => ({
            id: institution.id,
            value: `${
                institution.name
            } - ${
                institution.city
            }/${
                institution.state
            }`,
        }));
    }

    @action
    public selectInstitution = async (institutionId: string | null) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!institutionId) {
            this.institution = null;
            this.loading = false;
            return;
        }

        try {
            this.institution = this.institutions.find((institution) => institution.id === institutionId) || await api.getInstitution(institutionId);
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public getInstitutions = async (name: string) => {
        await this.clear();
        this.institutions = (await api.getInstitutions({ name, state: null, city: null, isActive: true }, 0, 20)).results;
    }

    @action
    public clear = async () => {
        this.institutions = [];
        this.institution = null;
    }
}
