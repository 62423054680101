import * as React from "react";
import "./style.scss";

// Mobx
import { observer } from "mobx-react";

// Material-UI
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import strings from "../../../resources/strings";
import { Button, Typography } from "@material-ui/core";

interface IProps {
	header: string[];
	hasEdit: boolean;
	hasDelete: boolean;
	selectable?: boolean;
	onSelectAll?: () => void;
}

@observer
export default class PostsTable extends React.Component<IProps> {
	public render() {
		const { selectable, header, hasEdit, hasDelete, onSelectAll } = this.props;

		return (
			<TableHead>
				<div>
					{onSelectAll && (
						<Button
							variant="contained"
							size="small"
							style={{ marginLeft: "10%" }}
							onClick={onSelectAll}
						>
							<Typography variant="button">
								{strings.cardPrint.table.toolbar.selectAll}
							</Typography>
						</Button>
					)}
				</div>
				<TableRow>
					{	selectable &&
						<TableCell
							align="center"
							padding="dense"
						>
							{strings.table.selected}
						</TableCell>
					}
					{
						hasEdit && hasDelete ?
							<TableCell
								key={"header_cell_editAndDelete"}
								align="center"
								padding="dense"
							>
								{strings.table.editAndDelete}
							</TableCell> :
							hasEdit ?
								<TableCell
									key={"header_cell_edit"}
									align="center"
									padding="dense"
								>
									{strings.table.edit}
								</TableCell> :
								hasDelete ?
									<TableCell
										key={"header_cell_delete"}
										align="center"
										padding="dense"
									>
										{strings.table.delete}
									</TableCell> : <></>
					}
					{
						header.map((headerItem, index) =>
							<TableCell
								key={"header_cell_" + index}
								align="center"
								padding="dense"
							>
								{headerItem}
							</TableCell>,
						)
					}
				</TableRow>
			</TableHead>
		);
	}
}
