import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { action, observable, computed, reaction } from "mobx";

// Components
import { IRowItem } from "../../components/Table/TableRow";

// Stores
import { uiStore, routerStore } from "../_rootStore";
import InstitutionCreateOrEditStore from "./InstitutionCreateOrEditStore";
import InstitutionFilterStore from "./InstitutionFilterStore";

// Resources
import strings from "../../resources/strings";

export default class InstitutionStore extends VariableChangeHandler {
    // Sub Stores
    public institutionCreateOrEditStore: InstitutionCreateOrEditStore = new InstitutionCreateOrEditStore();
    public institutionFilterStore: InstitutionFilterStore = new InstitutionFilterStore();

    // Control
    @observable public loading: boolean = false;
    @observable public pageOffset: number = 0;

    // Variables
    @observable public institutions: api.Institution[] = [];

    // Methods
    constructor() {
        super();

        reaction(() => this.institutionCreateOrEditStore.selectedInstitution, (selectedInstitution) => {
            if (selectedInstitution) {
                const index = this.institutions.findIndex((institution) => institution.id === selectedInstitution.id);

                if (index !== -1) {
                    this.institutions[index] = selectedInstitution;
                } else {
                    this.institutions.push(selectedInstitution);
                }
            }
        });

        reaction(() => this.institutionFilterStore.filter, async (filter) => {
            this.clear();
            await this.getInstitutions();
        });
    }

    // Pagination
    @observable public rowsPerPage: number = 10;
    @observable public totalOfRows: number = 25;

    @computed
    public get tableRowRange() {

        const page = this.pageOffset + 1;
        let end = this.rowsPerPage * page;
        const start = end - this.rowsPerPage;

        if (end > this.totalOfRows) {
            end =  this.totalOfRows;
        }

        return `${start} - ${end} de ${this.totalOfRows}`;
    }

    @computed
    public get tableHeader(): string[] {
        return [
            strings.institutions.table.header.name,
            strings.institutions.table.header.stateUF,
            strings.institutions.table.header.city,
            strings.institutions.table.header.isActive,
            strings.institutions.table.header.id,
        ];
    }

    @computed
    public get tableRows(): IRowItem[] {
        return this.institutions.map((institution) => ({
            id: institution.id,
            data: [
                {
                    value: institution.name,
                },
                {
                    value: institution.state,
                },
                {
                    value: institution.city,
                },
                {
                    value: institution.isActive ? strings.yes : strings.no,
                },
                {
                    value: institution.id,
                },
            ],
        }));
    }

    @action
    public getInitialInstitution = async () => {
        if (this.institutions.length === 0) {
            this.pageOffset = 0;
            await this.getInstitutions(0);
        }
    }

    @action
    public fetchData = async () => {
        await this.getInstitutions(this.pageOffset);
    }

    @action
    public getInstitutions = async (pageOffset?: number) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!pageOffset) {
            pageOffset = 0;
        }

        if (pageOffset < 0) {
            this.loading = false;
            return;
        }

        try {
            const newInstitutions = await api.getInstitutions(this.institutionFilterStore.filter, pageOffset, this.rowsPerPage);

            this.totalOfRows = newInstitutions.total;

            if (newInstitutions.results.length > 0) {
                this.institutions = newInstitutions.results;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public clear = () => {
        this.institutions = [];
        this.pageOffset = 0;
    }

    @action
    public nextPage = async () => {
        await this.getInstitutions(this.pageOffset + 1);
    }

    @action
    public previousPage = async () => {
        await this.getInstitutions(this.pageOffset - 1);
    }

    @action
    public selectInstitution = async (institutionId?: string | null) => {
        if (this.loading)
            return;

        try {
            this.loading = true;
            this.institutionCreateOrEditStore.selectedInstitution = null;

            if (institutionId) {
                const selectedInstitution = this.institutions.find((institution) => institution.id === institutionId) || await api.getInstitution(institutionId);
                this.institutionCreateOrEditStore.selectedInstitution = Object.create(selectedInstitution);
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public createOrEditInstitution = async (institutionId?: string | null) => {
        await this.selectInstitution(institutionId);

        if (this.institutionCreateOrEditStore.selectedInstitution) {
            routerStore.push(`/dashboard/institutions/edit/${institutionId}`);
        } else {
            routerStore.push("/dashboard/institutions/new");
        }
    }
}
