import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import PaymentStore from "../../../../stores/PaymentStore";

// Components
import Table from "../../../../components/Table";

// Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

interface IProps {
	match: Match<{ userId: string }>;
	paymentStore: PaymentStore;
}

@inject("paymentStore")
@observer
export default class Payments extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.paymentStore.fetchInitialData();
	}

	public render() {
		const { paymentStore } = this.props;
		return (
		<div></div>
		// 	<Table
		// 		title={strings.payments.table.title}
		// 		data={paymentStore.tableRows}
		// 		loading={paymentStore.loading}
		// 		nextPage={paymentStore.nextPage}
		// 		header={paymentStore.tableHeader}
		// 		pageOffset={paymentStore.pageOffset}
		// 		prevPage={paymentStore.previousPage}
		// 	/>
		// );
		);
	}
}
