import * as api from "../admin-api-web";
import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
    ptBR: {
        pendingRequestCardPrint: "Pendente",
        yes: "Sim",
        no: "Não",
        errors: {
			minimunChars: (fieldName: string, chars: number) => `É necessário preencher o campo "${fieldName}" com pelo menos ${chars} caracteres`,
			error: "Erro",
            certificatesNotFound: "Certificados não foram encontrados...",
            invalidCPF: "CPF inválido",
        },
        components: {
            image: {
                addImage: "Adicione uma imagem",
                cropImage: "Recortar",
                cancel: "Cancelar",
                error: {
                    missingCrop: "Corte da imagem não foi definido.",
                },
            },
        },
        format: {
            date: "dd/MM/yyyy",
        },
        dialogs: {
            logout: {
                title: "Logout",
                message: "Tem certeza que deseja sair?",
            },
            fieldValidation: {
                title: "Validação",
                message: (fieldName: string) => `O campo "${fieldName}" está incorreto.`,
            },
        },
        navbar: {
            payments: "Pagamentos",
            cardPrints: "Impressões",
            home: "Home",
            students: "Estudantes",
            courses: "Cursos",
            institutions: "Instituições",
            logout: "Sair",
            team: "Equipe",
            certificates: "Certificados",
        },
        preRegisterAdminUser: {
            contract: "Pelo presente termo, responsabilizo-me por todos os dados que cadastrar no sistema da FEC - Força Estudantil Consciente e pela emissão das respectivas carteiras de estudante. Comprometo-me em assegurar a autenticidade de todos os dados cadastrados, bem como preservar a privacidade dos mesmos, estando sujeito às sanções penais dos art. 171 e 298 do Código Penal. Tenho ciência, também, de que os dados cadastrados se baseiam em documentos fornecidos pelos requerentes, e que, portanto, deverão ser guardados pelo prazo mínimo de 24 meses para fins de eventuais necessidades.",
            buttons: {
                create: "Criar",
            },
        },
        login: {
            email: "Email",
            password: "Password",
            loginButton: "Entrar",
            resetPasswordButton: "Recuperar Senha",
            createAcountLink: "Ainda não possui cadastro?",
        },
        home: {
            headers: ["", "Anual", "Mensal", "Diário"],
            title: "Dados de Impressões",
        },
        recoverPassword: {
            confimationDialog: {
                title: "Recuperção de Senha",
                message: "O email de recuperação de senha foi enviado com sucesso",
            },
            recoverPasswordButton: "Recuperar Senha",
            backButton: "Voltar",
            email: "Email",
        },
        cardPrint: {
            filter: {
                isReprint: "Reimpressão",
            },
            confirmPrint: {
                confirmPrint: {
                    title: "Confirmar impressão",
                    button: "Imprimir",
                    dialog: {
                        withoutAvatar: {
                            title: "Imprimir",
                            message: "ATENÇÃO, ALGUNS ESTUDANTES NÃO POSSUEM FOTO! Tem certeza que deseja imprimir as carteirinhas selecionadas?",
                        },
                        withAvatar: {
                            title: "Imprimir",
                            message: "Tem certeza que deseja imprimir as carteirinhas selecionadas?",
                        },
                    },
                },
                confirmRequest: {
                    title: "Confirmar solicitação",
                    button: "Solicitar",
                    dialog: {
                        withoutAvatar: {
                            title: "Confirmar Solicitação",
                            message: "ATENÇÃO, ALGUNS ESTUDANTES NÃO POSSUEM FOTO! Tem certeza que deseja confirmar solicitação das carteirinhas selecionadas?",
                        },
                        withAvatar: {
                            title: "Confirmar Solicitação",
                            message: "Tem certeza que deseja confirmar solicitação das carteirinhas selecionadas?",
                        },
                    },
                },
            },
            table: {
                title: "Impressões",
                header: {
                    student: "Estudante",
                    type: "Tipo",
                    printNumber: "Reimpressão",
                    printedAt: "Impresso em",
                    vendor: "Promotor",
                    status: "Impressão",
                    payment: "Pagamento",
                    useCode: "Código de Uso",
                },
                csvHeader: {
                    name: "Nome",
                    email: "Email",
                    phone: "Telefone",
                    institutionName: "Instituição",
                    courseName: "Curso",
                    seller: "Promotor",
                    printStatus: "Impressão",
                    printDate: "Data da Impressão",
                    isReprint: "Reimpressão",
                },
                toolbar: {
                    selectAll: "Selecionar Tudo",
                    setCardPrintPaid: {
                        text: "PAGO",
                        tooltip: "Registra cartões selecionados como PAGO",
                    },
                    setCardPrintNotPaid: {
                        text: "NÃO PAGO",
                        tooltip: "Remove pagamento dos cartões selecionados",
                    },
                    printRequested: {
                        text: "IMPRIMIR SOLICITAÇÃO",
                        tooltip: "Imprime Cards que estão em solicitação",
                    },
                },
            },
            print: {
                sucess: "Cartões enviados para impressão",
                error: "Ocorreu um erro ao enviar cartões para impressão",
                confirmImpressionDialog: {
                    title: "Impressão",
                    message: "Tem certeza que deseja imprimir as carteirinhas selecionadas?",
                },
                confirmImpressionDialogWithAlert: {
                    title: "Impressão",
                    message: "ATENÇÃO, ALGUNS ESTUDANTES NÃO POSSUEM FOTO! Tem certeza que deseja imprimir as carteirinhas selecionadas?",
                },
                print: "Imprimir",
                fields: {
                    cpf: "CPF:",
                    rg: "RG:",
                    birthDate: "Data Nasc:",
                    registration: "Matrícula:",
                    code: "Código de uso:",
                },
                prePrint: "Seus documentos estao prontos para serem impressos.",
                cancel: "Cancelar",
            },
        },
        payments: {
            createDialog: (cardPrint: api.CardPrint) => ({
                title: `Criação de pagamento`,
                message: `Deseja criar pagamento para a impressão ${cardPrint.id[2]} do usuário ${cardPrint.name}`,
            }),
            editDialog: (payment: api.Payment, newStatus: api.PaymentStatus) => ({
                title: "Alterar situação de pagamento",
                message: `Tem certeza que deseja que deseja alterar a situação do
                     pagamento ${payment.id} de ${api.translatePaymentStatus(payment.status)} para ${api.translatePaymentStatus(newStatus)}`,
            }),
            table: {
                title: "Pagamentos",
                header: {
                    printId: "Impressão",
                    value: "Valor",
                    createdAt: "Emitido em",
                    status: "Situação",
                },
            },
        },
        userLogs: {
            table: {
                title: (adminUser?: api.AdminUser | null) => `Ações ${adminUser ? `de ${adminUser.name}` : "da Equipe" }`,
                header: {
                    name: "Nome",
                    action: "Ação",
                    date: "Data",
                    time: "Hora",
                },
            },
            csv: {
                generate: "Gerar Relatório para Download",
                generateTip: "Gerar o conteúdo do relatório para download.",
                download: "Baixar Relatório",
                await: "Gerando Relatório",
                downloadTip: "Baixar conteúdo do relatório em formato CSV.",
                snackBar: "Seu relatório está pronto para download",
            },
        },
        adminUsers: {
            filter: {
                type: "Cargo",
                email: "Email",
                name: "Nome",
            },
            roles: {
                superAdmin: "Super Administrador",
                manager: "Promotor 3",
                coordinator:  "Promotor 2",
                seller:  "Promotor 1",
            },
            editor: {
                title: (onEditMode: boolean) => onEditMode ? "Editar Membro" : "Criar Membro",
                superior: (type: api.AdminUserType) => type === api.AdminUserType.coordinator ? "Promotor 3" : "Promotor 2",
                role: "Cargo",
                superAdmin: "Super Administrador",
                name: "Nome",
                email: "Email",
                password: "Senha",
                phone: "Celular",
            },
            small: {
                limit: "Sua senha deve conter no mínimo 6 caracteres",
            },
            table: {
                title: "Equipe",
                pendingAnalysis: "Equipe com aprovação pendente",
                empty: "Nenhum Promotor disponível",
                header: {
                    name: "Nome",
                    email: "Email",
                    phone: "Celular",
                    role: "Cargo",
                    canManageCourses: "Administra Cursos",
                    canManageInstitutions: "Administra Instituições",
                    canManageStudents: "Administra Estudantes",
                    canPrintCard: "Imprime Carteira",
                    canManagePayments: "Administra Pagamentos",
                },
            },
            wantToApproveAdminUserDialog: {
                title: "Aprovar Promotor",
                message: "Deseja aprovar o promotor selecionado?",
            },
            wantToDisapproveAdminUserDialog: {
                title: "Reprovar Promotor",
                message: "Deseja reprovar o promotor selecionado?",
            },
            errors: {
                adminUserNotFound: "Membro da equipe não foi encontrado.",
                approvalFailed: "Não foi possivel realizar a aprovação",
                refuseFailed: "Não foi possivel reprovar o promotor",
            },
            yes: "Sim",
            no: "Não",
        },
        users: {
            filter: {
                rg: "RG",
                uf: "UF",
                cpf: "CPF",
                name: "Nome",
                email: "E-mail",
                courseId: "Curso",
                sellerId: "Promotor",
                schooling: "Escolaridade",
                institutionId: "Instituição",
                studentId: "Matrícula",
                addFilter: "Adicionar um filtro",
                status: "Impressão",
                editedAt: "Data da última atualização",
            },
            table: {
                request: {
                    title: "SOLICITAR IMPRESSÃO",
                    tooltip: "Marca Estudantes selecionados como pendentes para impressão",
                },
                import: {
                    title: "IMPORTAR",
                    tooltip: "Importar Estudantes através de arquivo CSV",
                },
                export: {
                    button:  "Exportar",
                    tooltip: "Exporta Estudantes através de um arquivo CSV",
                },
                selectAll: {
                    button:  "SELECIONAR TODOS",
                    tooltip: "Seleciona todas as linhas da página atual",
                },
                editTable: {
                    title: "EDITAR TABELA",
                    tooltip: "Editar as linhas da coluna",
                    save: "Salvar",
                    availableColumns: "Colunas Disponíveis",
                    selectedColumns: "Colunas Selecionadas",
                },
                buttons: {
                    selected: (numOfSelected: number) => numOfSelected > 1 ? `${numOfSelected} Selecionados` : `${numOfSelected} Selecionado`,
                },
                title: "Estudantes",
                header: {
                    cardPrint: "Impressão",
                    seller: "Promotor",
                    selected: "Selecionado",
                    avatar: "Foto",
                    institution: "Instituição",
                    course: "Curso",
                    name: "Nome",
                    birthday: "Nascimento",
                    email: "Email",
                    phone: "Telefone",
                    cpf: "CPF",
                    rg: "RG",
                    schooling: "Escolaridade",
                    studentId: "Matrícula",
                    address: "Endereço",
                    city: "Cidade",
                    state: "Estado",
                    neighborhood: "Bairro",
                    street: "Rua",
                    cep: "CEP",
                    number: "Numero",
                    rgInstitution: "Orgão Expedidor",
                    uf: "Uf",
                    lastPrint: "Última Impressão",
                },
            },
            createOrEdit: {
                title: (isEdit: boolean) => {
                    return  isEdit ? "Editar usuário" : "Criar usuário";
                },
                avatar: {
                    title: "Foto",
                },
                login: {
                    title: "Informações de Acesso",
                    email: "Email",
                    password: "Password",
                },
                personalInfo: {
                    title: "Informações Pessoais",
                    name: "Nome",
                    phone: "Celular",
                    birthday: "Data de Nascimento",
                },
                documents: {
                    title: "Documentos",
                    cpf: "CPF",
                    rgNumber: "Número RG",
                    rgInstitution: "Orgão Expedidor",
                },
                school: {
                    title: "Ensino",
                    institution: "Instituição",
                    course: "Curso",
                    schooling: "Escolaridade",
                    studentId: "Número de Matrícula",
                },
                date: {
                    invalidDateMessage: "Data inválida",
                },
                address: {
                    title: "Endereço",
                    cep: "CEP",
                    street: "Rua",
                    number: "Número",
                    neighborhood: "Bairro",
                    city: "Cidade",
                    state: "Estado",
                    uf: "UF",
                },
                buttons: {
                    save: "Salvar",
                    saveAndPrint: "Salvar e Imprimir",
                    new: "Novo",
                    back: "Voltar",
                },
                checkbox: {
                    ingnoreCPFValidation: "Aluno não possui CPF",
                },
            },
        },
        filter: {
            textField: {
                apply: "Aplicar",
            },
            autosuggest: {
                apply: "Aplicar",
            },
            enumPicker: {
                apply: "Aplicar",
            },
        },
        courses: {
            filter: {
                name: "Nome",
                isActive: "Ativo",
            },
            table: {
                title: "Cursos",
                header: {
                    id: "ID",
                    name: "Nome",
                    isActive: "Ativo",
                },
            },
            createOrEdit: {
                title: "Novo curso",
                info: {
                    title: "Dados",
                    name: "Nome do curso",
                },
                isActive: "Está ativo?",
                button: "Salvar",
            },
        },
        institutions: {
            filter: {
                name: "Nome",
                state: "Estado",
                city: "Cidade",
                isActive: "Ativo",
            },
            table: {
                title: "Instituições de Ensino",
                header: {
                    id: "ID",
                    name: "Nome",
                    stateUF: "Estado",
                    city: "Cidade",
                    isActive: "Ativo",
                },
            },
            createOrEdit: {
                title: "Nova Instituição",
                info: {
                    title: "Dados",
                    name: "Nome",
                    stateUF: "Estado",
                    city: "Cidade",
                },
                button: "Salvar",
            },
        },
        lists: {
            noMoreResults: "Sem mais resultados.",
        },
        table: {
            createTooltip: "Cria elemento da tabela",
            selected: "Selecionado",
            create: "Criar",
            editAndDelete: "Editar e Apagar",
            edit: "Editar",
            delete: "Apagar",
        },
        enum: {
            userPrintStatus: {
                printed: "Impresso",
                requested: "Solicitado",
                none: "Pendente",
            },
            PaymentStatus: {
                paid: "Pago",
                canceled: "Cancelado",
                pending: "Pendente",
            },
            PrintStatus: {
                printed: "Impresso",
                requested: "Solicitado",
            },
            PrintStatusPending: {
                printed: "Impresso",
                requested: "Solicitado",
                pending: "Pendente",
            },
            CardPrintType: {
                full: "Completo",
                webOnly: "Virtual apenas",
            },
            Schooling: {
                earlyChildhoodEducation: "Educação Infantil",
                elementaryEducation: "Ensino Fundamental",
                secondaryEducation: "Ensino Médio",
                higherEducation: "Ensino Superior",
				postgraduate: "Pós-graduação",
				technicalEducation: "Ensino Técnico",
                master: "Mestrado",
                doctorate: "Doutorado",
            },
            AdminUserType: {
                manager: "Promotor 3",
                coordinator:  "Promotor 2",
                seller:  "Promotor 1",
            },
            StateUF: {
                AC: "AC",
                AL: "AL",
                AP: "AP",
                AM: "AM",
                BA: "BA",
                CE: "CE",
                DF: "DF",
                ES: "ES",
                GO: "GO",
                MA: "MA",
                MT: "MT",
                MS: "MS",
                MG: "MG",
                PA: "PA",
                PB: "PB",
                PR: "PR",
                PE: "PE",
                PI: "PI",
                RJ: "RJ",
                RN: "RN",
                RS: "RS",
                RO: "RO",
                RR: "RR",
                SC: "SC",
                SP: "SP",
                SE: "SE",
                TO: "TO",
            },
            UsersColumnType: {
                cardPrint: "Impressão",
                seller: "Promotor",
                avatar: "Foto",
                name: "Nome",
                institution: "Instituição",
                course: "Curso",
                email: "Email",
                phone: "Celular",
                cpf: "CPF",
                schooling: "Escolaridade",
                studentId: "Matrícula",
                rg: "RG",
                birthday: "Data de Nascimento",
            },
        },
        register: {
            title: "Cadastro de Promotor",
        },
    },
});

api.setStrings(strings);

export default strings;
