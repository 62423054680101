import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../stores/AuthStore";

// Components
import Loading from "../../components/Loading";

interface IProps {
    authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Auth extends React.Component<IProps> {
    public componentDidMount = async () => {
        await this.props.authStore.authenticate();
        await this.props.authStore.openDashboardIfAuthenticated();
    }

    public render() {
        return (
            <div className="loader-container">
                <Loading/>
            </div>
        );
    }
}
