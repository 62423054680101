import * as React from "react";
import "./style.scss";
import strings from "../../resources/strings";

// Mobx
import { observer } from "mobx-react";

// Material-UI
import MaterialTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import MAddIcon from "@material-ui/icons/Add";
import MButton from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import TableHeader from "./TableHeader";
import TableRow, { IRowItem } from "./TableRow";
import TableFooter from "./TableFooter";
import { TableCell } from "@material-ui/core";

interface IProps {

	title: string;
	header: string[];
	data: IRowItem[];
	pageOffset: number;
	loading: boolean;

	// Component Pieces
	toolbar?: React.ReactNode;
	headerView?: React.ReactNode;

	/**
	 *  The goal of the selectable table is to
	 * 	to make it possible to select rows of the table.
	 * 	When some row is selected it may be good to set the
	 * 	tool bar to the activeSelectionToolbar if it exists
	 */
	activeSelectionToolbar?: React.ReactNode;
	onRowSelect?: (id: string) => void;

	nextPage?: () => void;
	prevPage?: () => void;
	selectAll?: () => void;
	onAddClick?: () => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string) => void;
	onEditClick?: (id: string) => void;
	onDesaprovalClick?: (id: string) => void;
	onClick?: (id: string) => void;

	/**
	 * This section is about the footer returning info about the total
	 * of results
	 */

	 rowsPerPageConfigs?: {
		rowsPerPage: number;
		onRowsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
		refetchData: () => void;
		info: string;
	 };
}

@observer
export default class Table extends React.Component<IProps> {
	public render() {
		const {
			pageOffset, nextPage, prevPage,
			loading, data, onAddClick,
			onEditClick, onDeleteClick,
			title, header, headerView,
			toolbar, onDetailsClick,
			activeSelectionToolbar,
			onClick, onDesaprovalClick,
			selectAll,
		} = this.props;

		const selectionIsActive = !!data.find((row) => !!row.selected);

		return (
			<Paper>
				<LinearProgress
					style={{
						visibility: loading ? "visible" : "hidden",
						borderRadius: "20px",
					}}
				/>
				<Toolbar
					style={{
						display: "flex" ,
						justifyContent: "space-between",
						boxSizing: "border-box",
						width: "100%",
					}}
				>
					<Typography variant="h6">
						{ title }
					</Typography>

					<div className="tableTools">
						{
							onAddClick && !selectionIsActive &&
							<Tooltip title={strings.table.createTooltip}>
								<MButton
									style={{ marginRight: 10, padding: "2px 14px" }}
									onClick={() => onAddClick()}
									variant="contained"
								>
									<MAddIcon style={{ height: 20}}/>
									{strings.table.create}
								</MButton>
							</Tooltip>
						}
						{ selectionIsActive ? activeSelectionToolbar : toolbar }
					</div>
				</Toolbar>

				{headerView}

				<div className="overflowX">
					<MaterialTable style={{minWidth: "600px"}}>
						<TableHeader
							onSelectAll={selectAll}
							header={header}
							hasEdit={!!onEditClick}
							hasDelete={!!onDeleteClick}
							selectable={!!this.props.onRowSelect}
						/>
						<TableBody>
						{
							data.map((item, index) => (
								<TableRow
									onRowSelected={this.props.onRowSelect}
									key={item.id + "-row-" + index}
									rowItem={item}
									onDeleteClick={onDeleteClick}
									onEditClick={onEditClick}
									onDetailsClick={onDetailsClick}
									onDesaprovalClick={onDesaprovalClick}
									onClick={onClick}
								/>
							))
						}
						</TableBody>
					</MaterialTable>
				</div>
				{nextPage && (
					<TableFooter
						onNextClick={nextPage}
						onPrevClick={prevPage}
						pageOffset={pageOffset + 1}
						rowsPerPageConfigs={this.props.rowsPerPageConfigs}
					/>
				)}
			</Paper>
		);
	}
}
