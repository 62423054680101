import * as React from "react";
import "./style.scss";

// Stores
import { observer } from "mobx-react";

// Components
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { CSVLink } from "react-csv";
import CardPrintStore from "../../../../../stores/CardPrintStore";

// Icons
import DownloadIcon from "@material-ui/icons/VerticalAlignBottom";

// Resources
import strings from "../../../../../resources/strings";

interface IProps {
	cardPrintStore: CardPrintStore;
}

@observer
export default class CardPrintTableToolbar extends React.Component<IProps> {
	public render() {
		const { cardPrintStore } = this.props;
		return (
			<div style={{ width: "100%" }}>
				{/* <div style={ cardPrintStore.loadedCSV ? { display: "none"} : { display: "block"} }>
					<Tooltip title={ strings.userLogs.csv.generateTip }>
						<Button variant="contained" size="small" onClick={() => this.props.cardPrintStore.getCSVData()}>
							<Typography variant="button">{ strings.userLogs.csv.generate }</Typography>
						</Button>
					</Tooltip>
				</div>

				<CSVLink
					style={ cardPrintStore.loadedCSV ? { display: "block"} : { display: "none"} }
					filename={"impressoes.csv"}
					headers={cardPrintStore.csvHeader}
					data={cardPrintStore.csvData} >
					<Tooltip title={ strings.userLogs.csv.downloadTip }>
						<Button variant="contained" color="primary" size="small">
							<Typography variant="button">{ strings.userLogs.csv.download }</Typography>
							<DownloadIcon style={{ marginLeft: "8px" }} />
						</Button>
					</Tooltip>
				</CSVLink> */}
			</div>
		);
	}
}
