import * as React from "react";
import "./style.scss";

// Resources
import strings from "../../../../../resources/strings";
import * as api from "../../../../../admin-api-web";

// Stores
import { observer } from "mobx-react";

// Components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import SelectionIndicator from "../../../../../components/SelectionIndicator";

// Icons
import AuthStore from "../../../../../stores/AuthStore";

interface IProps {
	onDeSelectAll: () => void;
	onRequestClick: () => void;
	numberOfSelected: number;
	authStore: AuthStore;
}

@observer
export default class ActiveSelectionUserTableToolbar extends React.Component<IProps> {
	public render() {
		const { numberOfSelected, authStore } = this.props;
		const {adminUser} = authStore;

		return (
			<div className="activeSelectionUserTableToobar">
				<SelectionIndicator
					numOfSelected={numberOfSelected}
					onCloseClick={this.props.onDeSelectAll}
				/>
				<Tooltip
					title={strings.users.table.request.tooltip}>
					<Button onClick={this.props.onRequestClick} variant="contained" size="small">
						<Typography variant="button">{strings.users.table.request.title}</Typography>
					</Button>
				</Tooltip>
			</div>
		);
	}
}
