import * as React from "react";
import "./style.scss";

// Components
import Navbar from "../../components/Navbar";
import { Route, Switch } from "react-router-dom";
import Alert from "../../components/Alert";
import Dialog from "../../components/Dialog";
import Snackbar from "../../components/SnackBar";
import LoadingDialog from "../../components/LoadingDialog";

// Stores
import { observer, inject } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import AuthStore from "../../stores/AuthStore";
import UIStore from "../../stores/UIStore";

// Containers
import Home from "./Home";
import Users from "./Users";
import Courses from "./Courses";
import Institutions from "./Institutions";
import Team from "./Team";
import Payments from "./Payments";
import CardPrint from "./CardPrint";

interface IProps {
	routerStore: RouterStore;
	authStore: AuthStore;
	uiStore: UIStore;
}

@inject("authStore", "routerStore", "uiStore")
@observer
export default class Dashboard extends React.Component<IProps> {
	public componentWillMount = async () => await this.props.authStore.authenticate();

	public render() {
		const { uiStore, authStore, routerStore } = this.props;

		return (
			<div className="dashboard">
				<Navbar
					logout={authStore.logout}
					authStore={authStore}
					routerStore={routerStore}
				/>
				<div className="pageContainers">
					<Switch>
						<Route exact path="/dashboard" component={Home} />
						<Route path="/dashboard/team" component={Team} />
						<Route path="/dashboard/courses" component={Courses} />
						<Route path="/dashboard/students" component={Users} />
						<Route path="/dashboard/institutions" component={Institutions} />
						<Route path="/dashboard/cardPrints" component={CardPrint} />
						<Route path="/dashboard/payments" component={Payments} />
					</Switch>
				</div>

				<Alert uiStore={uiStore} />
				<Dialog uiStore={uiStore} />
				<LoadingDialog uiStore={uiStore}/>
				<Snackbar
					open={uiStore.snackbarOpen}
					message={uiStore.snackBarMessage}
				/>
			</div>
		);
	}
}
