import * as React from "react";
import { observer } from "mobx-react";
import "./style.scss";

import * as api from "../../admin-api-web";

// Components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// MARK: Icons
import MenuIcon from "@material-ui/icons/Menu";
import FaceIcon from "@material-ui/icons/Face";
import School from "@material-ui/icons/School";
import BookIcon from "@material-ui/icons/Book";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SupervisorIcon from "@material-ui/icons/SupervisorAccountRounded";
import PrintIcon from "@material-ui/icons/Print";
import PaymentIcon from "@material-ui/icons/Payment";

// MARK: Stores
import { RouterStore } from "mobx-react-router";
import AuthStore from "../../stores/AuthStore";
import strings from "../../resources/strings";

interface IProps {
    routerStore: RouterStore;
    authStore: AuthStore;
    logout: () => void;
}

interface IState {
    open: boolean;
    teamOpen: boolean;
}

@observer
export default class Navbar extends React.Component<IProps, IState> {
    public componentWillMount = () => this.setState({ open: false });
    private handleDrawerOpen = () => this.setState({ open: true });
    private handleDrawerClose = () => this.setState({ open: false });

    public onItemClick = (route: string) => {
        this.props.routerStore.push(route);
        this.handleDrawerClose();
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            open: false,
            teamOpen: false,
        };
    }

    public render() {

        const { authStore } = this.props;

        return (
            <>
                <AppBar position="fixed">
                    <Toolbar className="toolbar">
                        <IconButton color="inherit" aria-label="Menu" onClick={this.handleDrawerOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Typography style={{ marginLeft: 10 }} color="inherit" variant="h5">
                            FEC Dashboard
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="persistent"
                    anchor="left"
                    className="sidedrawer"
                    open={this.state.open}
                >
                    <div className="closeButtonContainer">
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <List>
                        <ListItem button onClick={() => this.onItemClick("/dashboard")}>
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary={strings.navbar.home} />
                        </ListItem>

                        {authStore.hasPermission(api.Permission.manageStudents) &&
                            <ListItem button onClick={() => this.onItemClick("/dashboard/students")}>
                                <ListItemIcon><FaceIcon /></ListItemIcon>
                                <ListItemText primary={strings.navbar.students} />
                            </ListItem>
                        }

                        {authStore.hasPermission(api.Permission.manageCourses) &&
                            <ListItem button onClick={() => this.onItemClick("/dashboard/courses")}>
                                <ListItemIcon><BookIcon /></ListItemIcon>
                                <ListItemText primary={strings.navbar.courses} />
                            </ListItem>
                        }
                        {authStore.hasPermission(api.Permission.manageInstitutions) &&
                            <ListItem button onClick={() => this.onItemClick("/dashboard/institutions")}>
                                <ListItemIcon><School /></ListItemIcon>
                                <ListItemText primary={strings.navbar.institutions} />
                            </ListItem>
                        }
                        {authStore.isSuperUser &&
                                <ListItem button onClick={() => this.onItemClick("/dashboard/team")}>
                                    <ListItemIcon>
                                        <SupervisorIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={strings.navbar.team} />
                                </ListItem>
                        }
                        {authStore.hasPermission(api.Permission.printCard) &&
                            <ListItem button onClick={() => this.onItemClick("/dashboard/cardPrints")}>
                                <ListItemIcon>
                                    <PrintIcon />
                                </ListItemIcon>
                                <ListItemText inset primary={strings.navbar.cardPrints} />
                            </ListItem>
                        }
                        {/* {authStore.hasPermission(api.Permission.managePayments) &&
                            <ListItem button onClick={() => this.onItemClick("/dashboard/payments")}>
                                <ListItemIcon><PaymentIcon /></ListItemIcon>
                                <ListItemText primary={strings.navbar.payments} />
                            </ListItem>
                        } */}
                        <ListItem button onClick={this.props.logout}>
                            <ListItemIcon><KeyboardReturn /></ListItemIcon>
                            <ListItemText primary={strings.navbar.logout} />
                        </ListItem>
                    </List>
                </Drawer>
            </>
        );
    }
}
