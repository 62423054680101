import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";

// Components
import { Route, Switch } from "react-router-dom";
import TeamMemberEditor from "./TeamMemberEditor";
import TeamTable from "./TeamTable";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Team extends React.Component<IProps> {
	public async componentDidMount() {
		this.props.authStore.verifySuperUser();
	}

	public render() {
		return (
			<Switch>
				<Route exact path="/dashboard/team/" component={TeamTable} />
				<Route exact path="/dashboard/team/new" component={TeamMemberEditor} />
				<Route exact path="/dashboard/team/edit/:id" component={TeamMemberEditor} />
			</Switch>
		);
	}
}
