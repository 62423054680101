import * as React from "react";
import "./style.scss";

// Resources
import strings from "../../../../../resources/strings";

// Stores
import { observer } from "mobx-react";

// Components
import SelectionIndicator from "../../../../../components/SelectionIndicator";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps {
	numberOfSelected: number;
	onDeSelectAll: () => void;
	onPrintRequested: () => void;
	setSelectedToPaid: () => void;
	setSelectedToPending: () => void;
}

@observer
export default class ActiveSelectionUserTableToolbar extends React.Component<IProps> {
	public render() {

		const { numberOfSelected } = this.props;

		return (
			<div className="activeSelectionUserTableToobar">
				<SelectionIndicator
					numOfSelected={numberOfSelected}
					onCloseClick={this.props.onDeSelectAll}
				/>
				<Tooltip title={strings.cardPrint.table.toolbar.printRequested.tooltip}>
					<Button
						onClick={this.props.onPrintRequested}
						variant="contained"
						size="small"
						style={{ marginRight: "8px" }}
					>
						<Typography variant="button">
							{strings.cardPrint.table.toolbar.printRequested.text}
						</Typography>
					</Button>
				</Tooltip>
				<Tooltip title={strings.cardPrint.table.toolbar.setCardPrintNotPaid.tooltip}>
					<Button
						onClick={this.props.setSelectedToPending}
						variant="contained"
						size="small"
						style={{ marginRight: "8px" }}
					>
						<Typography variant="button">
							{strings.cardPrint.table.toolbar.setCardPrintNotPaid.text}
						</Typography>
					</Button>
				</Tooltip>
				<Tooltip title={strings.cardPrint.table.toolbar.setCardPrintPaid.tooltip}>
					<Button
						onClick={this.props.setSelectedToPaid}
						variant="contained"
						size="small"
						style={{ marginRight: "8px" }}
					>
						<Typography variant="button">
							{strings.cardPrint.table.toolbar.setCardPrintPaid.text}
						</Typography>
					</Button>
				</Tooltip>
			</div>
		);
	}
}
