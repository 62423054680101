// MARK: API
import * as api from "../../admin-api-web";

// MARK: Libraries
import jsPDF from "jspdf";
import moment from "moment";
import axios from "axios";
import convert from "convert-length";

// MARK: Mobx
import { observable, action, computed, runInAction, reaction } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";
import * as CardInfoFormatter from "../../resources/CardInfoFormatter";
import UserFilterStore from "./CardPrintFilterStore";

// MARK: Stores
import { uiStore } from "../_rootStore";

// MARK: Types
import TableDataStore from "../TableDataStore";
import { IRowItem } from "../../components/Table/TableRow";
import * as statusFormater from "../../resources/statusFormater";

export default class CardPrintStore extends TableDataStore<api.PartialCardPrint> {
	@observable public selectedCard: api.CardPrint | null;
	@observable public selection: api.CardPrint[] = [];
	public userFilterStore: UserFilterStore = new UserFilterStore();
	@observable public cardPrints: api.PartialCardPrint[] = [];

	constructor() {
        super();

        reaction(() => this.userFilterStore.filter, async (filter) => {
            this.clear();
            await this.getCardPrints();
        });

    }

	@action
    public clear = () => {
		this.cardPrints = [];
        this.pageOffset = 0;
    }

	/**
	 * Needed only for implementation of the TableDataStore
	 * the solution os on tableRow manually
	 */
	protected formatDataToRow(rowItem: api.CardPrint): IRowItem {
		return { id: rowItem.id.toString(), data: [] };
	}

	/**
	 * Had to overwrite this method to make react listen to computed
	 */
	@computed
	public get tableRows() {
		return this.cardPrints.map((rowItem) => {
			const allColumns = [
				{ value: rowItem.name },
				{ value: moment(rowItem.createdAt).format("DD/MM/YYYY") },
				{ value: rowItem.seller ? rowItem.seller.name : " - " },
				{ value: Number(rowItem.id[2]) === 0 ? "" : strings.yes },
				{ value: rowItem.useCode },
				{
					value: api.translatePrintStatus(rowItem.status),
					tagColor: rowItem.status === api.PrintStatus.requested ? "yellow" : "green" as IRowItem["data"][0]["tagColor"],
				},
				statusFormater.paymentStatusToRow(rowItem.payments),
			];

			return {
				selected: this.isThisRowSelected(rowItem.id.toString()),
				id: rowItem.id.toString(),
				data: allColumns,
			};
		});
	}

	protected formatTableHeader(): string[] {
		const { header } = strings.cardPrint.table;

		return [
			header.student,
			header.printedAt,
			header.vendor,
			header.printNumber,
			header.useCode,
			header.status,
			header.payment,
		];
	}

	protected getSingleItemById(id: string): Promise<api.CardPrint> {
		throw new Error("Method not implemented.");
	}

	@action
	protected getDataItemsPerPage(pageOffset: number): Promise<api.PartialCardPrint[]> {
		return this.getCardPrints(pageOffset);
	}

	@observable public rowsPerPage: number = 15;
	@observable public totalOfRows: number = 25;

	@computed
	public get tableRowRange() {
		const page = this.pageOffset + 1;
		let end = this.rowsPerPage * page;
		const start = end - this.rowsPerPage;

		if (end > this.totalOfRows) {
			end = this.totalOfRows;
		}

		return `${start} - ${end} de ${this.totalOfRows}`;
	}

	@action
	public getCardPrints = async (pageOffset?: number) => {
		pageOffset = pageOffset || this.pageOffset;
		try {
			this.loading = true;

			const cardPrints = await api.getCardPrints(this.userFilterStore.filter, pageOffset, this.rowsPerPage);
			this.totalOfRows = cardPrints.total;
			this.cardPrints = cardPrints.results;

			return cardPrints.results;
		} catch (error) {
			uiStore.openErrorSnackbar(error);
		} finally {
			this.loading = false;
		}
	}

	@action
	public setSelectionToPaid = async () => {
		try {
			this.loading = true;

			for (const selected of this.selection) {
				await api.createPaymentForCardPrint(selected.id);
				this.toggleSelection(selected.id.toString());
			}

			await runInAction(async () => {
				this.loading = true;
				this.cardPrints = await this.getDataItemsPerPage(this.pageOffset);
				this.loading = false;
			});
		} catch (err) {
			uiStore.openErrorSnackbar(err);
		} finally {
			this.loading = false;
		}
	}

	@action
	public setSelectionToPending = async () => {
		try {
			this.loading = true;

			for (const selected of this.selection) {
				await api.cancelCardPrintPayment(selected.id);
				this.toggleSelection(selected.id.toString());
			}

			await runInAction(async () => {
				this.loading = true;
				this.cardPrints = await this.getDataItemsPerPage(this.pageOffset);
				this.loading = false;
			});
		} catch (err) {
			uiStore.openErrorSnackbar(err);
		} finally {
			this.loading = false;
		}
	}

	@action
	public getCardByUser = async (userId: string): Promise<api.CardPrint | null> => {
		try {
			this.loading = true;
			this.selectedCard = await api.getLastCardPrintByUser(userId);
		} catch (e) {
			uiStore.openErrorSnackbar(e);
		} finally {
			this.loading = false;
		}

		return this.selectedCard;
	}

	private isThisRowSelected = (id: string) => {
		const isSelected = !!this.selection.find((selected) => id === selected.id.toString());
		return isSelected;
	}

	@action
	public toggleSelection = async (id: string) => {
		if (this.isThisRowSelected(id)) {
			this.selection = this.selection.filter((selected) => selected.id.toString() !== id);
		} else {
			const foundUserToAdd = this.cardPrints.find((cardPrint) => cardPrint.id.toString() === id);

			if (foundUserToAdd) {
				const cardPrint = await api.getCardPrint(foundUserToAdd?.id);
				this.selection = [...this.selection, cardPrint];
			}
		}
	}

	@action
	public selectAll = async () => {
		this.cardPrints.map(async (card) => {
			const cards = await api.getCardPrint(card?.id);
			this.selection = [...this.selection, cards];
		});
	}

	@action
	public printSelected = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			await this.generatePrintPdf(this.selection);

			const selectedCards = this.selection.map((card) => card.id);
			await api.printCards(selectedCards);

			runInAction(() => this.selection = []);

			await runInAction(async () => {
				this.loading = true;
				this.cardPrints = await this.getDataItemsPerPage(this.pageOffset);
				this.loading = false;
			});
		} catch (err) {
			uiStore.openErrorSnackbar(strings.cardPrint.print.error);
		} finally {
			this.loading = false;
		}
	}

	@action
	public convertMmToPixels = (size: number) => {
		return convert(size, "mm", "px");
	}

	private async downloadImage(url: string): Promise<string> {
		let image = await axios({
			url,
			method: "GET",
			responseType: "arraybuffer",
		}).then((response) => new Buffer(response.data, "binary").toString("base64"));

		image = "data:image/jpg;base64," + image;
		return image;
	}

	@action
	public processCardPrints = async (cardPrints: api.CardPrint[]) => {

		const cardsToPrint = cardPrints.map((cardPrint) => {
			return CardInfoFormatter.formatCardInfo({
				 ...cardPrint,
				 id: cardPrint.id.join(),
			});
		});

		const processedCards: Array<{
			avatar: string,
			qrCode: any,
			cardPrint: CardInfoFormatter.ICardInfoString,
		}> = [];

		for (const cardPrint of cardsToPrint) {
			const yaqrcode = require("yaqrcode");
			const qrCode = await yaqrcode(cardPrint.qrcodeUrl);
			const avatar = await this.downloadImage(cardPrint.avatar);
			processedCards.push({ avatar, qrCode, cardPrint });
		}

		return processedCards;
	}

	@action
	public generatePrintPdf = async (cardPrints: api.CardPrint[]) => {

		const pdf = new jsPDF("landscape", "mm", [ 255, 156 ]);
		const processedPrints = await this.processCardPrints(cardPrints);

		pdf.setFontSize(7);
		pdf.setFontType("bold");

		for (const processedCard of processedPrints) {

			const { cardPrint, avatar, qrCode } = processedCard;

			if (cardPrint.id !== processedPrints[0].cardPrint.id) {
				pdf.addPage();
			}

			const fields = strings.cardPrint.print.fields;

			pdf.addImage(avatar, "JPEG", 3, 11, 22, 29);
			pdf.text(cardPrint.name, 29, 15);
			pdf.text(cardPrint.institution, 29, 18);
			pdf.text(cardPrint.schooling, 29, 21);
			pdf.text(cardPrint.course, 29, 24);
			pdf.text(fields.cpf, 29, 28.5);
			pdf.text(cardPrint.cpf, 35.5, 28.5);
			pdf.text(fields.rg, 29, 31.5);
			pdf.text(cardPrint.rg, 34, 31.5);
			pdf.text(fields.birthDate, 29, 34.5);
			pdf.text(cardPrint.birthday, 42.5, 34.5);
			pdf.text(fields.registration, 29, 37.5);
			pdf.text(cardPrint.studentId, 41, 37.5);
			pdf.text(fields.code, 29, 49);
			pdf.text(cardPrint.useCode, 48, 49);
			pdf.addImage(qrCode, "JPEG", 9, 43, 10, 10);
		}

		try {
			await this.printSelected();

			const fileDownloadThreshhold = 50;
			if (processedPrints.length > fileDownloadThreshhold) {
				pdf.save("cartoesParaImpressao.pdf");
			} else {
				window.open(pdf.output("bloburl"), "_blank");
			}
		} catch (e) {
			console.log(e);
		}
	}

	// CSV download
	@observable public loadedCSV: boolean = false;
	@observable public csvData: any = [];
	@observable public csvHeader: any = [
		{ key: "name", label: strings.cardPrint.table.csvHeader.name },
		{ key: "email", label: strings.cardPrint.table.csvHeader.email },
		{ key: "phone", label: strings.cardPrint.table.csvHeader.phone },
		{ key: "institutionName", label: strings.cardPrint.table.csvHeader.institutionName },
		{ key: "courseName", label: strings.cardPrint.table.csvHeader.courseName },
		{ key: "seller", label: strings.cardPrint.table.csvHeader.seller },
		{ key: "printStatus", label: strings.cardPrint.table.csvHeader.printStatus },
		{ key: "printDate", label: strings.cardPrint.table.csvHeader.printDate },
		{ key: "isReprint", label: strings.cardPrint.table.csvHeader.isReprint },

	];

	@action public getCSVData = async () => {
		if (this.loading) return;
		this.loading = true;
		this.csvData = [];
		try {
			const logsData = await api.getAllCardPrints();
			this.csvData = logsData.map((cardPrint) => ({
				name: cardPrint.name,
				email: cardPrint.email,
				phone: cardPrint.phone,
				institutionName: cardPrint.institution.name,
				courseName: cardPrint.course.name,
				seller: cardPrint.seller ? cardPrint.seller.name : "",
				printStatus: cardPrint.status,
				printDate: cardPrint.createdAt,
				isReprint: (parseInt(cardPrint.id[2]) > 1) ? "X" : "",
			}));
		} catch (e) {
			console.error("Error in getCVSData: ", e);
		} finally {
			this.loading = false;
			this.loadedCSV = true;
			uiStore.openSnackbar(strings.userLogs.csv.snackBar);
		}
	}
}
