import { inspect } from "util";
import { uploadImage, Image, ImageFormat, UncertainImage } from "../../admin-api-web";
import { observable, action, computed } from "mobx";

// Components

import Cropper from "react-cropper";

// Helper
import { craftImageBuffer, openInputImageDialog } from "./ImageHelperFunctions";

// Stores
import { uiStore } from "../../stores/_rootStore";

export interface IUploadImage {
    imageFile?: File;
    withCrop?: boolean;
}

export default class ImageUploaderService {
    // Control
    @observable public status: "failed" | "loading" | "success" | "uploading" | "created" = "created";
    @observable public progress: number = 0;

    // Variables
    @observable public imageFile: File | null = null;
    @observable public uploadedImage: Image | null = null;

    @observable public isCropping: boolean = false;

    private reasonableUploadPercentage: number = 5;
    public cropper: Cropper | null;

    @action
    public setCropper = (cropper: Cropper | null) => {
        this.cropper = cropper;
    }

    // Methods
    @action
    public startCrop = () => {
        this.isCropping = true;
    }

    @action
    public stopCrop = () => {
        this.isCropping = false;
    }

    @action
    public setIsCropping = (isCropping: boolean) => {
        this.isCropping = isCropping;
    }

    @computed
    public get src(): string | null {
        if (this.uploadedImage) {
            return this.uploadedImage.url;
        } else {
            return this.imageFile ? URL.createObjectURL(this.imageFile) : null;
        }
    }

    @action
    public setImage = (image: Image | null) => {
        this.clear();
        this.uploadedImage = image;

        if (image) {
            this.status = "success";
        } else {
            this.status = "created";
        }
    }

    @action
    public selectImage = async () => {
        openInputImageDialog(async (file) => {
            this.imageFile = file;
            this.isCropping = true;
        }, (error) => uiStore.openSnackbar(error));
    }

    @action
    public uploadImage = async (uploadImageObj?: IUploadImage) => {
        let imageFile = uploadImageObj ? uploadImageObj.imageFile : null;

        if (imageFile) {
            this.imageFile = imageFile;
        } else {
            imageFile = this.imageFile;
        }

        imageFile = imageFile || this.imageFile;

        const withCrop = uploadImageObj && uploadImageObj.withCrop ? uploadImageObj.withCrop : false;
        const buffer = withCrop ?
            Buffer.from(this.cropper!.getCroppedCanvas({ fillColor: "white" }).toDataURL().split(",")[1], "base64") :
            imageFile ?
                await craftImageBuffer(
                    imageFile,
                    1000,
                ) : null;

        if (!buffer && !this.uploadedImage) {
            return;
        }

        this.isCropping = false;
        this.status = "loading";

        try {
            if (this.imageFile === imageFile) {
                const uploadedImage = buffer ? await uploadImage(
                    buffer,
                    ImageFormat.jpeg,
                    null,
                    this.updateProgress,
                ) : this.uploadedImage!;

                if (this.imageFile === imageFile) {
                    this.setImage(uploadedImage);
                }
            }
        } catch (e) {
            this.status = "failed";
            this.progress = 0;

            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        }
    }

    @action
    public clear = () => {
        this.status = "created";

        this.isCropping = false;

        this.imageFile = null;
        this.uploadedImage = null;
    }

    @action
    private updateProgress = (progress: number) => {
        if ((progress > this.reasonableUploadPercentage) && this.status !== "failed") {
            this.status = "uploading";
        }

        this.progress = progress;
    }

    public getUncertainfiedImage = async (): Promise<UncertainImage | null> => {
        if (this.uploadedImage) {
            return {
                bytes: null,
                image: this.uploadedImage,
                crop: null,
            };
        }

        return this.imageFile ? {
            bytes: await craftImageBuffer(this.imageFile, 1000),
            image: null,
            crop: null,
        } : null;
    }
}
