import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";

// Services
import AutocompleteInstitutionService from "../../../service/AutocompleteInstitutionService";
import AutocompleteCourseService from "../../../service/AutocompleteCourseService";
import AutocompleteAdminUserService from "../../../service/AutocompleteAdminUserService";

// Components
import { IFilterField } from "../../../components/Filter";

// Resources
import strings from "../../../resources/strings";

export default class UserFilterStore extends VariableChangeHandler {
    // Services
    public autocompleteInstitutionService: AutocompleteInstitutionService = new AutocompleteInstitutionService();
    public autocompleteCourseService: AutocompleteCourseService = new AutocompleteCourseService();
    public autocompleteAdminUserService: AutocompleteAdminUserService = new AutocompleteAdminUserService();

    // Variables
    @observable public name: string | null = null;
    @observable public email: string | null = null;
    @observable public cpf: string | null = null;
    @observable public rg: string | null = null;
    @observable public uf: api.StateUF | null = null;
    @observable public schooling: api.Schooling | null = null;
    @observable public status: api.PrintStatusPending | null = null;
    @observable public studentId: string | null = null;
    @observable public editedAt: Date | null = null;

    @observable public institution: api.Institution | null = null;
    @observable public course: api.Course | null = null;
    @observable public seller: api.AdminUser | null = null;
    @action private formatDate = (date: string) => {
        const dataObj = new Date(date);
        const getDay = dataObj.getDate();
        const getMonth = dataObj.getMonth() + 1;
        const getYear = dataObj.getFullYear();
        const formatedDate = (getDay < 10 ? "0" : "") + getDay + "/" + (getMonth < 10 ? "0" : "") + getMonth + "/" + getYear;
        return formatedDate;
}
    // Methods
    @computed
    public get filter(): api.UserFilter {
        return {
            name: this.name,
            email: this.email,
            cpf: this.cpf,
            rg: this.rg,
            uf: this.uf,
            schooling: this.schooling,
            institutionId: this.institution ? this.institution.id : null,
            courseId: this.course ? this.course.id : null,
            studentId: this.studentId,
            sellerId: this.seller ? this.seller.id : null,
            status: this.status,
            editedAt: this.editedAt,
        };
    }

    @computed
    public get fields(): IFilterField[] {
        return [
            {
                id: "name",
                value: strings.users.filter.name,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.name,
                clear: () => {
                    this.name = null;
                },
            },
            {
                id: "email",
                value: strings.users.filter.email,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.email,
                clear: () => {
                    this.email = null;
                },
            },
            {
                id: "cpf",
                value: strings.users.filter.cpf,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.cpf,
                clear: () => {
                    this.cpf = null;
                },
            },
            {
                id: "rg",
                value: strings.users.filter.rg,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.rg,
                clear: () => {
                    this.rg = null;
                },
            },
            {
                id: "uf",
                value: strings.users.filter.uf,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesStateUF(),
                    valueFromOption: api.valueFromTranslationStateUF,
                },
                currentValue: this.uf ? api.translateStateUF(this.uf) : null,
                clear: () => {
                    this.uf = null;
                },
            },
            {
                id: "schooling",
                value: strings.users.filter.schooling,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesSchooling(),
                    valueFromOption: api.valueFromTranslationSchooling,
                },
                currentValue: this.schooling ? api.translateSchooling(this.schooling) : null,
                clear: () => {
                    this.schooling = null;
                },
            },
            {
                id: "status",
                value: strings.users.filter.status,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesPrintStatusPending(),
                    valueFromOption: api.valueFromTranslationPrintStatusPending,
                },
                currentValue: this.status ? api.translatePrintStatusPending(this.status) : null,
                clear: () => {
                    this.status = null;
                },
            },
            {
                id: "editedAt",
                value: strings.users.filter.editedAt,
                datePicker: {
                    onChange: (date: Date) => this.editedAt = date,
                    value: this.editedAt,
                },
                type: "Date",
                currentValue: this.editedAt ? this.formatDate(this.editedAt.toString()) : null,
                clear: () => {
                    this.editedAt = null;
                },
            },
            {
                id: "institutionId",
                value: strings.users.filter.institutionId,
                type: "autosuggest",
                currentValue: this.institution ? this.institution.name : null,
                autosuggest: {
                    options: this.autocompleteInstitutionService.autocompleteInstitutions,
                    loading: this.autocompleteInstitutionService.loading,
                    onChange: this.selectInstitution,
                    getOptions: this.autocompleteInstitutionService.getInstitutions,
                    clearOptions: this.autocompleteInstitutionService.clear,
                },
                clear: () => {
                    this.institution = null;
                },
            },
            {
                id: "courseId",
                value: strings.users.filter.courseId,
                type: "autosuggest",
                currentValue: this.course ? this.course.name : null,
                autosuggest: {
                    options: this.autocompleteCourseService.autocompleteCourses,
                    loading: this.autocompleteCourseService.loading,
                    onChange: this.selectCourse,
                    getOptions: this.autocompleteCourseService.getCourses,
                    clearOptions: this.autocompleteCourseService.clear,
                },
                clear: () => {
                    this.course = null;
                },
            },
            {
                id: "studentId",
                value: strings.users.filter.studentId,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.studentId,
                clear: () => {
                    this.studentId = null;
                },
            },
            {
                id: "sellerId",
                value: strings.users.filter.sellerId,
                type: "autosuggest",
                currentValue: this.seller ? this.seller.name : null,
                autosuggest: {
                    options: this.autocompleteAdminUserService.autocompleteAdminUsers,
                    loading: this.autocompleteAdminUserService.loading,
                    onChange: this.selectSeller,
                    getOptions: this.autocompleteAdminUserService.getAdminUsers,
                    clearOptions: this.autocompleteAdminUserService.clear,
                },
                clear: () => {
                    this.seller = null;
                },
            },
        ];
    }

    @action
    public selectInstitution = async (institutionId: string | null) => {
        await this.autocompleteInstitutionService.selectInstitution(institutionId);
        this.institution = this.autocompleteInstitutionService.institution;
    }

    @action
    public selectCourse = async (courseId: string | null) => {
        await this.autocompleteCourseService.selectCourse(courseId);
        this.course = this.autocompleteCourseService.course;
    }

    @action
    public selectSeller = async (sellerId: string | null) => {
        await this.autocompleteAdminUserService.selectAdminUser(sellerId);
        this.seller = this.autocompleteAdminUserService.adminUser;
    }

    @action
    public clear = () => {
        this.name = null;
        this.email = null;
        this.cpf = null;
        this.rg = null;
        this.uf = null;
        this.schooling = null;
        this.studentId = null;

        this.institution = null;
        this.course = null;
        this.seller = null;
        this.status = null;
    }
}
