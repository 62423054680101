import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import TeamStore from "../../stores/TeamStore";
import AuthStore from "../../stores/AuthStore";
import UIStore from "../../stores/UIStore";
import { RouterStore } from "mobx-react-router";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import Snackbar from "../../components/SnackBar";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";

// Resources
import strings from "../../resources/strings";

interface IProps {
	authStore: AuthStore;
	teamStore: TeamStore;
	uiStore: UIStore;
}

@inject("teamStore", "uiStore")
@observer
export default class Register extends React.Component<IProps> {
	public render() {
		const { teamStore, uiStore} = this.props;

		return (
			<div className="newRegister">
				<div className="newRegisterContainer">
					<Paper>
						<div className="card">
							<Snackbar
								open={uiStore.snackbarOpen}
								message={uiStore.snackBarMessage}
							/>
							<Alert uiStore={uiStore} />
							{teamStore.loading ?
								<Loading /> :
								<>
									<Typography variant="h6">{strings.register.title}</Typography>
									<TextField
										type="name"
										name="name"
										label={strings.adminUsers.editor.name}
										value={teamStore.name}
										disabled={teamStore.loading}
										onChange={teamStore.handleValueChange}
									/>
									<TextField
										type="email"
										name="email"
										label={strings.adminUsers.editor.email}
										value={teamStore.email}
										disabled={teamStore.loading}
										onChange={teamStore.handleValueChange}
									/>
									<TextField
										type="password"
										name="password"
										label={strings.adminUsers.editor.password}
										value={teamStore.password}
										disabled={teamStore.loading}
										onChange={teamStore.handleValueChange}
									/>
									<p className="smallCounter">{strings.adminUsers.small.limit}</p>
									<TextField
										type="phone"
										name="phone"
										label={strings.adminUsers.editor.phone}
										value={teamStore.phone}
										disabled={teamStore.loading}
										onChange={teamStore.handleValueChange}
									/>
									{strings.preRegisterAdminUser.contract}
									<div className="buttonsContainer">
										<Button
											onClick={teamStore.preregisterAdminUser}
										>
											{strings.preRegisterAdminUser.buttons.create}
										</Button>
									</div>
								</>
							}
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}
