import * as React from "react";
import "./style.scss";

// Mobx
import { observer } from "mobx-react";

// Material-UI Components
import MTypography from "@material-ui/core/Typography";

// Material-UI Icons
import MIconButton from "@material-ui/core/IconButton";
import MChevronLeft from "@material-ui/icons/ChevronLeft";
import MChevronRight from "@material-ui/icons/ChevronRight";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

interface IProps {
	pageOffset: number;
	onNextClick?: () => void;
	onPrevClick?: () => void;
	rowsPerPageConfigs?: {
		rowsPerPage: number;
		onRowsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
		refetchData: () => void;
		info: string;
	 };
}

@observer
export default class TableFooter extends React.Component<IProps> {

	public render() {
		const { pageOffset, onNextClick, onPrevClick, rowsPerPageConfigs } = this.props;

		return (
			<div className="footerDiv">
				<div className="paginationControl">
					{
						rowsPerPageConfigs &&
						<>
							<FormControl variant="outlined">
								<InputLabel>
									itens/pág.
								</InputLabel>
								<Select
									name="rowsPerPage"
									value={rowsPerPageConfigs.rowsPerPage}
									onChange={(event) => {
										if (rowsPerPageConfigs) {
											rowsPerPageConfigs.onRowsPerPageChange(event);
											rowsPerPageConfigs.refetchData();
										}
									}}
									input={
										<OutlinedInput
											style={{width: 80}}
											labelWidth={68}
											name="age"
											id="outlined-age-simple"
										/>
									}
								>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={50}>50</MenuItem>
									<MenuItem value={100}>100</MenuItem>
								</Select>
							</FormControl>
							<Typography style={{margin: 20}}>{rowsPerPageConfigs.info}</Typography>
						</>
					}

					<MIconButton onClick={onPrevClick} >
						<MChevronLeft />
					</MIconButton>
					<MTypography variant="subtitle2">{pageOffset}</MTypography>
					<MIconButton onClick={onNextClick}>
						<MChevronRight />
					</MIconButton>
				</div>
			</div>
		);
	}
}
