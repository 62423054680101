import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";

// Components
import { IFilterField } from "../../../components/Filter";

// Resources
import strings from "../../../resources/strings";

export default class CourseFilterStore extends VariableChangeHandler {
    // Variables
    @observable public name: string | null = null;
    @observable public isActive: boolean | null = null;

    // Methods
    @computed
    public get filter(): api.CourseFilter {
        return {
            name: this.name,
            isActive: this.isActive,
        };
    }

    @computed
    public get fields(): IFilterField[] {
        return [
            {
                id: "name",
                value: strings.courses.filter.name,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.name,
                clear: () => {
                    this.name = null;
                },
            },
            {
                id: "isActive",
                value: strings.courses.filter.isActive,
                type: "checkbox",
                checkbox: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.isActive,
                clear: () => {
                    this.isActive = null;
                },
            },
        ];
    }

    @action
    public clear = () => {
        this.name = null;
    }
}
