import { PaymentStatus, translatePaymentStatus, Payment} from "../admin-api-web";
import strings from "./strings";

type ITagColor =  "yellow" |  "red" | "blue" | "green";

export function paymentStatusToRow(payments: Payment[]) {
    const status = payments.filter((payment) => payment.status === PaymentStatus.paid).length > 0 ? PaymentStatus.paid : null;

    if (!status) {
        return {
            value: strings.enum.PaymentStatus.pending,
            tagColor: "yellow" as ITagColor,
        };
    }

    const rowStatus = {
        value: translatePaymentStatus(status),
        tagColor: "yellow" as ITagColor,
    };

    switch (status) {
        case PaymentStatus.paid: rowStatus.tagColor = "green"; break;
        default: rowStatus.tagColor = "yellow"; break;
    }
    return rowStatus;
}
