// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import * as CardInfoFormatter from "../../resources/CardInfoFormatter";

// MARK: Stores
import UIStore from "../../stores/UIStore";

// MARK: Components
import { Paper, Typography, Toolbar, LinearProgress } from "@material-ui/core";
import CardPrint from "../CardPrint";
import strings from "../../resources/strings";
import Button from "@material-ui/core/Button";

// MARK: Icons
import PrintIcon from "@material-ui/icons/Print";

interface IStores {
	uiStore: UIStore;
}

interface IProps extends IStores {
	title: string;
	confirmButtonText: string;
	cardsInfo: CardInfoFormatter.ICardInfo[];

	loading?: boolean;

	onCancel: () => void;

	onConfirm: () => void;
	confirmDialog: {
		withoutAvatar: {
			title: string;
			message: string;
		};
		withAvatar: {
			title: string;
			message: string;
		};
	};
}

@inject("uiStore")
@observer
export default class ConfirmPrint extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		const { title, confirmButtonText, cardsInfo, onConfirm, confirmDialog, onCancel, loading, uiStore } = this.props;
		const hasCardInfoWithoutAvatar: boolean = cardsInfo.filter((cardInfo) => !cardInfo.avatar).length > 0;

		return (
			<Paper>
				{loading && <LinearProgress/>}
				<Toolbar
					style={{
						display: "flex",
						justifyContent: "space-between",
						boxSizing: "border-box",
						width: "100%",
					}}
				>
					<Typography variant="h6">
						{title}
					</Typography>
					<Toolbar
						style={{
							display: "flex",
							justifyContent: "space-between",
							boxSizing: "border-box",
							width: "35%",
						}}
					>
						<Button
							onClick={() => onCancel()}
							disabled={loading}
						>
							{strings.cardPrint.print.cancel}
						</Button>
						<Button
							size="small"
							variant="contained"
							onClick={() => {
								uiStore.showDialog(
									hasCardInfoWithoutAvatar ?
										confirmDialog.withoutAvatar :
										confirmDialog.withAvatar,
									onConfirm,
								);
							}}
							disabled={loading}
						>
							<PrintIcon style={{ marginRight: "8px" }} />
							<Typography
								variant="button"
							>
								{confirmButtonText}
							</Typography>
						</Button>
					</Toolbar>
				</Toolbar>
				<div className="confirmPrintDialog">
					<div className="confirmPrintDialogRow">
						{cardsInfo.map((cardInfo) => {
							return (
								<CardPrint
									key={cardInfo.id}
									cardInfo={CardInfoFormatter.formatCardInfo(cardInfo)}
								/>
							);
						})}
					</div>
				</div>
			</Paper>
		);
	}
}
