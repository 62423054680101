// Resources
import * as api from "../../admin-api-web";
import strings from "../../resources/strings";
import { IRowItem, ColumnType } from "../../components/Table/TableRow";
import { inspect } from "util";
import moment from "moment";

// Services
import * as spreadsheetService from "../../service/SpreadsheetService";

// Mobx
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed, reaction } from "mobx";
import { uiStore, routerStore } from "../_rootStore";

// Stores
import UserCreateOrEditStore from "./UserCreateOrEditStore";
import UserFilterStore from "./UserFilterStore";

import { authStore } from "../_rootStore";
import { onError } from "mobx-react";
import { string } from "prop-types";
import { downloadFile } from "../../resources/FileManager";
import { ICardInfo } from "src/resources/CardInfoFormatter";

type INewDataRow = IRowItem["data"][0] & { column: api.UsersColumnType, header: string };
interface INewRowItem extends IRowItem {
    data: INewDataRow[];
}

export default class UserStore extends VariableChangeHandler {
    // Sub Stores
    public userCreateOrEditStore: UserCreateOrEditStore = new UserCreateOrEditStore();
    public userFilterStore: UserFilterStore = new UserFilterStore();
    @observable public editingTable = false;
    @observable public reportLoading: boolean = false;

    @action
    public setEditingTable = (editingTable: boolean) => {
        this.editingTable = editingTable;
    }

    @action
    public saveSelectedColumns = async (selectedColumns: api.UsersColumnType[]) => {
        this.editingTable = false;

        if (authStore.adminUser) {
            authStore.adminUser.userConfig = {
                usersTableColumnOrder: selectedColumns,
            };

            await api.setUserConfig(authStore.adminUser.userConfig);
        }
    }

    @computed
    public get userTableConfig(): api.UsersColumnType[] {
        return authStore.adminUser && authStore.adminUser.userConfig && authStore.adminUser.userConfig.usersTableColumnOrder ?
            authStore.adminUser.userConfig.usersTableColumnOrder :
            [
                api.UsersColumnType.cardPrint,
                api.UsersColumnType.seller,
                api.UsersColumnType.avatar,
                api.UsersColumnType.name,
                api.UsersColumnType.email,
                api.UsersColumnType.phone,
                api.UsersColumnType.cpf,
                api.UsersColumnType.institution,
            ];
    }

    @observable public userCustomTableConfig: api.UsersColumnType[] = [];
    @observable public loading: boolean = false;
    @observable public rowsPerPage: number = 15;
    @observable public pageOffset: number = 0;
    @observable public users: api.PartialUser[] = [];
    @observable public userSelection: api.PartialUser[] = [];
    @observable public userCardPrintInfo: api.User[] = [];

    @observable public totalNumberOfItems: number = 0;

    // pageOffset: number;
    // numberPerPage: number;
    // total: number;

    constructor() {
        super();
        this.userSelection = [];
        reaction(() => this.userCreateOrEditStore.selectedUser, (selectedUser) => {
            if (selectedUser) {
                const index = this.users.findIndex((user) => user.id === selectedUser.id);

                if (index !== -1) {
                    this.users[index] = selectedUser;
                } else {
                    this.users.push(selectedUser);
                }
            }
        });

        reaction(() => this.userFilterStore.filter, async (filter) => {
            this.clear();
            await this.getUsers();
        });

    }

    @computed
    public get tableRows(): INewRowItem[] {
        return this.users.map((user) => {
            const allColumns: INewDataRow[] = [
                {
                    column: api.UsersColumnType.cardPrint,
                    header: strings.users.table.header.cardPrint,
                    value: user.cardPrintStatus ?
                        (user.cardPrintStatus === api.PrintStatus.printed ?
                            strings.enum.userPrintStatus.printed :
                            strings.enum.userPrintStatus.requested) :
                        strings.enum.userPrintStatus.none,
                    tagColor: user.cardPrintStatus ?
                        (user.cardPrintStatus === api.PrintStatus.printed ?
                            "green" :
                            "blue") :
                        "yellow",
                },
                {
                    column: api.UsersColumnType.seller,
                    header: strings.users.table.header.seller,
                    value: user.seller ? user.seller : null,
                },
                {
                    column: api.UsersColumnType.avatar,
                    header: strings.users.table.header.avatar,
                    type: ColumnType.image,
                    value: user.avatar ? user.avatar.thumb.url : null,
                },
                {
                    column: api.UsersColumnType.name,
                    header: strings.users.table.header.name,
                    value: user.name,
                },
                {
                    column: api.UsersColumnType.institution,
                    header: strings.users.table.header.institution,
                    value: user.institution,
                },
                {
                    column: api.UsersColumnType.email,
                    header: strings.users.table.header.email,
                    value: user.email,
                },
                {
                    column: api.UsersColumnType.phone,
                    header: strings.users.table.header.phone,
                    value: user.phone,
                },
                {
                    column: api.UsersColumnType.cpf,
                    header: strings.users.table.header.cpf,
                    value: user.cpf,
                },
                {
                    column: api.UsersColumnType.studentId,
                    header: strings.users.table.header.studentId,
                    value: user.studentId,
                },
                {
                    column: api.UsersColumnType.birthday,
                    header: strings.users.table.header.birthday,
                    value: moment(user.birthday).format("DD/MM/YYYY"),
                },
            ];

            return {
                selected: this.isThisUserSelected(user.id),
                id: user.id,
                data: this.userTableConfig
                    .map((columnConfig) => allColumns.find((column) => column.column === columnConfig))
                    .filter((x) => !!x) as INewDataRow[],
            };
        });
    }

    @computed
    public get tableRowRange() {

        const page = this.pageOffset + 1;
        let end = this.rowsPerPage * page;
        const start = end - this.rowsPerPage;

        if (end > this.totalNumberOfItems) {
            end = this.totalNumberOfItems;
        }

        return `${start} - ${end} de ${this.totalNumberOfItems}`;
    }

    @action
    public downloadCSV = async () => {
        try {
            this.loading = true;
            const url = await api.getExportUsers(this.userFilterStore.filter);

            window.open(url);
        } catch (error) {
            uiStore.openErrorSnackbar(error);
        } finally {
            this.loading = false;
        }
    }

    @action
    public usersSelectionRequest = async () => {
        try {
            this.userSelection.map(async (user) => {
                const data = await api.getUserFor(user.cpf || "");
                const alreadyExist = this.userCardPrintInfo.find((item) => item.id === data.id);
                if (!alreadyExist) {
                    this.userCardPrintInfo.push(data);
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    }

    @computed
    public get selectionLength() {
        return this.userSelection.length;
    }

    private isThisUserSelected = (id: string) => {
        return !!this.userSelection.find((selectedUser) => id === selectedUser.id);
    }

    @action
    public toggleUserSelection = (id: string) => {
        if (this.isThisUserSelected(id)) {
            this.userSelection = this.userSelection.filter((user) => user.id !== id);
        } else {
            const foundUserToAdd = this.users.find((user) => user.id === id);
            if (foundUserToAdd) {
                this.userSelection = [...this.userSelection, foundUserToAdd];
            }
        }
    }

    @action
    public selectAllPageUsers = () => {
        this.userSelection = [];
        this.userSelection = this.users;
    }

    @action
    public emptyUserSelection = () => {
        this.userSelection = [];
    }

    @action
    public getInitialUsers = async () => {
        if (this.users.length === 0) {
            this.pageOffset = 0;
            await this.getUsers(0);
        }
    }

    @action
    public requestCardPrintForSelection = async () => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        try {
            const cardPrints = await api.requestCardPrints(this.userSelection.map((user) => ({
                userId: user.id,
                printType: api.CardPrintType.full,
            })));

            this.userSelection = this.userSelection.filter((user) => {
                return !cardPrints.find((cardPrint) => cardPrint.userId === user.id);
            });
        } catch (err) {
            uiStore.openErrorSnackbar(err);
        } finally {
            this.loading = false;
            await this.getUsers();
        }
    }

    @action
    public getUsers = async (pageOffset?: number) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!pageOffset) {
            pageOffset = 0;
        }

        if (pageOffset < 0) {
            this.loading = false;
            return;
        }

        try {
            const userResults = await api.getUsers(this.userFilterStore.filter, pageOffset, this.rowsPerPage, null);

            this.totalNumberOfItems = userResults.total;

            if (userResults.results.length > 0) {
                this.users = userResults.results;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            uiStore.openErrorSnackbar(e);
        } finally {
            this.loading = false;
        }
    }

    @action
    public clear = () => {
        this.pageOffset = 0;
        this.users = [];
    }

    @action
    public nextPage = async () => {
        await this.getUsers(this.pageOffset + 1);
    }

    @action
    public previousPage = async () => {
        await this.getUsers(this.pageOffset - 1);
    }

    @action
    public selectUser = async (userId?: string | null) => {
        if (this.loading) {
            return;
        }

        try {
            this.loading = true;
            this.userCreateOrEditStore.selectedEditUser = null;
            if (userId) {
                const selectedUser = await api.getUser(userId);

                this.userCreateOrEditStore.selectedEditUser = Object.create(selectedUser);
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }
            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
        if (userId) {
            return this.userCreateOrEditStore.selectedEditUser;
        }

        return this.userCreateOrEditStore.selectedEditUser;
    }

    @action
    public startCreateOrEditUser = async (userId?: string) => {
        this.userCreateOrEditStore.clear();
		await this.selectUser(userId);

        if (this.userCreateOrEditStore.selectedEditUser) {
            routerStore.push(`/dashboard/students/edit/${userId}`);
        } else {
            routerStore.push("/dashboard/students/new");
        }
	}

    @action
    public importUsersFromExcel = async () => {
        spreadsheetService.getUsersDataFromCSVFile(async (userDataList) => {
            this.loading = true;
            uiStore.openLoadingDialog("Aguarde...", "Carregando Arquivo");
            try {
                await api.createUsersWithCSVRowUserJSON(userDataList);
                await this.getInitialUsers();
            } catch (csvError) {
                uiStore.showAlert({ title: strings.errors.error, message: csvError.message });
            } finally {
                this.loading = false;
                uiStore.closeLoadingDialog();
            }
        });
    }
    // Import Csv
    @observable public loadingCSV: boolean = false;
    @observable public loadedCSV: boolean = false;
    @observable public csvHeader: any = [
        { key: "name", label: strings.users.table.header.name },
        { key: "cpf", label: strings.users.table.header.cpf },
        { key: "rg", label: strings.users.table.header.rg },
        { key: "rgInstitution", label: strings.users.table.header.rgInstitution },
        { key: "birthday", label: strings.users.table.header.birthday },
        { key: "email", label: strings.users.table.header.email },
        { key: "city", label: strings.users.table.header.city },
        { key: "state", label: strings.users.table.header.state },
        { key: "uf", label: strings.users.table.header.uf },
        { key: "neighborhood", label: strings.users.table.header.neighborhood },
        { key: "street", label: strings.users.table.header.street },
        { key: "number", label: strings.users.table.header.number },
        { key: "cep", label: strings.users.table.header.cep },
        { key: "phone", label: strings.users.table.header.phone },
        { key: "institution", label: strings.users.table.header.institution },
        { key: "course", label: strings.users.table.header.course },
        { key: "schooling", label: strings.users.table.header.schooling },
        { key: "studentId", label: strings.users.table.header.studentId },
        { key: "seller", label: strings.users.table.header.seller },
        { key: "lastPrint", label: strings.users.table.header.lastPrint },
    ];
    @observable public csvData: any = [];

    @action public getCSVData = async () => {
        if (this.loading) return;
        this.loading = true;
        this.csvData = [];
        try {
            const usersData = await api.getAllUsers();
            this.csvData = usersData.map((user) => ({
                name: user.name,
                cpf: user.cpf,
                rg: user.rg.number,
                rgInstitution: user.rg.institution,
                birthday: moment(user.birthday).format("DD/MM/YYYY"),
                email: user.email,
                city: user.address.city,
                state: user.address.state,
                uf: user.address.uf,
                neighborhood: user.address.neighborhood,
                street: user.address.street,
                number: user.address.number,
                cep: user.address.cep,
                phone: user.phone,
                institution: user.institution.name,
                course: user.course.name,
                schooling: api.translateSchooling(user.schooling),
                studentId: user.studentId,
                seller: user.seller && user.seller.name !== "root" && user.seller.name,
                lastPrint: user.lastPrint ? moment(user.lastPrint).format("DD/MM/YYYY hh:mm") : "",
            }));
        } catch (e) {
            console.error("Error in getCVSData: ", e);
        } finally {
            this.loading = false;
            this.loadedCSV = true;
            uiStore.openSnackbar(strings.userLogs.csv.snackBar);
        }
    }

    @action
	public getUsersReport = async () => {
		if (this.reportLoading) {
			return;
		}
		this.reportLoading = true;
		try {
			const url =  await api.getUsersReportsWithStream();
			downloadFile(url);
		} catch (e) {
			uiStore.showAlert(e);
		} finally {
			this.reportLoading = false;
		}
	};

}
