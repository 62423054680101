import * as React from "react";
import "./style.scss";

// MARK: API
import * as api from "../../../../admin-api-web";

// MARK: Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

// MARK: Stores
import { observer, inject } from "mobx-react";
import { routerStore } from "../../../..//stores/_rootStore";
import UserStore from "../../../../stores/UserStore";

// MARK: Components
import { Typography, Paper, Checkbox, FormControlLabel } from "@material-ui/core";
import Autosuggest from "../../../../components/Autosuggest";
import TextField from "../../../../components/TextField";
import MaskedTextField from "../../../../components/MaskedTextField";
import DatePicker from "../../../../components/DatePicker";
import Button from "../../../../components/Button";
import EnumPicker from "../../../../components/EnumPicker";
import LinkButton from "../../../../components/LinkButton";
import SingleImageCase from "../../../../components/SingleImageCase";
import Loading from "../../../../components/Loading";

interface IProps {
    match: Match<{ userId: string }>;
    userStore: UserStore;
}

@inject("userStore")
@observer
export default class CreateOrEditUser extends React.Component<IProps> {
    public componentDidMount = async () => {
        const { userStore, match } = this.props;
        const { userId } = match.params;

        if (userId) {
            if (!userStore.userCreateOrEditStore.selectedEditUser || userStore.userCreateOrEditStore.selectedEditUser.id !== userId) {
                await userStore.selectUser(userId);
            }

            if (!userStore.userCreateOrEditStore.selectedEditUser) {
                routerStore.push("/");
            }
        }
    }

    constructor(props: any) {
        super(props);
        this.props.userStore.userCreateOrEditStore.ingnoreCPFValidation = false;
    }

    public render() {
        const { userStore } = this.props;

		const shouldTryGetUserFromCpf = (
			!userStore.userCreateOrEditStore.selectedEditUser &&
			!userStore.userCreateOrEditStore.tryedGetUserFromCpf &&
			!userStore.userCreateOrEditStore.ingnoreCPFValidation
		);

        const cpfMaskedTextfield = (
            <MaskedTextField
                label={strings.users.createOrEdit.documents.cpf}
                name="cpf"
                mask="999.999.999-99"
                required={true}
                className="cpfCharacter"
				errorMessage={userStore.userCreateOrEditStore.cpfValidationErrorMessage}
                value={userStore.userCreateOrEditStore.cpf}
                onChange={userStore.userCreateOrEditStore.handleValueChange}
                disabled={
					userStore.userCreateOrEditStore.loading ||
					userStore.userCreateOrEditStore.cpfBeeingValidated
				}
                onMaskComplete={(cpfString) => {
                    if (shouldTryGetUserFromCpf) {
                        userStore.userCreateOrEditStore.tryGetUserFromCpf(cpfString);
                    } else {
                        userStore.userCreateOrEditStore.cpfValidate(cpfString);
                    }
                }}
            />
        );

        return (
            <Paper>
                <div className="createOrEditUserFormContainer">
                    {userStore.loading ?
                        <Loading /> :
                        <>
                            <Typography variant="h6">{strings.users.createOrEdit.title(!!userStore.userCreateOrEditStore.selectedEditUser)}</Typography>
                            <div className="createOrEditUserForm">
                                {shouldTryGetUserFromCpf ?
                                    <div className="createOrEditUserFormFullWidthSection">
                                        <Typography variant="subtitle1">{strings.users.createOrEdit.documents.title}</Typography>
                                        {cpfMaskedTextfield}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={() => userStore.userCreateOrEditStore.tougleIngnoreCPFValidation()}
                                                    color="primary"
                                                />
                                            }
                                            label={strings.users.createOrEdit.checkbox.ingnoreCPFValidation}
                                        />
                                    </div> :
                                    <>
                                        <div className="createOrEditUserFormFullWidthSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.avatar.title}</Typography>
                                            <SingleImageCase
                                                src={userStore.userCreateOrEditStore.imageUploaderService.src}
                                                status={userStore.userCreateOrEditStore.imageUploaderService.status}
                                                progress={userStore.userCreateOrEditStore.imageUploaderService.progress}
                                                selectImage={userStore.userCreateOrEditStore.imageUploaderService.selectImage}
                                                clear={userStore.userCreateOrEditStore.imageUploaderService.clear}
                                                uploadImage={userStore.userCreateOrEditStore.imageUploaderService.uploadImage}
                                                imageCropper={{
                                                    setCropper: userStore.userCreateOrEditStore.imageUploaderService.setCropper,
                                                    aspect: 3 / 4,
                                                    startCrop: userStore.userCreateOrEditStore.imageUploaderService.startCrop,
                                                    stopCrop: userStore.userCreateOrEditStore.imageUploaderService.stopCrop,
                                                    isCropping: userStore.userCreateOrEditStore.imageUploaderService.isCropping,
                                                }}
                                            />
                                        </div>
                                        <div className="createOrEditUserFormSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.personalInfo.title}</Typography>
                                            <TextField
                                                label={strings.users.createOrEdit.personalInfo.name}
                                                name="name"
                                                type="name"
                                                value={userStore.userCreateOrEditStore.name}
                                                disabled={userStore.userCreateOrEditStore.loading}
                                                onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                charactersLimit={35}
                                                required={true}
                                                className="fieldCharacter"
                                            />
                                             <p className="smallCounter"><small>{34 - userStore.userCreateOrEditStore.name.length}</small> caracteres restantes.</p>
                                            <MaskedTextField
                                                label={strings.users.createOrEdit.personalInfo.phone}
                                                name="phone"
                                                type="phone"
                                                mask="(99) 99999-9999"
                                                value={userStore.userCreateOrEditStore.phone}
                                                disabled={userStore.userCreateOrEditStore.loading}
                                                onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                className="fieldCharacter"
                                            />
                                            <DatePicker
                                                name="birthday"
                                                type="birthday"
                                                disabled={userStore.userCreateOrEditStore.loading}
                                                label={strings.users.createOrEdit.personalInfo.birthday}

                                                value={userStore.userCreateOrEditStore.birthday}
                                                onChange={userStore.userCreateOrEditStore.handleBirthdayChange}

                                                required={true}
                                                className="fieldCharacter"
                                            />
                                        </div>
                                        <div className="createOrEditUserFormSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.documents.title}</Typography>
                                            {cpfMaskedTextfield}
                                            <div className="fieldInputs">
                                                <MaskedTextField
                                                    label={strings.users.table.header.rg}
                                                    name="rgNumber"
                                                    mask={"**************"}
                                                    value={userStore.userCreateOrEditStore.rgNumber}
                                                    disabled={userStore.userCreateOrEditStore.loading}
                                                    onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                    required={true}
                                                    className="fieldCharacter"
                                                />
                                                <TextField
                                                    label={strings.users.createOrEdit.documents.rgInstitution}
                                                    name="rgInstitution"
                                                    value={userStore.userCreateOrEditStore.rgInstitution}
                                                    disabled={userStore.userCreateOrEditStore.loading}
                                                    onChange={userStore.userCreateOrEditStore.handleValueChangeWithoutSpaces}
                                                    required={true}
                                                    className="fieldCharacter"
                                                />
                                            </div>
                                        </div>
                                        <div className="createOrEditUserFormSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.school.title}</Typography>
                                            <EnumPicker
                                                label={strings.users.createOrEdit.school.schooling}
                                                name="schooling"
                                                value={userStore.userCreateOrEditStore.schooling}
                                                disabled={userStore.userCreateOrEditStore.loading}
                                                onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                options={api.allTranslatedValuesSchooling()}
                                                valueFromOption={api.valueFromTranslationSchooling}
                                                required={true}
                                                className="fieldCharacter"

                                            />
                                            <Autosuggest
                                                onChange={userStore.userCreateOrEditStore.selectInstitution}
                                                label={strings.users.createOrEdit.school.institution}
                                                disabled={userStore.userCreateOrEditStore.loading || userStore.userCreateOrEditStore.autocompleteInstitutionService.loading}
                                                items={userStore.userCreateOrEditStore.autocompleteInstitutionService.autocompleteInstitutions}
                                                value={userStore.userCreateOrEditStore.institution ?
                                                    {
                                                        id: userStore.userCreateOrEditStore.institution.id,
                                                        value: `${
                                                                userStore.userCreateOrEditStore.institution.name
                                                            } - ${
                                                                userStore.userCreateOrEditStore.institution.city
                                                            }/${
                                                                userStore.userCreateOrEditStore.institution.state
                                                            }`,
                                                    } :
                                                    undefined
                                                }
                                                getOptions={userStore.userCreateOrEditStore.autocompleteInstitutionService.getInstitutions}
                                                clearOptions={userStore.userCreateOrEditStore.autocompleteInstitutionService.clear}
                                                required={true}
                                                className="fieldCharacter"
                                            />
                                            <Autosuggest
                                                onChange={userStore.userCreateOrEditStore.selectCourse}
                                                label={strings.users.createOrEdit.school.course}
                                                disabled={userStore.userCreateOrEditStore.loading || userStore.userCreateOrEditStore.autocompleteCourseService.loading}
                                                items={userStore.userCreateOrEditStore.autocompleteCourseService.autocompleteCourses}
                                                getOptions={userStore.userCreateOrEditStore.autocompleteCourseService.getCourses}
                                                clearOptions={userStore.userCreateOrEditStore.autocompleteCourseService.clear}
                                                value={userStore.userCreateOrEditStore.course ?
                                                    {
                                                        id: userStore.userCreateOrEditStore.course.id,
                                                        value: userStore.userCreateOrEditStore.course.name,
                                                    } : undefined
                                                }
                                                required={true}
                                                className="fieldCharacter"
                                            />
                                            <MaskedTextField
                                                label={strings.users.createOrEdit.school.studentId}
                                                name="studentId"
                                                mask={"**************"}
                                                value={userStore.userCreateOrEditStore.studentId}
                                                disabled={userStore.userCreateOrEditStore.loading}
                                                onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                required={true}
                                                className="fieldCharacter"
                                            />
                                        </div>
                                        <div className="createOrEditUserFormSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.login.title}</Typography>
                                            <TextField
                                                label={strings.users.createOrEdit.login.email}
                                                name="email"
                                                type="email"
                                                value={userStore.userCreateOrEditStore.email}
                                                disabled={userStore.userCreateOrEditStore.loading || userStore.userCreateOrEditStore.emailBeeingValidated}
                                                errorMessage={userStore.userCreateOrEditStore.emailValidationErrorMessage}
                                                onChange={userStore.userCreateOrEditStore.handleValueChange}
                                                className="fieldCharacter"
                                            />
                                        </div>
                                        <div className="createOrEditUserFormSection">
                                            <Typography variant="subtitle1">{strings.users.createOrEdit.address.title}</Typography>
                                            <MaskedTextField
                                                label={strings.users.createOrEdit.address.cep}

                                                name="cep"
                                                mask="99.999-999"
                                                disabled={userStore.userCreateOrEditStore.loading || userStore.userCreateOrEditStore.addressService.cepBeeingValidated}

                                                value={userStore.userCreateOrEditStore.addressService.cep}
                                                onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                onMaskComplete={(cepString) => {
                                                    userStore.userCreateOrEditStore.addressService.cepValidate(cepString);
                                                }}
                                                errorMessage={userStore.userCreateOrEditStore.addressService.cepValidationErrorMessage}
                                            />
                                            {!userStore.userCreateOrEditStore.addressService.cepBeeingValidated && userStore.userCreateOrEditStore.addressService.cepValidated &&
                                                <>
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.street}
                                                        name="street"
                                                        value={userStore.userCreateOrEditStore.addressService.street}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.number}
                                                        name="number"
                                                        value={userStore.userCreateOrEditStore.addressService.number}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.neighborhood}
                                                        name="neighborhood"
                                                        value={userStore.userCreateOrEditStore.addressService.neighborhood}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.city}
                                                        name="city"
                                                        value={userStore.userCreateOrEditStore.addressService.city}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.state}
                                                        name="state"
                                                        value={userStore.userCreateOrEditStore.addressService.state}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                    <TextField
                                                        label={strings.users.createOrEdit.address.uf}
                                                        name="uf"
                                                        value={userStore.userCreateOrEditStore.addressService.uf}
                                                        disabled={userStore.userCreateOrEditStore.loading}
                                                        onChange={userStore.userCreateOrEditStore.addressService.handleValueChange}
                                                    />
                                                </>
                                            }
                                        </div>
                                        <div className="createOrEditUserFormFooter">
                                            <div className="buttonsContainer">
                                                <Button emphasis onClick={() => userStore.userCreateOrEditStore.createOrEditUser()}>
                                                    {strings.users.createOrEdit.buttons.save}
                                                </Button>
                                                <Button emphasis onClick={() => userStore.userCreateOrEditStore.createOrEditUser(true)}>
                                                    {strings.users.createOrEdit.buttons.saveAndPrint}
                                                </Button>
                                                <Button onClick={() => userStore.userCreateOrEditStore.saveUserAndContinue()}>
                                                    {strings.users.createOrEdit.buttons.new}
                                                </Button>
                                                <LinkButton onClick={() => routerStore.replace("/dashboard/students")}>
                                                    {strings.users.createOrEdit.buttons.back}
                                                </LinkButton>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </Paper>
        );
    }
}
