const IMG_EL = "img";
const CANVAS_EL = "canvas";
const CTX_2D_TYPE = "2d";

function calculateSize(startWidth: number, startHeight: number, maxSize: number) {
	let width = startWidth;
	let height = startHeight;

	if (width > height) {
		if (width > maxSize) {
			height *= maxSize / width;
			width = maxSize;
		}
	} else {
		if (height > maxSize) {
			width *= maxSize / height;
			height = maxSize;
		}
	}
	return { width, height };
}

function extractImageData(canvas: HTMLCanvasElement) {
	return canvas.toDataURL("image/png").split(",")[1];
}

export const craftImageBuffer = (file: File, maxSize: number, onResolve: (buffer: Buffer) => void) => {

	const reader = new FileReader();
	reader.onload = () => {

		const image = document.createElement(IMG_EL);
		image.onload = () => {
			const canvas = document.createElement(CANVAS_EL);
			const ctx = canvas.getContext(CTX_2D_TYPE);

			if (ctx) {
				ctx.drawImage(image, 0, 0);
				const { width , height} = calculateSize(image.width, image.height, maxSize);
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(image, 0, 0, width, height);

				const buffer = Buffer.from(extractImageData(canvas), "base64");
				onResolve(buffer);

			} else {
				console.log("Context does not exist");
			}
		};

		if (reader.result) {
			image.src = (reader.result as string);
		} else {
			console.log("reader result is null");
			reader.abort();
		}
	};
	reader.readAsDataURL(file);
};

export const downloadFile = (url: string) => {
	const link = document.createElement("a");
	link.setAttribute("id", "download-file-link");
	link.setAttribute("download", "");
	link.setAttribute("style", "display:none");
	link.setAttribute("href", url);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
