// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: API
import * as api from "../../../../../admin-api-web";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Components
import Button from "../../../../../components/Button";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided, DraggableLocation } from "react-beautiful-dnd";

interface IProps {
    selectedColumns: api.UsersColumnType[];
    saveSelectedColumns: (selectedColumns: api.UsersColumnType[]) => void;
}

interface IState {
    availableColumns: api.UsersColumnType[];
    selectedColumns: api.UsersColumnType[];
}

export default class EditUserTable extends React.Component<IProps, IState> {
    public componentWillMount() {
        this.setState({
            availableColumns: api.allValuesUsersColumnType().filter((columnType) => !this.props.selectedColumns.includes(columnType)),
            selectedColumns: this.props.selectedColumns,
        });
    }

    public getRows = (draggableLocationId: string) => {
        return draggableLocationId === "availableColumns" ?
            this.state.availableColumns :
            this.state.selectedColumns;
    }

    public move = (sourceList: api.UsersColumnType[], destinationList: api.UsersColumnType[], source: DraggableLocation, destination: DraggableLocation) => {
        const newSourceList: api.UsersColumnType[] = Array.from(sourceList);
        const newDestinationList: api.UsersColumnType[] = Array.from(destinationList);
        const [removed] = newSourceList.splice(source.index, 1);

        newDestinationList.splice(destination.index, 0, removed);

        return {
            newSourceList,
            newDestinationList,
        };
    };

    public reorder = (list: api.UsersColumnType[], startIndex: number, endIndex: number) => {
        const result: api.UsersColumnType[] = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    public onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const newColumns = this.reorder(
                this.getRows(source.droppableId),
                source.index,
                destination.index,
            );

            if (source.droppableId === "availableColumns") {
                this.setState({
                    availableColumns: newColumns,
                });
            } else {
                this.setState({
                    selectedColumns: newColumns,
                });
            }
        } else {
            const newLists = this.move(
                this.getRows(source.droppableId),
                this.getRows(destination.droppableId),
                source,
                destination,
            );

            if (source.droppableId === "availableColumns") {
                this.setState({
                    availableColumns: newLists.newSourceList,
                    selectedColumns: newLists.newDestinationList,
                });
            } else {
                this.setState({
                    availableColumns: newLists.newDestinationList,
                    selectedColumns: newLists.newSourceList,
                });
            }
        }
    }

    public render() {
        const { saveSelectedColumns } = this.props;
        const { selectedColumns, availableColumns } = this.state;

        return (
            <div className="editUsersTableContainer">
                <div className="editUsersTableContainerDragDropContextContainer">
                    <DragDropContext
                        onDragEnd={this.onDragEnd}
                    >
                        <Droppable
                            droppableId="availableColumns"
                        >
                            {(provided, snapshot) => (
                                <div
                                    className={!snapshot.isDraggingOver ? "columnRowContainer" : "columnRowDraggingContainer"}

                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <h3>{strings.users.table.editTable.availableColumns}</h3>
                                    {availableColumns.map((row, index) => {
                                        return (
                                            <Draggable
                                                key={row} draggableId={row} index={index}
                                            >
                                                {(providedDraggable, snapshotDraggable) => (
                                                    <div
                                                        className={!snapshotDraggable.isDragging ? "columnRowContainerItem" : "columnRowDraggingContainerItem"}

                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}
                                                    >
                                                        {api.translateUsersColumnType(row)}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="customRows">
                            {(provided, snapshot) => (
                                <div
                                    className={!snapshot.isDraggingOver ? "columnRowContainer" : "columnRowDraggingContainer"}

                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <h3>{strings.users.table.editTable.selectedColumns}</h3>
                                    {selectedColumns.map((row, index) => (
                                        <Draggable
                                            key={row}
                                            draggableId={row}
                                            index={index}>
                                            {(providedDraggable, snapshotDraggable) => (
                                                <div
                                                    className={!snapshotDraggable.isDragging ? "columnRowContainerItem" : "columnRowDraggingContainerItem"}

                                                    ref={providedDraggable.innerRef}
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}
                                                >
                                                    {api.translateUsersColumnType(row)}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <Button
                    onClick={() => saveSelectedColumns(this.state.selectedColumns)}
                >
                    {strings.users.table.editTable.save}
                </Button>
            </div>
        );
    }
}
