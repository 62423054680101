import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { uiStore } from "../_rootStore";
import { routerStore } from "../_rootStore";
import strings from "../../resources/strings";

export default class AuthStore extends VariableChangeHandler {
	// Control
	@observable public adminUser: api.AdminUser | null = null;

	// Variables
	@observable public loading: boolean = false;
	@observable public email: string = "";
	@observable public password: string = "";

	constructor() {
		super();
		const adminUser = localStorage.getItem("adminUser");
		this.adminUser = adminUser ? JSON.parse(localStorage.getItem("adminUser") as string) : null;
		this.updateAdminUser();
	}

	@action
	public updateAdminUser = () => {
		api.getCurrentAdminUser().then((adminUser) => {
			this.adminUser = adminUser;
			localStorage.setItem("adminUser", JSON.stringify(adminUser as api.AdminUser));
		}).catch((err) => {
			console.log(err);
		});
	}

	@computed
	public get permissions() {
		if (this.adminUser) {
			return this.adminUser.permissions;
		} else {
			return [];
		}
	}

	@computed
	public get isSuperUser() {
		return this.adminUser && this.adminUser.isSuperAdmin;
	}

	@action
	public hasPermission = (permission: api.Permission) => {
		if (this.adminUser && this.adminUser.isSuperAdmin) {
			return true;
		}

		return !!this.permissions.find((item) => item === permission);
	}

	@action public verifySuperUser = async () => {
		if (!this.isSuperUser) {
			routerStore.replace("/dashboard");
		}
	}

	@action
	public verifyPermission = (permission: api.Permission) => {
		if (!this.hasPermission(permission)) {
			routerStore.replace("/dashboard");
		}
	};

	@computed
	public get isLoginFormReady() {
		return this.email.length > 5 && this.password.length >= 6;
	}

	@action
	public login = async () => {
		this.loading = true;

		try {
			this.adminUser = await api.loginAdminUser(
				this.email.trim(),
				this.password.trim(),
			);

			localStorage.setItem("adminUser", JSON.stringify(this.adminUser as api.AdminUser));
			routerStore.push("/dashboard");
		} catch (e) {
			let errorMessage: string;

			if (e.message) {
				errorMessage = e.message;
			} else {
				errorMessage = inspect(e);
			}

			uiStore.openSnackbar(errorMessage);
		} finally {
			this.loading = false;
		}
	}

	@action
	public logout = async () => {
		const onConfirm = async () => {
			try {
				await api.logout();
				localStorage.clear();
				this.adminUser = null;
				routerStore.push("/login");
			} catch (e) {
				let errorMessage: string;

				if (e.message) {
					errorMessage = e.message;
				} else {
					errorMessage = inspect(e);
				}

				uiStore.openSnackbar(errorMessage);
			}
		};

		uiStore.showDialog(strings.dialogs.logout, onConfirm);
	}

	@action
	public isLogged = async () => {
		return !!this.adminUser;
	}

	@action
	public authenticate = async () => {
		if (!(await this.isLogged())) {
			routerStore.replace("/login");
		}
	}

	@action
	public openDashboardIfAuthenticated = async () => {
		if (await this.isLogged()) {
			routerStore.replace("/dashboard");
		}
	}

	@action
	public resetPassword = async () => {
		this.loading = true;

		try {
			await api.sendResetPasswordEmail(this.email);

			uiStore.showAlert({
				title: strings.recoverPassword.confimationDialog.title,
				message: strings.recoverPassword.confimationDialog.message,
			}, () => {
				routerStore.push("/login");
			});
		} catch (e) {
			let errorMessage: string;

			if (e.message) {
				errorMessage = e.message;
			} else {
				errorMessage = inspect(e);
			}

			uiStore.openSnackbar(errorMessage);
		} finally {
			this.loading = false;
		}
	}
}
