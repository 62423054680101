import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { uiStore } from "../../stores/_rootStore";

// Components
import { IItem } from "../../components/Autosuggest";

export default class CourseAutocompleteStore extends VariableChangeHandler {
    @observable public loading: boolean = false;

    @observable public courses: api.Course[] = [];
    @observable public course: api.Course | null = null;

    @computed
    public get autocompleteCourses(): IItem[] {
        return this.courses.map((course) => ({
            id: course.id,
            value: course.name,
        }));
    }

    @action
    public selectCourse = async (courseId: string | null) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!courseId) {
            this.course = null;
            this.loading = false;
            return;
        }

        try {
            this.course = this.courses.find((course) => course.id === courseId) || await api.getCourse(courseId);
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public getCourses = async (name: string) => {
        await this.clear();
        this.courses = (await api.getCourses({ name, isActive: true }, 0, 20)).results;
    }

    @action
    public clear = async () => {
        this.courses = [];
        this.course = null;
    }
}
