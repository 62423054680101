import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";

// Components
import { Route, Switch } from "react-router-dom";
import CoursesTable from "./CoursesTable";
import CreateOrEditCourses from "./CreateOrEdit";

// Resources
import * as api from "../../../admin-api-web";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class CoursesContainer extends React.Component<IProps> {
	public async componentDidMount() {
		this.props.authStore.verifyPermission(api.Permission.manageCourses);
	}

	public render() {
		return (
			<Switch>
				<Route exact path="/dashboard/courses/" component={CoursesTable} />
				<Route exact path="/dashboard/courses/new" component={CreateOrEditCourses} />
				<Route exact path="/dashboard/courses/edit/:courseId" component={CreateOrEditCourses} />
			</Switch>
		);
	}
}
