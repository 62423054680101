import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";

// Components
import { Route, Switch } from "react-router-dom";
import InstitutionsTable from "./InstitutionsTable";
import CreateOrEditInstitution from "./CreateOrEdit";

// Resources
import * as api from "../../../admin-api-web";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class InstitutionsContainer extends React.Component<IProps> {
	public async componentDidMount() {
		this.props.authStore.verifyPermission(api.Permission.manageInstitutions);
	}

	public render() {
		return (
			<Switch>
				<Route exact path="/dashboard/institutions/" component={InstitutionsTable} />
				<Route exact path="/dashboard/institutions/new" component={CreateOrEditInstitution} />
				<Route exact path="/dashboard/institutions/edit/:institutionId" component={CreateOrEditInstitution} />
			</Switch>
		);
	}
}
