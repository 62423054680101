// MARK: React
import * as React from "react";
import "./style.scss";
import * as api from "../../../admin-api-web";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";
import HomeStore from "../../../stores/HomeStore";

// MARK: UI
import { Typography } from "@material-ui/core";

// MARK: Components
import { IRowItem } from "../../../components/Table/TableRow";
import ContactCard from "../../../components/ContactCard";
import Table from "../../../components/Table";
import strings from "../../../resources/strings";
import { CardPrintCounter } from "../../../components/CardPrintCounter";

interface IStores {
	homeStore: HomeStore;
	authStore: AuthStore;
}

interface IProps extends IStores {

}

interface IState {
	isConfirmingPrint: boolean;
}

@inject("homeStore", "authStore")
@observer
export default class HomeContainer extends React.Component<IProps, IState> {
	public componentDidMount = async () => {
		if (this.props.authStore.adminUser?.isSuperAdmin) {
			await this.props.homeStore.getHomeData();
		} else {
			await this.props.homeStore.getUserHomeData();
		}
	}

	public componentWillMount = () => {
		this.setState({
			isConfirmingPrint: false,
		});
	}

	constructor(props: any) {
        super(props);
    }

	public render() {
		const { homeStore, authStore } = this.props;
		const pageStrings = strings.home;
		return (
			authStore.adminUser &&
				<>
					<Typography variant="h4">{`Olá, bem vindo ao dashboard da FEC${authStore.adminUser ? `, ${authStore.adminUser.name}` : ""}!`}</Typography>
					{authStore.adminUser.manager &&
					<>
						<ContactCard
							name={authStore.adminUser.manager.name}
							role={authStore.adminUser.manager.type ?
								api.translateAdminUserType(authStore.adminUser.manager.type) :
								null}
							phone={authStore.adminUser.manager.phone}
							email={authStore.adminUser.manager.email}
						/>
					</>
					}
					{authStore.adminUser.coordinator &&
					<>
						<ContactCard
							name={authStore.adminUser.coordinator.name}
							role={authStore.adminUser.coordinator.type ?
								api.translateAdminUserType(authStore.adminUser.coordinator.type) :
								null}
							phone={authStore.adminUser.coordinator.phone}
							email={authStore.adminUser.coordinator.email}
						/>
					</>
					}
					{homeStore.homeDataForAdmin && (
							<CardPrintCounter
								daily={homeStore.homeDataForAdmin.daily}
								yearly={homeStore.homeDataForAdmin.yearly}
								monthly={homeStore.homeDataForAdmin.monthly}
							/>
						)}
					<div style={{marginTop: "20px", maxWidth: 700}}>
						{authStore.adminUser.isSuperAdmin &&
						<>
							<Table
								title={pageStrings.title}
								loading={homeStore.loader}
								header={pageStrings.headers}
								data={homeStore.homeDataRow as IRowItem[]}
								pageOffset={0}
							/>
						</>
						}
					</div>
				</>
		);
	}
}
