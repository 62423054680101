import { inspect } from "util";

import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { action, observable, reaction } from "mobx";

// Stores
import { uiStore, routerStore } from "../../_rootStore";

// Resources
import strings from "../../../resources/strings";

export default class InstitutionCreateOrEditStore extends VariableChangeHandler {
    // Control
    @observable public loading: boolean = false;
    @observable public selectedInstitution: api.Institution | null = null;

    // Variables
    @observable public name: string = "";
    @observable public stateUF: api.StateUF = api.StateUF.AC;
    @observable public city: string = "";
    @observable public isActive: boolean = true;

    // Methods
    constructor() {
        super();

        reaction(() => this.selectedInstitution, (selectedInstitution) => {
            if (selectedInstitution) {
                this.setInstitutionFields(selectedInstitution);
            } else {
                this.clear();
            }
        });
    }

    @action
    public createOrEditInstitution = async () => {
        if (this.loading)
            return;

        this.loading = true;

        try {
            if (!this.selectedInstitution) {
                const createdInstitution = await api.createInstitution({
                    name: this.name,
                    state: this.stateUF,
                    city: this.city,
                    isActive: this.isActive,
                });

                this.selectedInstitution = createdInstitution;
            } else {
                const editedInstitution = await api.editInstitution(this.selectedInstitution.id, {
                    name: this.name,
                    state: this.stateUF,
                    city: this.city,
                    isActive: this.isActive,
                });

                this.selectedInstitution = editedInstitution;
            }

            routerStore.push("/dashboard/institutions");
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public setInstitutionFields = async (selectedInstitution: api.Institution) => {
        this.clear();

        this.name = selectedInstitution.name;
        this.stateUF = selectedInstitution.state;
        this.city = selectedInstitution.city;
        this.isActive = selectedInstitution.isActive;
    }

    @action private clear = () => {
        this.name = "";
        this.stateUF = api.StateUF.AC;
        this.city = "";
        this.isActive = true;
    }
}
