import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import TeamStore from "../../../../stores/TeamStore";

// Components
import Table from "../../../../components/Table";
import { Toolbar } from "@material-ui/core";
import Filter from "../../../../components/Filter";
import Divider from "@material-ui/core/Divider";

// Resources
import strings from "../../../../resources/strings";

interface IProps {
	teamStore: TeamStore;
}

@inject("teamStore")
@observer
export default class TeamTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.teamStore.fetchInitialAdminUsers();
		await this.props.teamStore.getAdminUsersPendingAnalysis();
	}

	public render() {
		const { teamStore } = this.props;
		return (
			<div>
				{teamStore.adminUsersPendingAnalysis.length > 0 && <>
					<Table
						title={strings.adminUsers.table.pendingAnalysis}
						loading={teamStore.adminUsersPendingAnalysisLoading}
						prevPage={teamStore.previousPageAdminUserPendingAnalysis}
						nextPage={teamStore.nextPageAdminUserPendingAnalysis}
						header={teamStore.tableHeader}
						data={teamStore.adminUserPendingAnalysisTableRows}
						pageOffset={teamStore.adminUsersPendingAnalysisPageOffset}
						onClick={(adminUserID: string) => teamStore.wantToApproveAdminUser(adminUserID)}
					/>
					<Divider className="divider" />
				</>}
				<Table
					headerView={
						<Toolbar>
							<Filter
								disabled={teamStore.loading}
								fields={teamStore.adminUserFilterStore.fields}
							/>
						</Toolbar>
					}
					title={strings.adminUsers.table.title}
					loading={teamStore.loading}
					prevPage={teamStore.previousPage}
					nextPage={teamStore.nextPage}
					header={teamStore.tableHeader}
					data={teamStore.tableRows}
					pageOffset={teamStore.pageOffset}
					onAddClick={teamStore.goToNewUser}
					onEditClick={teamStore.goToEditUser}
					onDetailsClick={teamStore.goToUserHistory}
					onDesaprovalClick={(adminUserID: string) => teamStore.wantToDisapproveAdminUser(adminUserID)}
					rowsPerPageConfigs={{
						rowsPerPage: teamStore.rowsPerPage,
						onRowsPerPageChange: teamStore.handleSelectElementChange,
						refetchData: teamStore.fetchData,
						info: teamStore.tableRowRange,
					}}
					/>
			</div>
		);
	}
}
