import * as React from "react";
import "./style.scss";

// Tools
import ImageUploader, { IUploadImage } from "../../service/ImageUploaderService";

// Mobx
import { observer } from "mobx-react";

// Resources
import strings from "../../resources/strings";

// Components
import ImageItem from "../ImageItem";
import Button from "../Button";
import LinkButton from "../LinkButton";

import Fab from "@material-ui/core/Fab";
import { Typography, IconButton } from "@material-ui/core";
import Cropper from "react-cropper";

// Icons
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

interface IProps {
	src: string | null;
	status: ImageUploader["status"];
	progress?: number;
	selectImage: () => void;
	clear: () => void;
	uploadImage: (uploadImageObject: IUploadImage) => void;
	imageCropper?: {
		setCropper: (cropper: Cropper | null) => void;
		aspect?: number | null;
		isCropping: boolean;
		startCrop: () => void;
		stopCrop: () => void;
	};
}

@observer
export default class ImageCase extends React.Component<IProps> {
	public cropper: Cropper | null;

	public render = () => {
		const { src, status, progress, clear, selectImage, uploadImage, imageCropper } = this.props;

		return (
			<div className="singleImageCase">
				{
					src ?
						(imageCropper && imageCropper.isCropping ?
							<div className="cropContainer">
								<div className="cropContainerCropperContainer">
									<Cropper
										ref={(cropper) => {
											this.cropper = cropper;
											imageCropper.setCropper(cropper);
										}}
										src={src}
										aspectRatio={imageCropper.aspect || undefined}
										guides={true}
										rotatable={true}
										style={{ maxHeight: "60vh", maxWidth: "100%" }}
										zoomable={true}
									/>
									<div className="cropContainerCropperContainerRotateButtons">
										<IconButton
											className="rotateIconButton"
											onClick={() => {
												if (this.cropper) {
													this.cropper.rotate(-90);
												}
											}}
										>
											<RotateLeftIcon />
										</IconButton>
										<IconButton
											className="rotateIconButton"
											onClick={() => {
												if (this.cropper) {
													this.cropper.rotate(90);
												}
											}}
										>
											<RotateRightIcon />
										</IconButton>
									</div>
								</div>
								<div className="cropContainerButtonsContainer">
									<Button
										onClick={() => uploadImage({ withCrop: !!imageCropper })}
									>
										{strings.components.image.cropImage}
									</Button>
									<LinkButton onClick={imageCropper.stopCrop}>{strings.components.image.cancel}</LinkButton>
								</div>
							</div> :
							<ImageItem
								src={src}
								status={status}
								progress={progress}
								removeItem={clear}
								startCrop={imageCropper && imageCropper.startCrop}
								reloadItem={() => uploadImage({ withCrop: !!imageCropper })}
							/>
						) :
						<div className="singleImageUploadeButtonContainer">
							<Fab variant="extended" onClick={selectImage}>
								<AddPhotoIcon />
								<Typography className="buttonTitle">{strings.components.image.addImage}</Typography>
							</Fab>
						</div>
				}
			</div>
		);
	}
}
