import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed, reaction } from "mobx";
import { uiStore, routerStore } from "../_rootStore";

// Components
import { IRowItem } from "../../components/Table/TableRow";

// Resources
import strings from "../../resources/strings";

// Stores
import CourseFilterStore from "./CourseFilterStore";

export default class CourseStore extends VariableChangeHandler {
    // Sub Stores
    public courseFilterStore: CourseFilterStore = new CourseFilterStore();

    // Control
    @observable public loading: boolean = false;
    @observable public onEditMode: boolean = false;
    @observable public selectedCourse: api.Course | null = null;
    @observable public pageOffset: number = 0;

    // Variables
    @observable public courses: api.Course[] = [];
    @observable public name: string = "";
    @observable public isActive: boolean = true;

    // Methods
    constructor() {
        super();

        reaction(() => this.courseFilterStore.filter, async (filter) => {
            this.pageOffset = 0;
            this.courses = [];
            await this.getCourses(this.pageOffset);
        });
    }

    @computed
    public get tableHeader(): string[] {
        return [
            strings.courses.table.header.name,
            strings.courses.table.header.isActive,
            strings.courses.table.header.id,
        ];
    }

    @computed
    public get tableRows(): IRowItem[] {
        return this.courses.map((course) => ({
            id: course.id,
            data: [
                {
                    value: course.name,
                },
                {
                    value: course.isActive ? strings.yes : strings.no,
                },
                {
                    value: course.id,
                },
            ],
        }));
    }

    @action
    public clear = async () => {
        this.pageOffset = 0;
        this.courses = [];
    }

    // Pagination
    @observable public rowsPerPage: number = 10;
    @observable public totalOfRows: number = 25;

    @computed
    public get tableRowRange() {

        const page = this.pageOffset + 1;
        let end = this.rowsPerPage * page;
        const start = end - this.rowsPerPage;

        if (end > this.totalOfRows) {
            end =  this.totalOfRows;
        }

        return `${start} - ${end} de ${this.totalOfRows}`;
    }

    @action
    public fetchData = async () => {
        await this.getCourses(this.pageOffset);
    }

    @action
    public getCourses = async (pageOffset?: number | null) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!pageOffset) {
            pageOffset = 0;
        }

        if (pageOffset < 0) {
            this.loading = false;
            return;
        }

        try {
            const newCourses = await api.getCourses(this.courseFilterStore.filter, pageOffset, this.rowsPerPage);

            this.totalOfRows = newCourses.total;
            if (newCourses.results.length > 0) {
                this.courses = newCourses.results;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            let errorMessage: string;

			if (e.message) {
				errorMessage = e.message;
			} else {
				errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public nextPage = async () => {
        await this.getCourses(this.pageOffset + 1);
    }

    @action
    public previousPage = async () => {
        await this.getCourses(this.pageOffset - 1);
    }

    @action
    public getCourse = async (courseId: string) => {
        if (this.loading)
            return;

        this.loading = true;

        try {
            this.selectedCourse = this.courses.find((course) => course.id === courseId) || await api.getCourse(courseId);
            this.setCourseFields(this.selectedCourse);
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public createCourse = async () => {
        if (this.loading)
            return;

        this.loading = true;

        try {
            const createdCourse = await api.createCourse({
                name: this.name,
                isActive: this.isActive,
            });

            this.courses.push(createdCourse);
            this.selectedCourse = createdCourse;
            routerStore.push("/dashboard/courses");
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public goToEditCourse = async (courseId: string) => {
        if (this.loading)
            return;

        this.loading = true;
        this.selectedCourse = null;

        try {
            this.selectedCourse = this.courses.find((course) => course.id === courseId) || await api.getCourse(courseId);

            this.onEditMode = true;
            this.setCourseFields(this.selectedCourse);
            routerStore.push(`/dashboard/courses/edit/${courseId}`);
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public setCourseFields = async (selectedCourse: api.Course) => {
        this.name = selectedCourse.name;
        this.isActive = selectedCourse.isActive;
    }

    @action
    public setEditMode = (mode: boolean) => {
        this.onEditMode = mode;
    }

    @action
    public editCourse = async () => {
        if (this.loading) return;
        this.loading = true;
        if (this.selectedCourse) {
            try {
                const editedCourse = await api.editCourse(this.selectedCourse.id, {
                    name: this.name,
                    isActive: this.isActive,
                });

                this.courses = this.courses.map((course) => course.id === editedCourse.id ? editedCourse : course);
                this.selectedCourse = editedCourse;
                routerStore.replace("/dashboard/courses");
            } catch (e) {
                let errorMessage: string;

                if (e.message) {
                    errorMessage = e.message;
                } else {
                    errorMessage = inspect(e);
                }

                uiStore.openSnackbar(errorMessage);
            } finally {
                this.loading = false;
            }
        }
    }

    @action public goToNewCourse = () => {
		this.onEditMode = false;
		this.clearEditorFields();
		routerStore.push("/dashboard/courses/new");
    }

    @action private clearEditorFields = () => {
        this.name = "";
        this.isActive = true;
    }
}
