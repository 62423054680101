import { action } from "mobx";

export interface IEvent {
    target: {
        name: string;
        value: any;
    };
}

export default class VariableChangeHandler {

    @action
    public handleSelectElementChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (Object.keys(this).includes(e.target.name)) {
            this[e.target.name] = e.target.value;
        } else {
            throw {
                type: "Fatal",
                message: `Store doesn't include variable ${e.target.name}`,
            };
        }
    }

    @action
    public handleValueChange = (e: IEvent) => {
        if (Object.keys(this).includes(e.target.name)) {
            this[e.target.name] = e.target.value;
        } else {
            throw {
                type: "Fatal",
                message: `Store doesn't include variable ${e.target.name}`,
            };
        }
    }

    @action
    public handleValueChangeWithoutSpaces = (e: IEvent) => {
        if (Object.keys(this).includes(e.target.name)) {

            const value: string =  e.target.value;

            if (value.trim().length > 0) {

                this[e.target.name] = value;
            }

        } else {
            throw {
                type: "Fatal",
                message: `Store doesn't include variable ${e.target.name}`,
            };
        }
    }
}
