// MARK: Mobx
import { observable, action, reaction } from "mobx";

// MARK: API
import * as api from "../../../admin-api-web";

// MARK: Libraries
import { inspect } from "util";
import isValidCpf from "@brazilian-utils/is-valid-cpf";

// MARK: Resources
import strings from "../../../resources/strings";

// MARK: Stores
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { uiStore, routerStore } from "../../_rootStore";

// MARK: Services
import AddressService from "../../../service/AddressService";
import ImageUploaderService from "../../../service/ImageUploaderService";
import AutocompleteCourseService from "../../../service/AutocompleteCourseService";
import AutocompleteInstitutionService from "../../../service/AutocompleteInstitutionService";

export default class CreateOrEditUserStore extends VariableChangeHandler {
    // Services
    public addressService: AddressService = new AddressService();
    public imageUploaderService: ImageUploaderService = new ImageUploaderService();
    public autocompleteInstitutionService: AutocompleteInstitutionService = new AutocompleteInstitutionService();
    public autocompleteCourseService: AutocompleteCourseService = new AutocompleteCourseService();

    // Control
    @observable public errorMessage: string | null = null;
    @observable public loading: boolean = false;
    @observable public selectedUser: api.PartialUser | null = null;
    @observable public selectedEditUser: api.User | null = null;

    // Email
    @observable public emailValidated: boolean = false;
    @observable public emailBeeingValidated: boolean = false;
    @observable public emailValidationErrorMessage: string | null = null;

    // CPF
    @observable public ingnoreCPFValidation: boolean = false;
    @observable public cpfValidated: boolean = false;
    @observable public cpfBeeingValidated: boolean = false;
    @observable public cpfValidationErrorMessage: string | null = null;
    @observable public tryedGetUserFromCpf: boolean = false;

    // Variables
    // Login
    @observable public email: string = "";

    // Personal Info
    @observable public name: string = "";
    @observable public phone: string = "";
    @observable public birthday: Date | null = null ;

    // Documents
    @observable public cpf: string = "";
    @observable public rgNumber: string = "";
    @observable public rgInstitution: string = "";

    // Schooling
    @observable public institution: api.Institution | null = null;
    @observable public course: api.Course | null = null;
    @observable public schooling: api.Schooling = api.Schooling.earlyChildhoodEducation;
    @observable public studentId: string = "";

    // Methods
    constructor() {
        super();

        reaction(() => this.selectedEditUser, (selectedEditUser) => {
            if (selectedEditUser) {
                this.setUserFields(selectedEditUser);
            } else {
                this.clear();
            }
        });

        reaction(() => this.cpf, () => {
            this.cpfValidationErrorMessage = null;
        });

        reaction(() => this.email, () => {
            if (this.email !== "" && (!this.selectedUser || this.email !== this.selectedUser.email)) {
                this.emailValidate(this.email);
            }

            this.emailValidationErrorMessage = null;
        });
    }

    @action
    public selectInstitution = async (institutionId: string | null) => {
        await this.autocompleteInstitutionService.selectInstitution(institutionId);
        this.institution = this.autocompleteInstitutionService.institution;
    }

    @action
    public selectCourse = async (courseId: string | null) => {
        await this.autocompleteCourseService.selectCourse(courseId);
        this.course = this.autocompleteCourseService.course;
    }

    @action
    public saveUserAndContinue = async () => {
        await this.createOrEditUser();

        if (!this.errorMessage) {
            this.clear();
			routerStore.push(`/dashboard/students/new/`);
			window.location.reload();
        }
    }

    @action
    public createOrEditUser = async (print?: boolean) => {

        if (this.loading) {
            return;
        }

        this.loading = true;
        this.errorMessage = null;

        try {
            if (!this.institution) {
                uiStore.showAlert({
                    title: strings.dialogs.fieldValidation.title,
                    message: strings.dialogs.fieldValidation.message(strings.users.createOrEdit.school.institution),
                });

                return;
			}

			const minStudentIdChards = 4;
			if (this.studentId.trim().length < minStudentIdChards) {
                uiStore.showAlert({
                    title: strings.dialogs.fieldValidation.title,
                    message: strings.errors.minimunChars("Número de Matrícula", minStudentIdChards),
                });

                return;
            }

            if (!this.course) {
                uiStore.showAlert({
                    title: strings.dialogs.fieldValidation.title,
                    message: strings.dialogs.fieldValidation.message(strings.users.createOrEdit.school.course),
                });

                return;
            }

            if (!this.selectedEditUser) {
                if (this.birthday !== null) {
                    if (!print) {
                        const createdUser = await api.createUser({
                            avatar: await this.imageUploaderService.getUncertainfiedImage(),
                            password: null,
                            institutionId: this.institution.id,
                            courseId: this.course.id,
                            name: this.name,
                            birthday: this.birthday,
                            email: this.email || null,
                            phone: this.phone || null,
                            cpf: this.cpf,
                            rg: {
                                number: this.rgNumber,
                                institution: this.rgInstitution,
                            },
                            schooling: this.schooling,
                            studentId: this.studentId,
                            address: this.addressService.address,
                        });
                        this.selectedEditUser = createdUser;
                    } else {
                        await api.createUserWithPrint({
                            avatar: await this.imageUploaderService.getUncertainfiedImage(),
                            password: null,
                            institutionId: this.institution.id,
                            courseId: this.course.id,
                            name: this.name,
                            birthday: this.birthday,
                            email: this.email || null,
                            phone: this.phone || null,
                            cpf: this.cpf,
                            rg: {
                                number: this.rgNumber,
                                institution: this.rgInstitution,
                            },
                            schooling: this.schooling,
                            studentId: this.studentId,
                            address: this.addressService.address,
                        },
                        api.CardPrintType.full,
                        );
                    }
					routerStore.replace("/dashboard/students/");
                }
            } else {
                if (this.birthday !== null) {
                    if (!print) {
                    const editedUser = await api.editUser(this.selectedEditUser.id, {
                        avatar: await this.imageUploaderService.getUncertainfiedImage(),
                        institutionId: this.institution.id,
                        courseId: this.course.id,
                        name: this.name,
                        birthday: this.birthday,
                        email: this.email,
                        phone: this.phone,
                        cpf: this.cpf,
                        rg: {
                            number: this.rgNumber,
                            institution: this.rgInstitution,
                        },
                        schooling: this.schooling,
                        studentId: this.studentId,
                        address: this.addressService.address,
                    });

					this.selectedEditUser = editedUser;
					routerStore.replace("/dashboard/students/");
				} else {
                    const editedUser = await api.editUserWithPrint(this.selectedEditUser.id, {
                        avatar: await this.imageUploaderService.getUncertainfiedImage(),
                        institutionId: this.institution.id,
                        courseId: this.course.id,
                        name: this.name,
                        birthday: this.birthday,
                        email: this.email,
                        phone: this.phone,
                        cpf: this.cpf,
                        rg: {
                            number: this.rgNumber,
                            institution: this.rgInstitution,
                        },
                        schooling: this.schooling,
                        studentId: this.studentId,
                        address: this.addressService.address,
                    },
                    api.CardPrintType.full );

					this.selectedEditUser = editedUser;
					routerStore.replace("/dashboard/students/");
                }}
				this.clear();
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            this.errorMessage = errorMessage;
            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public handleBirthdayChange = (date: Date | null) => {
        this.birthday = date;
    }

    @action
    public tryGetUserFromCpf = (cpfString: string) => {
        if (this.cpfBeeingValidated) {
            return;
        }

        setTimeout(async () => {
            if (this.cpf === cpfString) {
                if (!isValidCpf(cpfString, undefined)) {
                    this.cpfValidationErrorMessage = strings.errors.invalidCPF;
                    return;
                }

                this.cpfBeeingValidated = true;

                try {

                    const selectedUser = await api.getUserFor(this.cpf);
                    this.selectedEditUser = selectedUser;
                    this.cpfValidated = true;
					routerStore.replace(`/dashboard/students/edit/${this.selectedEditUser.id}`);

                } catch (e) {
                    if (e.type === api.ErrorType.NotFound) {
                        this.tryedGetUserFromCpf = true;
                        this.cpfValidated = true;
                    } else {
                        let errorMessage: string;

                        if (e.message) {
                            errorMessage = e.message;
                        } else {
                            errorMessage = inspect(e);
                        }

                        this.cpfValidationErrorMessage = errorMessage;
                    }
                } finally {
                    this.cpfBeeingValidated = false;
                }
            }
        }, 400);
    }

    @action
    public cpfValidate = (cpfString: string) => {
        if (this.cpfBeeingValidated) {
            return;
        }

        this.cpf = cpfString;

        setTimeout(async () => {
            if (this.cpf === cpfString) {
                const userId = this.selectedUser ? this.selectedUser.id : null;

                if (this.cpfBeeingValidated) {
                    return;
                }

                this.cpfBeeingValidated = true;
                this.cpfValidationErrorMessage = null;

                try {
                    await api.cpfAvailableFor(
                        userId,
                        cpfString,
                    );
                } catch (e) {
                    let errorMessage: string;

                    if (e.message) {
                        errorMessage = e.message;
                    } else {
                        errorMessage = inspect(e);
                    }

                    this.cpfValidationErrorMessage = errorMessage;
                } finally {
                    this.cpfBeeingValidated = false;
                }
            }
        }, 400);
    }

    @action
    public tougleIngnoreCPFValidation = () => {
        this.ingnoreCPFValidation = !this.ingnoreCPFValidation;
    }

    @action
    public emailValidate = (emailString: string) => {
        if (this.emailBeeingValidated) {
            return;
        }

        this.email = emailString;

        setTimeout(async () => {
            // if (this.email === emailString) {
            //     this.emailBeeingValidated = true;

            //     try {
            //         await api.emailAvailableFor(
            //             this.selectedUser ? this.selectedUser.id : null,
            //             this.email,
            //         );
            //     } catch (e) {
            //         let errorMessage: string;

            //         if (e.message) {
            //             errorMessage = e.message;
            //         } else {
            //             errorMessage = inspect(e);
            //         }

            //         this.emailValidationErrorMessage = errorMessage;
            //     } finally {
            //         this.emailBeeingValidated = false;
            //     }
            // }
        }, 600);
    }

    @action
    public setUserFields = async (selectedUser: api.User) => {
        this.clear();

        this.imageUploaderService.setImage(selectedUser.avatar);
        this.name = selectedUser.name;
        this.birthday = selectedUser.birthday;
        this.phone = selectedUser.phone || "";

        this.email = selectedUser.email || "";
        this.emailValidated = true;
        this.emailBeeingValidated = false;
        this.emailValidationErrorMessage = null;

        this.cpf = selectedUser.cpf || "";
        this.ingnoreCPFValidation = false;
        this.cpfValidated = true;
        this.cpfBeeingValidated = false;
        this.cpfValidationErrorMessage = null;
        this.tryedGetUserFromCpf = true;

        this.rgInstitution = selectedUser.rg.institution;
        this.rgNumber = selectedUser.rg.number;
        this.schooling = selectedUser.schooling;
        this.studentId = selectedUser.studentId;
        this.addressService.setFields(selectedUser.address);
        this.institution = selectedUser.institution;
        this.course = selectedUser.course;
    }

    @action
    public clear = () => {
        this.addressService.clear();
        this.imageUploaderService.clear();
        this.autocompleteCourseService.clear();
        this.autocompleteInstitutionService.clear();

        this.name = "";
        this.birthday = null;
        this.phone = "";

        this.email = "";
        this.emailValidated = false;
        this.emailBeeingValidated = false;
        this.emailValidationErrorMessage = null;

        this.cpf = "";
        this.cpfValidated = false;
        this.cpfBeeingValidated = false;
        this.cpfValidationErrorMessage = null;
        this.tryedGetUserFromCpf = false;

        this.rgInstitution = "";
        this.rgNumber = "";
        this.schooling = api.Schooling.earlyChildhoodEducation;
        this.studentId = "";

        this.institution = null;
        this.course = null;
    }
}
