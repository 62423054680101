import * as React from "react";
import "./style.scss";

// Resources
import strings from "../../resources/strings";
import * as api from "../../admin-api-web";

// Stores
import { observer } from "mobx-react";

// Components
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

// Icons
import DeleteIcon from "@material-ui/icons/Close";

interface IProps {
	onCloseClick: () => void;
	numOfSelected: number;
}

@observer
export default class SelectionIndicator extends React.Component<IProps> {
	public render() {

		return (
			<div className="selectionIndicator">
				<IconButton style={{ margin: 0, padding: 6 }} onClick={this.props.onCloseClick}>
					<DeleteIcon fontSize="small" />
				</IconButton>
				<Typography variant="caption">
					{strings.users.table.buttons.selected(this.props.numOfSelected)}
				</Typography>
			</div>
		);
	}
}
