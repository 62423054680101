import * as React from "react";
import "./style.scss";

// Tools
import ImageUploader from "../../service/ImageUploaderService";

// Mobx
import { observer } from "mobx-react";

// Components
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

// Icons
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import CropIcon from "@material-ui/icons/Crop";

interface IProps {
	src: string;
	status: ImageUploader["status"];
	progress?: number;
	startCrop?: () => void;
	removeItem: () => void;
	reloadItem: () => void;
}

@observer
export default class ImageItem extends React.Component<IProps> {
	public render() {
		const { removeItem, startCrop, src, status, reloadItem, progress } = this.props;

		return (
			<div className="imageItem">
				<img src={src}/>
				{startCrop &&
					<div className="cropBtnContainer">
						<IconButton onClick={startCrop}>
							<CropIcon fontSize="small" />
						</IconButton>
					</div>
				}
				<div className="deleteBtnContainer">
					<IconButton onClick={removeItem}>
						<DeleteIcon fontSize="small"/>
					</IconButton>
				</div>
				{
					(() => {
						switch (status) {
							case "failed": {
								return (
									<div className="darkOverlay">
										<IconButton onClick={reloadItem}>
											<RefreshIcon
												style={{ height: 42, width: 42 }}
											/>
										</IconButton>
									</div>
								);
							}
							case "success": {
								return null;
							}
							case "uploading":
							default: {
								return(
									<div className="darkOverlay">
										<CircularProgress
											variant="static"
											value={progress}
										/>
									</div>
								);
							}
						}
					})()
				}
			</div>
		);
	}
}
