import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";

// Components
import { IFilterField } from "../../../components/Filter";

// Resources
import strings from "../../../resources/strings";

export default class AdminUserFilterStore extends VariableChangeHandler {
    // Variables
    @observable public name: string | null = null;
    @observable public type: api.AdminUserType | null = null;
    @observable public email: string | null = null;

    // Methods
    @computed
    public get filter(): api.AdminUserFilter {
        return {
            name: this.name,
            email: this.email,
            type: this.type,
        };
    }

    @computed
    public get fields(): IFilterField[] {
        return [
            {
                id: "name",
                value: strings.adminUsers.filter.name,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.name,
                clear: () => {
                    this.name = null;
                },
            },
            {
                id: "type",
                value: strings.adminUsers.filter.type,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesStateUF(),
                    valueFromOption: api.valueFromTranslationStateUF,
                },
                currentValue: this.type ? api.translateAdminUserType(this.type) : null,
                clear: () => {
                    this.type = null;
                },
            },
            {
                id: "email",
                value: strings.adminUsers.filter.email,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.email,
                clear: () => {
                    this.email = null;
                },
            },
        ];
    }

    @action
    public clear = () => {
        this.name = null;
    }

}
