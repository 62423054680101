import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";

// Services
import AutocompleteInstitutionService from "../../../service/AutocompleteInstitutionService";
import AutocompleteCourseService from "../../../service/AutocompleteCourseService";
import AutocompleteAdminUserService from "../../../service/AutocompleteAdminUserService";

// Components
import { IFilterField } from "../../../components/Filter";

// Resources
import strings from "../../../resources/strings";

export default class UserFilterStore extends VariableChangeHandler {
    // Services
    public autocompleteInstitutionService: AutocompleteInstitutionService = new AutocompleteInstitutionService();
    public autocompleteCourseService: AutocompleteCourseService = new AutocompleteCourseService();
    public autocompleteAdminUserService: AutocompleteAdminUserService = new AutocompleteAdminUserService();

    // Variables
    @observable public name: string | null = null;
    @observable public email: string | null = null;
    @observable public cpf: string | null = null;
    @observable public rg: string | null = null;
    @observable public uf: api.StateUF | null = null;
    @observable public schooling: api.Schooling | null = null;
    @observable public status: api.PrintStatus | null = null;
    @observable public studentId: string | null = null;
    @observable public editedAt: Date | null = null;
    @observable public isReprint: boolean | null = null;

    @observable public institution: api.Institution | null = null;
    @observable public course: api.Course | null = null;
    @observable public seller: api.AdminUser | null = null;
    @observable public type: api.CardPrintType | null = null;

    @action private formatDate = (date: string) => {
        const dataObj = new Date(date);
        const getDay = dataObj.getDate();
        const getMonth = dataObj.getMonth() + 1;
        const getYear = dataObj.getFullYear();
        const formatedDate = (getDay < 10 ? "0" : "") + getDay + "/" + (getMonth < 10 ? "0" : "") + getMonth + "/" + getYear;
        return formatedDate;
}
    // Methods
    @computed
    public get filter(): api.CardPrintFilter {
        return {
            name: this.name,
            cpf: this.cpf,
            rg: this.rg,
            schooling: this.schooling,
            institutionId: this.institution ? this.institution.id : null,
            courseId: this.course ? this.course.id : null,
            studentId: this.studentId,
            sellerId: this.seller ? this.seller.id : null,
            status: this.status,
            type: this.type,
            isReprint: this.isReprint,
            };
    }

    @computed
    public get fields(): IFilterField[] {
        return [
            {
                id: "name",
                value: strings.users.filter.name,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.name,
                clear: () => {
                    this.name = null;
                },
            },
            {
                id: "status",
                value: strings.users.filter.status,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesPrintStatus(),
                    valueFromOption: api.valueFromTranslationPrintStatusPending,
                },
                currentValue: this.status ? api.translatePrintStatus(this.status) : null,
                clear: () => {
                    this.status = null;
                },
            },
            {
                id: "sellerId",
                value: strings.users.filter.sellerId,
                type: "autosuggest",
                currentValue: this.seller ? this.seller.name : null,
                autosuggest: {
                    options: this.autocompleteAdminUserService.autocompleteAdminUsers,
                    loading: this.autocompleteAdminUserService.loading,
                    onChange: this.selectSeller,
                    getOptions: this.autocompleteAdminUserService.getAdminUsers,
                    clearOptions: this.autocompleteAdminUserService.clear,
                },
                clear: () => {
                    this.seller = null;
                },
            },
            {
                id: "isReprint",
                value: strings.cardPrint.filter.isReprint,
                type: "checkbox",
                checkbox: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.isReprint,
                clear: () => {
                    this.isReprint = null;
                },
            },
        ];
    }

    @action
    public selectInstitution = async (institutionId: string | null) => {
        await this.autocompleteInstitutionService.selectInstitution(institutionId);
        this.institution = this.autocompleteInstitutionService.institution;
    }

    @action
    public selectCourse = async (courseId: string | null) => {
        await this.autocompleteCourseService.selectCourse(courseId);
        this.course = this.autocompleteCourseService.course;
    }

    @action
    public selectSeller = async (sellerId: string | null) => {
        await this.autocompleteAdminUserService.selectAdminUser(sellerId);
        this.seller = this.autocompleteAdminUserService.adminUser;
    }

    @action
    public clear = () => {
        this.name = null;
        this.email = null;
        this.cpf = null;
        this.rg = null;
        this.uf = null;
        this.schooling = null;
        this.studentId = null;

        this.institution = null;
        this.course = null;
        this.seller = null;
        this.status = null;
    }
}
