import * as React from "react";
import "./style.scss";

interface IProps {
    src: string | null;
}

export default class TableRowRoundImage extends React.Component<IProps> {
    public render() {
        return (
            <div className="tableRowRoundContainer">
                <img
                    className="roundImage"
                    src={this.props.src || "/userPlaceholder.svg"}
                />
            </div>
        );
    }
}
