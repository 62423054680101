// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Resources
import * as CardInfoFormatter from "../../resources/CardInfoFormatter";
import strings from "../../resources/strings";

interface IProps {
	cardInfo: CardInfoFormatter.ICardInfoString;
}

export default class CardForPrint extends React.Component<IProps> {
	public render() {
		const { cardInfo } = this.props;
		const fields = strings.cardPrint.print.fields;

		return (
			<div className="cardPrint">
				<div className="cardPrintInfo">
					<img src={cardInfo.avatar} alt="document-pic" />
					<div className="cardPrintData">
						<div className="verticalWrapper" style={{ margin: "10px" }}>
							<p style={{ fontSize: "10px", margin: "0", color: "#343436" }} className="title">
								{cardInfo.name}
							</p>
							<p style={{ fontSize: "10px", margin: "0", color: "#343436" }}>
								{cardInfo.institution}
							</p>
							<p style={{ fontSize: "10px", margin: "0", color: "#343436" }}>
								{cardInfo.schooling}
							</p>
							<p style={{ fontSize: "10px", margin: "0", color: "#343436" }}>
								{cardInfo.course}
							</p>
						</div>
						<div className="horizontalWrapper">
							<p className="TitleCard">
								{fields.cpf}
							</p>
							<p className="StudentInfo">
								{cardInfo.cpf}
							</p>
						</div>
						<div className="horizontalWrapper">
							<p className="TitleCard">
								{fields.rg}
							</p>
							<p className="StudentInfo">
								{cardInfo.rg}
							</p>
						</div>
						<div className="horizontalWrapper">
							<p className="TitleCard">
								{fields.birthDate}
							</p>
							<p className="StudentInfo" >
								{cardInfo.birthday}
							</p>
						</div>
						<div className="horizontalWrapper">
							<p className="TitleCard">
								{fields.registration}
							</p>
							<p className="StudentInfo">
								{cardInfo.studentId}
							</p>
						</div>
					</div>
				</div>
				<div className="qrCodeRow">
					<img src="/qrcode.png" alt="qrcode" />
					<div className="horizontalWrapper">
						<p className="TitleCard">
							{fields.code}
						</p>
						<p className="StudentInfo">
							{cardInfo.useCode}
						</p>
					</div>
				</div>
			</div>
		);
	}
}
