import { RouterStore } from "mobx-react-router";
import AuthStore from "./AuthStore";
import UIStore from "./UIStore";
import UserStore from "./UserStore";
import CourseStore from "./CourseStore";
import TeamStore from "./TeamStore";
import InstitutionStore from "./InstitutionStore";
import UserActionStore from "./UserActionStore";
import CardPrintStore from "./CardPrintStore";
import PaymentStore from "./PaymentStore";
import HomeStore from "./HomeStore";

export const routerStore =  new RouterStore();
export const authStore = new AuthStore();
export const uiStore = new UIStore();
export const userStore = new UserStore();
export const courseStore = new CourseStore();
export const teamStore = new TeamStore();
export const institutionStore = new InstitutionStore();
export const userActionStore = new UserActionStore();
export const cardPrintStore = new CardPrintStore();
export const paymentStore = new PaymentStore();
export const homeStore = new HomeStore();

export const rootStore = {
	uiStore,
	authStore,
	teamStore,
	userStore,
	courseStore,
	routerStore,
	paymentStore,
	cardPrintStore,
	userActionStore,
	institutionStore,
	homeStore,
};
