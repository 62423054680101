import * as React from "react";
import "./style.scss";

import { observer, inject } from "mobx-react";

// Stores
import { routerStore } from "../../../..//stores/_rootStore";
import CourseStore from "../../../../stores/CourseStore";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";

// Resources
import strings from "../../../../resources/strings";
import * as api from "../../../../admin-api-web";
import { match as Match } from "react-router";

interface IProps {
    courseStore: CourseStore;
    match: Match<{ courseId: string }>;
}

@inject("courseStore")
@observer
export default class CreateOrEditCourse extends React.Component<IProps> {
    public componentDidMount = async () => {
        const { match, courseStore } = this.props;
        const { courseId } = match.params;

        if (courseId) {
            if (!courseStore.selectedCourse || courseStore.selectedCourse.id !== courseId) {
                await courseStore.getCourse(courseId);
            }

            if (!courseStore.selectedCourse) {
                routerStore.push("/");
            } else {
                courseStore.setEditMode(true);
            }
        }
    }

    public render() {
        const { courseStore } = this.props;

        return (
            <Paper>
                <div className="createOrEditCourseFormContainer">
                    <Typography variant="h6">{strings.courses.createOrEdit.title}</Typography>
                    <div className="createOrEditCourseForm">
                        <div className="inputContainer">
                            <Typography variant="subtitle1">{strings.courses.createOrEdit.info.title}</Typography>
                            <TextField
                                label={strings.courses.createOrEdit.info.name}
                                name="name"
                                type="name"
                                value={courseStore.name}
                                disabled={courseStore.loading}
                                onChange={courseStore.handleValueChange}
                                charactersLimit={35}
                                required={true}
                                className="fieldCharacter"
                            />
                            <p className="text-muted"><small>{34 - courseStore.name.length}</small> caracteres restantes</p>
                            <FormControlLabel
                                label={strings.courses.createOrEdit.isActive}
                                disabled={courseStore.loading}
                                control={
                                    <Checkbox
                                        checked={courseStore.isActive}
                                        onChange={() => {
                                            courseStore.handleValueChange({
                                                target: {
                                                    name: "isActive",
                                                    value: !courseStore.isActive,
                                                },
                                            });
                                        }}
                                    />
                                }
                            />
                            <Button
                                onClick={courseStore.onEditMode ? courseStore.editCourse : courseStore.createCourse}
                            >
                                {strings.courses.createOrEdit.button}
                            </Button>

                            <LinkButton style={{marginLeft: 20}} onClick={() => routerStore.replace("/dashboard/courses")}>
                                {strings.users.createOrEdit.buttons.back}
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}
