import * as React from "react";
import "./style.scss";

interface IProps {
    text?: string | null;
    tagColor?: "yellow" | "blue" | "green" | "red" | "gray";
}

export default class TableRowCellContainer extends React.Component<IProps> {

    private calculateCSSColor = () => {
        switch (this.props.tagColor) {
            case "yellow": return "tableRowCellContainerYellow";
            case "green": return "tableRowCellContainerGreen";
            case "blue": return "tableRowCellContainerBlue";
            case "red": return "tableRowCellContainerRed";
            case "gray": return "tableRowCellContainerGray";
            default: return "tableRowCellContainerGray";
        }
    }

    public render() {
        return (
            <div className={`tableRowCellContainer ${this.calculateCSSColor()}`}>
                {this.props.text}
            </div>
        );
    }
}
