import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import TeamStore from "../../../../stores/TeamStore";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Autosuggest from "../../../../components/Autosuggest";
import TextField from "../../../../components/TextField";
import EnumPicker from "../../../../components/EnumPicker";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";
import Loading from "../../../../components/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Resources
import strings from "../../../../resources/strings";
import * as api from "../../../../admin-api-web";
import { match as Match } from "react-router";

interface IProps {
    match: Match<{ id: string }>;
    teamStore: TeamStore;
}

@inject("teamStore")
@observer
export default class CreateOrEditUser extends React.Component<IProps> {
    public componentDidMount = async () => {
        await this.props.teamStore.initializeEditor(this.props.match.params.id);
    }

    public render() {
        const { teamStore } = this.props;

        return (
            <Paper>
                <div className="teamMemberEditor">
                    {teamStore.loading ?
                        <Loading /> :
                        <>
                            <Typography variant="h6">{strings.adminUsers.editor.title(teamStore.onEditMode)}</Typography>
                            <TextField
                                type="name"
                                name="name"
                                label={strings.adminUsers.editor.name}
                                value={teamStore.name}
                                disabled={teamStore.loading}
                                onChange={teamStore.handleValueChange}
                            />
                            <TextField
                                type="email"
                                name="email"
                                label={strings.adminUsers.editor.email}
                                value={teamStore.email}
                                disabled={teamStore.loading}
                                onChange={teamStore.handleValueChange}
                            />
                            <TextField
                                type="phone"
                                name="phone"
                                label={strings.adminUsers.editor.phone}
                                value={teamStore.phone}
                                disabled={teamStore.loading}
                                onChange={teamStore.handleValueChange}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={teamStore.isSuperAdmin}
                                        onChange={teamStore.toggleSuperAdmin}
                                    />
                                }
                                disabled={teamStore.loading}
                                label={strings.adminUsers.editor.superAdmin}
                            />
                            {
                                !teamStore.isSuperAdmin &&
                                <EnumPicker
                                    label={strings.adminUsers.editor.role}
                                    name="type"
                                    value={teamStore.type}
                                    disabled={teamStore.loading}
                                    onChange={teamStore.handleValueChange}
                                    options={api.allDisplayableValuesAdminUserType()}
                                    valueFromOption={api.valueFromTranslationAdminUserType}
                                />

                            }

                            {!teamStore.isSuperAdmin && (teamStore.type === api.AdminUserType.coordinator || teamStore.type === api.AdminUserType.seller) &&
                                <Autosuggest
                                    onChange={teamStore.selectSuperiorAdminUser}
                                    label={strings.adminUsers.editor.superior(teamStore.type)}
                                    disabled={teamStore.autocompleteAdminUserService.loading}
                                    items={teamStore.autocompleteAdminUserService.autocompleteAdminUsers}
                                    value={teamStore.superiorAdminUser ? { id: teamStore.superiorAdminUser.id, value: teamStore.superiorAdminUser.name } : undefined}
                                    getOptions={(inputValue) =>
                                        teamStore.autocompleteAdminUserService.getAdminUsers(
                                            inputValue,
                                            teamStore.type === api.AdminUserType.coordinator ?
                                                api.AdminUserType.manager :
                                                api.AdminUserType.coordinator,
                                        )
                                    }
                                    clearOptions={teamStore.autocompleteAdminUserService.clear}
                                />
                            }

                            <div className="buttonsContainer">
                                <Button
                                    onClick={teamStore.saveUser}
                                >
                                    {strings.users.createOrEdit.buttons.save}
                                </Button>

                                <LinkButton
                                    onClick={teamStore.closeEditor}
                                >
                                    {strings.users.createOrEdit.buttons.back}
                                </LinkButton>
                            </div>
                        </>
                    }
                </div>
            </Paper>
        );
    }
}
