// Models
import * as api from "../../admin-api-web";

// Mobx
import { action, computed } from "mobx";
import { uiStore, userStore } from "../_rootStore";

// Resources
import strings from "../../resources/strings";
import moment from "moment";
import * as  statusFormater from "../../resources/statusFormater";

// Types
import TableDataStore from "../TableDataStore";

export default class PaymentStore extends TableDataStore<api.Payment> {
    protected formatDataToRow(rowItem: api.Payment) {
        return {
            id: rowItem.id.toString(),
            data: [
                { value: `${rowItem.cardPrintId}` },
                { value: moment(rowItem.createdAt).format("DD/MM/YYYY") },
                { value: rowItem.amount.toString() },
                // statusFormater.paymentStatusToRow(rowItem.status),
            ],
        };
    }

    protected formatTableHeader() {
        const { header } = strings.payments.table;
        return [header.printId, header.createdAt, header.value, header.status];
    }

    @action
    protected getDataItemsPerPage(pageOffset: number): Promise<api.Payment[]> {
        return new Promise<api.Payment[]>((resolve, reject) => {
            api.getPayments(pageOffset, 20).then((paymentResults) => resolve((paymentResults).results)).catch(reject);
        });
    }

    @computed
    public get selectedUsersForPrint() {
        return userStore.userSelection;
    }

    protected getSingleItemById(id: string): Promise<api.Payment> {
        throw new Error("Method not implemented.");
    }

    // @action
    // public togglePaymentStatus = async (payment: api.Payment) => {
    //     const status = payment.status === api.PaymentStatus.paid ? api.PaymentStatus.canceled : api.PaymentStatus.paid;

    //     try {
    //         this.loading = true;
    //         await api.setPaymentStatus(payment.id, status);
    //         await this.fetchInitialData();
    //     } catch (err) {
    //         uiStore.openErrorSnackbar(err);
    //     } finally {
    //         this.loading = false;
    //     }
    // }
}
