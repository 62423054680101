import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { uiStore } from "../../stores/_rootStore";

// Components
import { IItem } from "../../components/Autosuggest";

export default class AdminUserAutocompleteService extends VariableChangeHandler {
    @observable public loading: boolean = false;

    @observable public adminUsers: api.AdminUser[] = [];
    @observable public adminUser: api.AdminUser | null = null;

    @computed
    public get autocompleteAdminUsers(): IItem[] {
        return this.adminUsers.map((adminUser) => ({
            id: adminUser.id,
            value: adminUser.name,
        }));
    }

    @action
    public selectAdminUser = async (adminUserId: string | null) => {
        if (this.loading) {
            return;
        }

        this.loading = true;

        if (!adminUserId) {
            this.adminUser = null;
            this.loading = false;
            return;
        }

        try {
            this.adminUser = this.adminUsers.find((adminUser) => adminUser.id === adminUserId) || await api.getAdminUser(adminUserId);
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public getAdminUsers = async (name: string | null, type?: api.AdminUserType | null) => {
        await this.clear();

        this.adminUsers = (await api.getAdminUsers({
            type: type || null,
            name,
            email: null,
        }, 0, 20)).results;
    }

    @action
    public clear = async () => {
        this.adminUsers = [];
    }
}
