import * as React from "react";
import "./style.scss";

import * as api from "../../../../admin-api-web";

import { observer, inject } from "mobx-react";

// Stores
import InstitutionStore from "../../../../stores/InstitutionStore";
import { routerStore } from "../../../..//stores/_rootStore";

// Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TextField from "../../../../components/TextField";
import EnumPicker from "../../../../components/EnumPicker";
import Button from "../../../../components/Button";
import LinkButton from "../../../../components/LinkButton";

// Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

interface IProps {
    institutionStore: InstitutionStore;
    match: Match<{ institutionId: string }>;
}

@inject("institutionStore")
@observer
export default class CreateOrEditInstitution extends React.Component<IProps> {
    public componentDidMount = async () => {
        const { match, institutionStore } = this.props;
        const { institutionId } = match.params;

        if (institutionId) {
            await institutionStore.selectInstitution(institutionId);
        }
    }

    public render() {
        const { institutionStore } = this.props;

        return (
            <Paper>
                <div className="createOrEditInstitutionFormContainer">
                    <Typography variant="h6">{strings.institutions.createOrEdit.title}</Typography>
                    <div className="createOrEditInstitutionForm">
                        <div className="inputContainer">
                            <Typography variant="subtitle1">{strings.institutions.createOrEdit.info.title}</Typography>
                            <TextField
                                name="name"
                                type="name"
                                label={strings.institutions.createOrEdit.info.name}
                                value={institutionStore.institutionCreateOrEditStore.name}
                                disabled={institutionStore.institutionCreateOrEditStore.loading}
                                onChange={institutionStore.institutionCreateOrEditStore.handleValueChange}
                                charactersLimit={35}
                            />
                            <p className="smallCounter"><small>{34 - institutionStore.institutionCreateOrEditStore.name.length}</small> caracteres restantes</p>
                            <EnumPicker
                                name="stateUF"
                                label={strings.institutions.createOrEdit.info.stateUF}
                                value={institutionStore.institutionCreateOrEditStore.stateUF}
                                disabled={institutionStore.institutionCreateOrEditStore.loading}
                                onChange={institutionStore.institutionCreateOrEditStore.handleValueChange}
                                options={api.allDisplayableValuesStateUF()}
                                valueFromOption={api.valueFromTranslationStateUF}
                            />
                            <TextField
                                name="city"
                                label={strings.institutions.createOrEdit.info.city}
                                value={institutionStore.institutionCreateOrEditStore.city}
                                disabled={institutionStore.institutionCreateOrEditStore.loading}
                                onChange={institutionStore.institutionCreateOrEditStore.handleValueChange}
                            />

                            <FormControlLabel
                                label={strings.courses.createOrEdit.isActive}
                                disabled={institutionStore.institutionCreateOrEditStore.loading}
                                control={
                                    <Checkbox
                                        checked={institutionStore.institutionCreateOrEditStore.isActive}
                                        onChange={() => {
                                            institutionStore.institutionCreateOrEditStore.handleValueChange({
                                                target: {
                                                    name: "isActive",
                                                    value: !institutionStore.institutionCreateOrEditStore.isActive,
                                                },
                                            });
                                        }}
                                    />
                                }
                            />
                            <Button onClick={institutionStore.institutionCreateOrEditStore.createOrEditInstitution}>
                                {strings.institutions.createOrEdit.button}
                            </Button>
                            <LinkButton
                                style={{marginLeft: 20}}
                                onClick={() => routerStore.replace("/dashboard/institutions")}
                            >
                                {strings.users.createOrEdit.buttons.back}
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}
