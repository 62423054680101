import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import CourseStore from "../../../../stores/CourseStore";

// Components
import Table from "../../../../components/Table";
import { Toolbar } from "@material-ui/core";
import Filter from "../../../../components/Filter";

// Resources
import strings from "../../../../resources/strings";

interface IProps {
	courseStore: CourseStore;
}

@inject("courseStore")
@observer
export default class CoursesContainer extends React.Component<IProps> {
	public async componentDidMount() {
		await this.props.courseStore.clear();
		await this.props.courseStore.getCourses();
	}

	public render() {
		const { courseStore } = this.props;

		return (
			<div className="coursesContainer">
				<Table
					headerView={
						<Toolbar>
							<Filter
								disabled={courseStore.loading}
								fields={courseStore.courseFilterStore.fields}
							/>
						</Toolbar>
					}
					title={strings.courses.table.title}
					loading={courseStore.loading}
					prevPage={courseStore.previousPage}
					nextPage={courseStore.nextPage}
					header={courseStore.tableHeader}
					data={courseStore.tableRows}
					pageOffset={courseStore.pageOffset}
					onAddClick={courseStore.goToNewCourse}
					onEditClick={courseStore.goToEditCourse}
					rowsPerPageConfigs={{
						rowsPerPage: courseStore.rowsPerPage,
						onRowsPerPageChange: courseStore.handleSelectElementChange,
						refetchData: courseStore.fetchData,
						info: courseStore.tableRowRange,
					}}
				/>
			</div>
		);
	}
}
