// MARK: API
import * as api from "../../admin-api-web";

// MARK: Mobx
import { observable, action, computed } from "mobx";

// MARK: Libraries
import moment from "moment";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Stores
import { uiStore, teamStore } from "../_rootStore";

// Models
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { IRowItem } from "../../components/Table/TableRow";
import { downloadFile } from "../../resources/FileManager";

export default class UserActionStore extends VariableChangeHandler {
    @observable public reportLoading: boolean = false;

    @observable public loading: boolean = false;
    @observable public loadingCSV: boolean = false;
    @observable public loadedCSV: boolean = false;
    @observable public pageOffset: number = 0;
    @observable public logs: api.Log[] = [];
    @observable public selectedUser: api.AdminUser | null | undefined = null;
    @observable public salesLoading: boolean = false;
    @observable public csvHeader: any = [
        { key: "name", label: strings.userLogs.table.header.name },
        { key: "action", label: strings.userLogs.table.header.action },
        { key: "day", label: strings.userLogs.table.header.date },
        { key: "hour", label: strings.userLogs.table.header.time },
    ];
    @observable public csvData: any = [];

    @action public getCSVData = async (userId?: string) => {
        if (this.loading) return;
        this.loading = true;
        this.csvData = [];
        try {
            const logsData = (userId) ? await api.getCompleteUserLogsById(userId) : await api.getCompleteAllUserLogs();
            this.csvData = logsData.map((log) => ({
                name: log.adminUser.name,
                action: log.text,
                day: moment(log.createdAt).format("DD/MM/YYYY"),
                hour: moment(log.createdAt).format("HH:mm"),
            }));
        } catch (e) {
            console.error("Error in getCVSData: ", e);
        } finally {
            this.loading = false;
            this.loadedCSV = true;
            uiStore.openSnackbar(strings.userLogs.csv.snackBar);
        }
    }

    @computed
    public get tableHeader(): string[] {
        const { header } = strings.userLogs.table;
        return [ header.name, header.action, header.date, header.time ];
    }

    @computed
    public get tableRows(): IRowItem[] {
        return this.logs.map((log) => ({
            id: log.id,
            data: [
                { value: log.adminUser.name },
                { value: log.text },
                { value: moment(log.createdAt).format("DD/MM/YYYY") },
                { value: moment(log.createdAt).format("HH:mm") },
            ],
        }));
    }

    @action
    public getInitialData = async (userId?: string) => {
       this.loading = true;
        try {
            if (userId) {
                this.selectedUser = await teamStore.selectUser(userId);
            } else {
                this.selectedUser = null;
            }
            this.logs = [];
            await this.getData(0);
            if (!this.logs) {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch {
            uiStore.openSnackbar(strings.lists.noMoreResults);
        } finally {
            this.loading = false;
        }
    }

    @action
    public getData = async (pageOffset: number) => {
        if (pageOffset < 0) return;
        this.loading = true;
        try {
            let newLogs: api.LogResults;
            if (this.selectedUser) {
                newLogs = await api.getUserLogsById(this.selectedUser.id, pageOffset, this.rowsPerPage);
                this.totalOfRows = newLogs.total;
            } else {
                newLogs = await api.getAllUserLogs(pageOffset, this.rowsPerPage);
                this.totalOfRows = newLogs.total;
            }
            if (newLogs.results.length > 0) {
                this.logs = newLogs.results;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            uiStore.openErrorSnackbar(e);
        } finally {
            this.loading = false;
        }
    }

    @action
    public fetchData = async () => {
        await this.getData(this.pageOffset);
    }

    @observable public rowsPerPage: number = 10;
    @observable public totalOfRows: number = 25;

    @computed
    public get tableRowRange() {

        const page = this.pageOffset + 1;
        let end = this.rowsPerPage * page;
        const start = end - this.rowsPerPage;

        if (end > this.totalOfRows) {
            end =  this.totalOfRows;
        }

        return `${start} - ${end} de ${this.totalOfRows}`;
    }

    @action
    public nextPage = async () => await this.getData(this.pageOffset + 1);

    @action
    public previousPage = async () => await this.getData(this.pageOffset - 1);

    @action
	public getLogsReport = async () => {
		if (this.reportLoading) {
			return;
		}
		this.reportLoading = true;
		try {
			const url =  await api.getUsersReportsWithStream();
			downloadFile(url);
		} catch (e) {
			uiStore.showAlert(e);
		} finally {
			this.reportLoading = false;
		}
	};
}
