// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Components
import Toolbar from "@material-ui/core/Toolbar";
import Table from "../../../../components/Table";
import Filter from "../../../../components/Filter";
import ConfirmPrint from "../../../../components/ConfirmPrint";
import EditUserTable from "./EditUserTable";
import UserTableToolbar from "./UserTableToolbar";
import ActiveSelectionUserTableToobar from "./ActiveSelectionUserTableToolbar";

// MARK: Stores
import { authStore } from "../../../../stores/_rootStore";
import UserStore from "../../../../stores/UserStore";
import AuthStore from "../../../../stores/AuthStore";
import CardPrintStore from "../../../../stores/CardPrintStore";

interface IStores {
	userStore: UserStore;
	cardPrintStore: CardPrintStore;
	authStore: AuthStore;
}

interface IProps extends IStores {

}

interface IState {
	isConfirmingPrint: boolean;
}

@inject("userStore", "cardPrintStore", "authStore")
@observer
export default class UsersTable extends React.Component<IProps, IState> {
	public componentDidMount = async () => {
		await this.props.userStore.clear();
		await this.props.userStore.getUsers();
	}

	public componentWillMount = () => {
		this.setState({
			isConfirmingPrint: false,
		});
	}

	constructor(props: any) {
        super(props);
        this.props.userStore.userSelection = [];
    }

	public render() {
		const { userStore } = this.props;
		return (
			!this.state.isConfirmingPrint ?
				<Table
					headerView={
						<Toolbar>
							<div className="usersTableHeaderViewContainer">
								{userStore.editingTable &&
									<EditUserTable
										selectedColumns={userStore.userTableConfig}
										saveSelectedColumns={userStore.saveSelectedColumns}
									/>
								}
								<Filter
									disabled={userStore.loading}
									fields={userStore.userFilterStore.fields}
								/>
							</div>
						</Toolbar>
					}
					toolbar={
						<UserTableToolbar
							onImportExcel={userStore.importUsersFromExcel}
							onEditTable={() => this.props.userStore.setEditingTable(true)}
							onSelectAll={userStore.selectAllPageUsers}
							onExport={userStore.downloadCSV}
							userStore={userStore}
						/>
					}
					activeSelectionToolbar={
						<ActiveSelectionUserTableToobar
							authStore={authStore}
							numberOfSelected={userStore.selectionLength}
							onDeSelectAll={userStore.emptyUserSelection}
							onRequestClick={() => {
								this.setState({
									isConfirmingPrint: true,
								});
							}}
						/>
					}
					data={userStore.tableRows}
					header={userStore.tableRows.length > 0 ? userStore.tableRows[0].data.map((column) => column.header) : []}
					loading={userStore.loading}
					nextPage={userStore.nextPage}
					pageOffset={userStore.pageOffset}
					title={strings.users.table.title}
					prevPage={userStore.previousPage}
					onAddClick={userStore.startCreateOrEditUser}
					onEditClick={userStore.startCreateOrEditUser}
					onRowSelect={(id) =>  {
						userStore.toggleUserSelection(id);
						userStore.userCardPrintInfo = [];
						userStore.usersSelectionRequest();
					}}
					rowsPerPageConfigs={{
						rowsPerPage: userStore.rowsPerPage,
						onRowsPerPageChange: userStore.handleSelectElementChange,
						refetchData: userStore.getUsers,
						info: userStore.tableRowRange,
					}}
				/> :
				<ConfirmPrint
					confirmDialog={strings.cardPrint.confirmPrint.confirmRequest.dialog}
					title={strings.cardPrint.confirmPrint.confirmRequest.title}
					confirmButtonText={strings.cardPrint.confirmPrint.confirmRequest.button}
					cardsInfo={userStore.userCardPrintInfo}
					loading={userStore.loading}
					onConfirm={async () => {
						await userStore.requestCardPrintForSelection();
						this.setState({
							isConfirmingPrint: false,
						});
					}}
					onCancel={() => {
						this.setState({
							isConfirmingPrint: false,
						});
					}}
				/>
		);
	}
}
