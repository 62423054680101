// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: API
import * as api from "../../../../../admin-api-web";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Stores
import { observer, inject } from "mobx-react";
import UserStore from "../../../../../stores/UserStore";

// MARK: Components
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { CSVLink } from "react-csv";

// MARK: Icons
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/VerticalAlignBottom";

interface IProps {
	onImportExcel: () => void;
	onEditTable: () => void;
	onExport: () => void;
	onSelectAll: () => void;
	userStore: UserStore;
}

@inject("userStore")
@observer
export default class UserTableToolbar extends React.Component<IProps> {
	public render() {
		const { userStore } = this.props;
		return (
			<div style={{ width: "100%" }}>
				<Tooltip title={strings.users.table.editTable.tooltip}>
					<Button
						onClick={() => this.props.onEditTable()}
						variant="contained"
						size="small"
						style={{ marginRight: "8px" }}
					>
						<Typography variant="button">{strings.users.table.editTable.title}</Typography>
					</Button>
				</Tooltip>
				<Tooltip title={strings.users.table.selectAll.tooltip}>
					<Button
						onClick={this.props.onSelectAll}
						variant="contained"
						size="small"
						style={{ marginRight: "8px" }}
					>
						<Typography variant="button">{strings.users.table.selectAll.button}</Typography>
					</Button>
				</Tooltip>
				{!userStore.loadedCSV ?
					<Tooltip
						style={{ marginRight: "8px" }}
						title={strings.userLogs.csv.generateTip}
					>
						<Button variant="contained" size="small" onClick={() => this.props.userStore.getUsersReport()} disabled={this.props.userStore.reportLoading}>
							<Typography variant="button">{this.props.userStore.reportLoading ? strings.userLogs.csv.await : strings.userLogs.csv.download}</Typography>
						</Button>
					</Tooltip> :
					<CSVLink
						style={{ marginRight: "8px" }}
						filename={"users.csv"}
						headers={userStore.csvHeader}
						data={userStore.csvData}
						onClick={() => {
							userStore.loadedCSV = false;
						}}
					>
						<Tooltip title={strings.userLogs.csv.downloadTip}>
							<Button variant="contained" color="primary" size="small">
								<Typography variant="button">{strings.userLogs.csv.download}</Typography>
								<DownloadIcon style={{ marginLeft: "8px" }} />
							</Button>
						</Tooltip>
					</CSVLink>
				}
				<Tooltip title={strings.users.table.import.tooltip}>
					<Button onClick={this.props.onImportExcel} variant="contained" size="small">
						<Typography variant="button">{strings.users.table.import.title}</Typography>
						<CloudUploadIcon style={{ marginLeft: "8px" }} />
					</Button>
				</Tooltip>
			</div >
		);
	}
}
