import * as React from "react";
import "./style.scss";

// Mobx
import { observer } from "mobx-react";

// Material-UI
import MaterialTableCell from "@material-ui/core/TableCell";
import MaterialTableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DetailsIcon from "@material-ui/icons/Search";
import DesaprovalIcon from "@material-ui/icons/Block";
import Checkbox from "@material-ui/core/Checkbox";

// Components
import TableRowRoundImage from "./TableRowRoundImage";
import TableRowCellContainer from "./TableRowCellContainer";

export enum ColumnType {
	image,
	text,
}

export interface IRowItem {
	selected?: boolean;
	id: string;
	data: Array<{
		type?: ColumnType;
		tagColor?: "yellow" | "red" | "blue" | "green";
		value: string | null
	}>;
}

interface IProps {
	/**
	 * 	 if onRowSelectedExists, its selectable
	 *   is selectable
	 * 	 the row must include a checkbox.
	 */
	rowItem: IRowItem;
	onRowSelected?: (id: string) => void;

	onEditClick?: (id: string) => void;
	onDeleteClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDesaprovalClick?: (id: string) => void;
	onClick?: (id: string) => void;
}

@observer
export default class TableRow extends React.Component<IProps> {
	/**
	 * Each item of the array is a column value in the in the Table row
	 * with you choose to add a edit/delete button make sure adding -1 item on the
	 * data array, to the row matches with the header size.
	 */

	public render() {
		const { onRowSelected, rowItem, onEditClick, onDeleteClick, onDetailsClick, onDesaprovalClick, onClick } = this.props;

		return (
			<MaterialTableRow hover tabIndex={-1} onClick={() => onClick && onClick(rowItem.id)}>
			{
				onRowSelected &&
				<MaterialTableCell
					align="center"
					padding="dense"
				>
					<Checkbox
						onClick={() => onRowSelected(rowItem.id)}
						checked={rowItem.selected}
					/>
				</MaterialTableCell>
			}
			{
				/**
				 * The additional Cell will only be placed if
				 * there is one of the functions: "onEditClick"
				 * or "onDeleteClick" or "onDetailsClick".
				 */
				(onEditClick || onDeleteClick || onDetailsClick) &&
				<MaterialTableCell
					key={`${rowItem.id}-edit-or-delete`}
					align="center"
					padding="dense"
				>
					{
						onEditClick &&
						<IconButton onClick={() => onEditClick(rowItem.id)}>
							<EditIcon />
						</IconButton>
					}
					{
						onDeleteClick &&
						<IconButton
							onClick={() => onDeleteClick(rowItem.id)}>
							<DeleteIcon />
						</IconButton>
					}
					{
						onDetailsClick &&
						<IconButton
							onClick={() => onDetailsClick(rowItem.id)}>
							<DetailsIcon />
						</IconButton>
					}
					{
						onDesaprovalClick &&
						<IconButton
							onClick={() => onDesaprovalClick(rowItem.id)}>
							<DesaprovalIcon />
						</IconButton>
					}
				</MaterialTableCell>
			}
			{
				rowItem.data.map((cell, index) => (
					<MaterialTableCell
							className="tableCell"
							key={index + rowItem.id + index}
							align="center"
							padding="dense"
						>
						{
							cell.type === ColumnType.image ?
							<TableRowRoundImage src={cell.value}/> :
							<TableRowCellContainer
								tagColor={cell.tagColor}
								text={cell.value}
							/>
						}
					</MaterialTableCell>
				))
			}
			</MaterialTableRow>
		);
	}
}
