import React from "react";
import "./style.scss";

interface IProps {
    yearly: number;
    monthly: number;
    daily: number;
}

const CardPrintCounter: React.FC<IProps> = ({yearly, monthly, daily }) => {
    return(
        <div>
            <h4>Dados de impressões:</h4>
            <div className="container">
                <div className="miniCard">
                    <h6>Anual</h6>
                    <p>{yearly}</p>
                </div>
                <div className="miniCard">
                    <h6>Mensal</h6>
                    <p>{monthly}</p>
                </div>
                <div className="miniCard">
                    <h6>Diário</h6>
                    <p>{daily}</p>
                </div>
            </div>
        </div>
    );
};

export { CardPrintCounter };
