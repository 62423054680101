import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";

// Components
import { Route, Switch } from "react-router-dom";
import PaymentTable from "./PaymentTable";

// Resources
import * as api from "../../../admin-api-web";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class PaymentContainer extends React.Component<IProps> {
	public async componentDidMount() {
		this.props.authStore.verifyPermission(api.Permission.managePayments);
	}

	public render() {
		return (
			<Switch>
				<Route exact path="/dashboard/payments" component={PaymentTable} />
			</Switch>
		);
	}
}
