// Models
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { IRowItem } from "../../components/Table/TableRow";

// Mobx
import { observable, action, computed } from "mobx";
import { uiStore } from "../_rootStore";

// Resources
import strings from "../../resources/strings";

export default abstract class TableStore<RowType> extends VariableChangeHandler {
    @observable public loading: boolean = false;
    @observable public pageOffset: number = 0;
    @observable public rowItems: RowType[] = [];
    @observable public selectedRowItem: RowType | null = null;
    @observable public selection: RowType[] = [];

    public get tableHeader(): string[] {
        return this.formatTableHeader();
    }

    @computed
    public get tableRows(): IRowItem[] {
        return this.rowItems.map(this.formatDataToRow);
    }

    protected abstract formatDataToRow(rowItem: RowType): IRowItem;
    protected abstract formatTableHeader(): string[];
    protected async abstract getSingleItemById(id: string): Promise<RowType>;
    protected async abstract getDataItemsPerPage(pageOffset: number): Promise<RowType[]>;

    @action
    public fetchInitialData = async (itemId?: string) => {
        this.selectedRowItem = null;
        this.rowItems = [];

        if (itemId) {
            this.selectedRowItem = await this.getSingleItemById(itemId);
        }

        await this.fetchData(0);
    }

    @action
    public fetchData = async (pageOffset: number) => {
        if (this.loading || pageOffset < 0) {
            return;
        }

        this.loading = true;

        try {
            const newActions: RowType[] = await this.getDataItemsPerPage(pageOffset);

            if (newActions.length > 0) {
                this.rowItems = newActions;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            uiStore.openErrorSnackbar(e);
        } finally {
            this.loading = false;
        }
    }

    @action
    public getData = async () => {
        await this.fetchData(this.pageOffset);
    }

    @computed
    public get selectionLength() {
        return this.selection.length;
    }

    @action
    public clearSelection = () => {
        this.selection = [];
    }

    @action
    public nextPage = async () => await this.fetchData(this.pageOffset + 1);

    @action
    public previousPage = async () => await this.fetchData(this.pageOffset - 1);
}
