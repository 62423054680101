import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { inspect } from "util";

export default class AddressService extends VariableChangeHandler {
    // Address Variables
    @observable public cep: string = "";
    @observable public cepValidated: boolean = false;
    @observable public cepBeeingValidated: boolean = false;
    @observable public cepValidationErrorMessage: string | null = null;

    @observable public street: string = "";
    @observable public neighborhood: string = "";
    @observable public number: string = "";
    @observable public city: string = "";
    @observable public state: string = "";
    @observable public uf: api.StateUF | "" = api.StateUF.BA;
	@observable public lat: number = 0;
    @observable public lng: number = 0;

    @computed public get address() {
        return {
            cep: this.cep,
            number: this.number,
            street: this.street,
            neighborhood: this.neighborhood,
            city: this.city,
            state: this.state,
            uf: this.uf ? this.uf : null,
            latLng: {
                lat: this.lat,
                lng: this.lng,
            },
        };
    }

    @action
    public cepValidate = (cepString: string) => {
        if (this.cepBeeingValidated) {
            return;
        }

        this.cep = cepString;

        setTimeout(async () => {
            if (this.cep === cepString) {
                this.cepBeeingValidated = true;

                try {
                    const address = await api.getAddressFromCep(this.cep);
                    this.setFields(address);
                } catch (e) {
                    let errorMessage: string;

                    if (e.message) {
                        errorMessage = e.message;
                    } else {
                        errorMessage = inspect(e);
                    }

                    this.cepValidationErrorMessage = errorMessage;
                } finally {
                    this.cepBeeingValidated = false;
                }
            }
        }, 600);
    }

    @action public setFields = (address: api.Address) => {
        this.clear();

        this.cep = address.cep;
        this.cepValidated = true;
        this.cepBeeingValidated = false;
        this.cepValidationErrorMessage = null;

        this.number = address.number;
        this.street = address.street;
        this.neighborhood = address.neighborhood;
        this.city = address.city;
        this.state = address.state;
        this.uf = address.uf ? address.uf : "";
        this.lat = address.latLng.lat;
        this.lng = address.latLng.lng;
    }

    @action public clear = () => {
        this.cep = "";
        this.cepValidated = false;
        this.cepBeeingValidated = false;
        this.cepValidationErrorMessage = null;

        this.number = "";
        this.street = "";
        this.neighborhood = "";
        this.city = "";
        this.state = "";
        this.uf = api.StateUF.BA;
        this.lat = 0;
        this.lng = 0;
    }
}
