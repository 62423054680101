import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface Address {
    cep: string;
    number: string;
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    uf: StateUF | null;
    latLng: LatLng;
}

export interface InstitutionResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: Institution[];
}

export interface Institution {
    id: string;
    name: string;
    state: StateUF;
    city: string;
    isActive: boolean;
}

export interface InstitutionDetails {
    name: string;
    state: StateUF;
    city: string;
    isActive: boolean;
}

export interface InstitutionFilter {
    name: string | null;
    state: StateUF | null;
    city: string | null;
    isActive: boolean | null;
}

export interface CourseResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: Course[];
}

export interface Course {
    id: string;
    name: string;
    isActive: boolean;
}

export interface CourseDetails {
    name: string;
    isActive: boolean;
}

export interface CourseFilter {
    name: string | null;
    isActive: boolean | null;
}

export interface CityFilter {
    name: string | null;
    uf: StateUF | null;
}

export interface City {
    id: string;
    name: string;
    state: string;
    uf: string;
}

export interface Crop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
    crop: Crop | null;
}

export interface File {
    name: string;
    url: string;
}

export interface Payment {
    id: string;
    amount: number;
    createdAt: Date;
    cardPrintId: string[];
    status: PaymentStatus;
}

export interface SignatureAttributes {
    type: string;
    values: string[];
}

export interface NewSignature {
    issuer: string;
    holder: string;
    signature: string;
    cardPrintId: string[];
    extensions: string[];
    version: string;
    publicKey: string;
    certHash: string;
    signatureValue: string;
}

export interface Signature {
    cardPrintId: string[];
    certHash: string;
    issuer: string;
    holder: string;
    version: string;
    signature: string;
    publicKey: string;
    extensions: string[];
    serialNumber: string;
    signatureValue: string;
    attCertValidityPeriod: string;
    attributes: SignatureAttributes[];
}

export interface CardPrintUser {
    avatar: Image;
    name: string;
    birthday: Date;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    institution: Institution;
    course: Course;
    email: string;
    phone: string;
}

export interface CardPrint {
    id: string[];
    year: number;
    useCode: string;
    createdAt: Date;
    userId: string;
    seller: Seller | null;
    status: PrintStatus;
    type: CardPrintType;
    payments: Payment[];
    signature: Signature | null;
    avatar: Image;
    name: string;
    birthday: Date;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    institution: Institution;
    course: Course;
    email: string;
    phone: string;
}

export interface PartialCardPrint {
    id: string[];
    useCode: string;
    userId: string;
    name: string;
    createdAt: Date;
    seller: Seller | null;
    status: PrintStatus;
    type: CardPrintType;
    payments: Payment[];
}

export interface Seller {
    id: string;
    name: string;
}

export interface User {
    id: string;
    avatar: Image | null;
    institution: Institution;
    course: Course;
    seller: Seller | null;
    cardPrintStatus: PrintStatus | null;
    lastPrint: Date | null;
    name: string;
    birthday: Date;
    email: string | null;
    phone: string | null;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    address: Address;
}

export interface NewUser {
    password: string | null;
    avatar: UncertainImage | null;
    institutionId: string;
    courseId: string;
    name: string;
    birthday: Date;
    email: string | null;
    phone: string | null;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    address: Address;
}

export interface EditUser {
    avatar: UncertainImage | null;
    institutionId: string;
    courseId: string;
    name: string;
    birthday: Date;
    email: string | null;
    phone: string | null;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    address: Address;
}

export interface UserDetails {
    name: string;
    birthday: Date;
    email: string | null;
    phone: string | null;
    cpf: string | null;
    rg: RG;
    schooling: Schooling;
    studentId: string;
    address: Address;
}

export interface RG {
    number: string;
    institution: string;
}

export interface UsersReports {
    Nome: string | null;
    CPF: string | null;
    RG: string | null;
    Expedidor: string | null;
    Nascimento: string | null;
    Email: string | null;
    Estado: string | null;
    Cidade: string | null;
    Bairro: string | null;
    Rua: string | null;
    Numero: string | null;
    UF: string | null;
    CEP: string | null;
    Telefone: string | null;
    Instituicao: string | null;
    Curso: string | null;
    Escolaridade: string | null;
    Promotor: string | null;
    UltimaImpressao: string | null;
}

export interface UserResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: User[];
}

export interface PartialUserResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: PartialUser[];
}

export interface UserFilter {
    name: string | null;
    email: string | null;
    cpf: string | null;
    rg: string | null;
    uf: StateUF | null;
    editedAt: Date | null;
    schooling: Schooling | null;
    institutionId: string | null;
    courseId: string | null;
    studentId: string | null;
    sellerId: string | null;
    status: PrintStatusPending | null;
}

export interface PartialUser {
    id: string;
    avatar: Image | null;
    institution: string | null;
    name: string | null;
    studentId: string | null;
    seller: string | null;
    cardPrintStatus: PrintStatus | null;
    email: string | null;
    cpf: string | null;
    phone: string | null;
    birthday: Date;
}

export interface IUserRowJson {
    nome: string;
    instituicao: string;
    curso: string;
    email: string | null;
    telefone: string | null;
    cpf: string | null;
    rg: string;
    escolaridade: string;
    orgaoExpedidor: string;
    matricula: string;
    cep: string;
    rua: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    uf: string;
    nascimento: string;
}

export interface AdminUserResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: AdminUser[];
}

export interface AdminUser {
    id: string;
    manager: LeaderUser | null;
    coordinator: LeaderUser | null;
    approved: boolean | null;
    userConfig: UserConfig | null;
    type: AdminUserType | null;
    isSuperAdmin: boolean;
    permissions: Permission[];
    name: string;
    email: string;
    phone: string;
}

export interface LeaderUser {
    id: string;
    type: AdminUserType | null;
    isSuperAdmin: boolean;
    permissions: Permission[];
    name: string;
    email: string;
    phone: string;
}

export interface AdminUserDetails {
    type: AdminUserType | null;
    isSuperAdmin: boolean;
    permissions: Permission[];
    name: string;
    email: string;
    phone: string;
}

export interface AdminUserPublicDetails {
    name: string;
    email: string;
    phone: string;
}

export interface AdminUserFilter {
    type: AdminUserType | null;
    email: string | null;
    name: string | null;
}

export interface NewAdminUser {
    managerId: string | null;
    coordinatorId: string | null;
    password: string | null;
    type: AdminUserType | null;
    isSuperAdmin: boolean;
    permissions: Permission[];
    name: string;
    email: string;
    phone: string;
}

export interface EditAdminUser {
    managerId: string | null;
    coordinatorId: string | null;
    type: AdminUserType | null;
    isSuperAdmin: boolean;
    permissions: Permission[];
    name: string;
    email: string;
    phone: string;
}

export interface NewAdminUserPublic {
    password: string | null;
    name: string;
    email: string;
    phone: string;
}

export interface HomeData {
    name: string;
    yearly: number;
    monthly: number;
    daily: number;
}

export interface UserConfig {
    usersTableColumnOrder: UsersColumnType[] | null;
}

export interface CardPrintResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: CardPrint[];
}

export interface CardPrintFilter {
    status: PrintStatus | null;
    type: CardPrintType | null;
    name: string | null;
    cpf: string | null;
    rg: string | null;
    schooling: Schooling | null;
    institutionId: string | null;
    courseId: string | null;
    studentId: string | null;
    sellerId: string | null;
    isReprint: boolean | null;
}

export interface PartialCardPrintResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: PartialCardPrint[];
}

export interface NewCardPrint {
    userId: string;
    printType: CardPrintType;
}

export interface LogResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: Log[];
}

export interface Log {
    id: string;
    createdAt: Date;
    text: string;
    adminUser: AdminUser;
}

export interface PaymentResults {
    pageOffset: number;
    numberPerPage: number;
    total: number;
    results: Payment[];
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum PrintStatus {
    printed = "printed",
    requested = "requested",
}

export function translatePrintStatus(enumPrintStatus: PrintStatus): string {
    switch (enumPrintStatus) {
        case PrintStatus.printed: {
            return strings ? strings["enum"]["PrintStatus"]["printed"] || PrintStatus.printed : PrintStatus.printed;
        }
        case PrintStatus.requested: {
            return strings ? strings["enum"]["PrintStatus"]["requested"] || PrintStatus.requested : PrintStatus.requested;
        }
    }
    return "";
}

export function allValuesPrintStatus(): PrintStatus[] {
    return [
        PrintStatus.printed,
        PrintStatus.requested,
    ];
}

export function allTranslatedValuesPrintStatus(): string[] {
    return [
        translatePrintStatus(PrintStatus.printed),
        translatePrintStatus(PrintStatus.requested),
    ];
}

export function allDisplayableValuesPrintStatus(): string[] {
    return allTranslatedValuesPrintStatus().sort();
}

export function valueFromTranslationPrintStatus(translation: string): PrintStatus {
    const index = allTranslatedValuesPrintStatus().indexOf(translation);
    return allValuesPrintStatus()[index] || PrintStatus.printed;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum PaymentStatus {
    paid = "paid",
    canceled = "canceled",
}

export function translatePaymentStatus(enumPaymentStatus: PaymentStatus): string {
    switch (enumPaymentStatus) {
        case PaymentStatus.paid: {
            return strings ? strings["enum"]["PaymentStatus"]["paid"] || PaymentStatus.paid : PaymentStatus.paid;
        }
        case PaymentStatus.canceled: {
            return strings ? strings["enum"]["PaymentStatus"]["canceled"] || PaymentStatus.canceled : PaymentStatus.canceled;
        }
    }
    return "";
}

export function allValuesPaymentStatus(): PaymentStatus[] {
    return [
        PaymentStatus.paid,
        PaymentStatus.canceled,
    ];
}

export function allTranslatedValuesPaymentStatus(): string[] {
    return [
        translatePaymentStatus(PaymentStatus.paid),
        translatePaymentStatus(PaymentStatus.canceled),
    ];
}

export function allDisplayableValuesPaymentStatus(): string[] {
    return allTranslatedValuesPaymentStatus().sort();
}

export function valueFromTranslationPaymentStatus(translation: string): PaymentStatus {
    const index = allTranslatedValuesPaymentStatus().indexOf(translation);
    return allValuesPaymentStatus()[index] || PaymentStatus.paid;
}

export enum CardPrintType {
    webOnly = "webOnly",
    full = "full",
}

export function translateCardPrintType(enumCardPrintType: CardPrintType): string {
    switch (enumCardPrintType) {
        case CardPrintType.webOnly: {
            return strings ? strings["enum"]["CardPrintType"]["webOnly"] || CardPrintType.webOnly : CardPrintType.webOnly;
        }
        case CardPrintType.full: {
            return strings ? strings["enum"]["CardPrintType"]["full"] || CardPrintType.full : CardPrintType.full;
        }
    }
    return "";
}

export function allValuesCardPrintType(): CardPrintType[] {
    return [
        CardPrintType.webOnly,
        CardPrintType.full,
    ];
}

export function allTranslatedValuesCardPrintType(): string[] {
    return [
        translateCardPrintType(CardPrintType.webOnly),
        translateCardPrintType(CardPrintType.full),
    ];
}

export function allDisplayableValuesCardPrintType(): string[] {
    return allTranslatedValuesCardPrintType().sort();
}

export function valueFromTranslationCardPrintType(translation: string): CardPrintType {
    const index = allTranslatedValuesCardPrintType().indexOf(translation);
    return allValuesCardPrintType()[index] || CardPrintType.webOnly;
}

export enum Schooling {
    earlyChildhoodEducation = "earlyChildhoodEducation",
    elementaryEducation = "elementaryEducation",
    secondaryEducation = "secondaryEducation",
    technicalEducation = "technicalEducation",
    higherEducation = "higherEducation",
    postgraduate = "postgraduate",
    master = "master",
    doctorate = "doctorate",
}

export function translateSchooling(enumSchooling: Schooling): string {
    switch (enumSchooling) {
        case Schooling.earlyChildhoodEducation: {
            return strings ? strings["enum"]["Schooling"]["earlyChildhoodEducation"] || Schooling.earlyChildhoodEducation : Schooling.earlyChildhoodEducation;
        }
        case Schooling.elementaryEducation: {
            return strings ? strings["enum"]["Schooling"]["elementaryEducation"] || Schooling.elementaryEducation : Schooling.elementaryEducation;
        }
        case Schooling.secondaryEducation: {
            return strings ? strings["enum"]["Schooling"]["secondaryEducation"] || Schooling.secondaryEducation : Schooling.secondaryEducation;
        }
        case Schooling.technicalEducation: {
            return strings ? strings["enum"]["Schooling"]["technicalEducation"] || Schooling.technicalEducation : Schooling.technicalEducation;
        }
        case Schooling.higherEducation: {
            return strings ? strings["enum"]["Schooling"]["higherEducation"] || Schooling.higherEducation : Schooling.higherEducation;
        }
        case Schooling.postgraduate: {
            return strings ? strings["enum"]["Schooling"]["postgraduate"] || Schooling.postgraduate : Schooling.postgraduate;
        }
        case Schooling.master: {
            return strings ? strings["enum"]["Schooling"]["master"] || Schooling.master : Schooling.master;
        }
        case Schooling.doctorate: {
            return strings ? strings["enum"]["Schooling"]["doctorate"] || Schooling.doctorate : Schooling.doctorate;
        }
    }
    return "";
}

export function allValuesSchooling(): Schooling[] {
    return [
        Schooling.earlyChildhoodEducation,
        Schooling.elementaryEducation,
        Schooling.secondaryEducation,
        Schooling.technicalEducation,
        Schooling.higherEducation,
        Schooling.postgraduate,
        Schooling.master,
        Schooling.doctorate,
    ];
}

export function allTranslatedValuesSchooling(): string[] {
    return [
        translateSchooling(Schooling.earlyChildhoodEducation),
        translateSchooling(Schooling.elementaryEducation),
        translateSchooling(Schooling.secondaryEducation),
        translateSchooling(Schooling.technicalEducation),
        translateSchooling(Schooling.higherEducation),
        translateSchooling(Schooling.postgraduate),
        translateSchooling(Schooling.master),
        translateSchooling(Schooling.doctorate),
    ];
}

export function allDisplayableValuesSchooling(): string[] {
    return allTranslatedValuesSchooling().sort();
}

export function valueFromTranslationSchooling(translation: string): Schooling {
    const index = allTranslatedValuesSchooling().indexOf(translation);
    return allValuesSchooling()[index] || Schooling.earlyChildhoodEducation;
}

export enum PrintStatusPending {
    printed = "printed",
    requested = "requested",
    pending = "pending",
}

export function translatePrintStatusPending(enumPrintStatusPending: PrintStatusPending): string {
    switch (enumPrintStatusPending) {
        case PrintStatusPending.printed: {
            return strings ? strings["enum"]["PrintStatusPending"]["printed"] || PrintStatusPending.printed : PrintStatusPending.printed;
        }
        case PrintStatusPending.requested: {
            return strings ? strings["enum"]["PrintStatusPending"]["requested"] || PrintStatusPending.requested : PrintStatusPending.requested;
        }
        case PrintStatusPending.pending: {
            return strings ? strings["enum"]["PrintStatusPending"]["pending"] || PrintStatusPending.pending : PrintStatusPending.pending;
        }
    }
    return "";
}

export function allValuesPrintStatusPending(): PrintStatusPending[] {
    return [
        PrintStatusPending.printed,
        PrintStatusPending.requested,
        PrintStatusPending.pending,
    ];
}

export function allTranslatedValuesPrintStatusPending(): string[] {
    return [
        translatePrintStatusPending(PrintStatusPending.printed),
        translatePrintStatusPending(PrintStatusPending.requested),
        translatePrintStatusPending(PrintStatusPending.pending),
    ];
}

export function allDisplayableValuesPrintStatusPending(): string[] {
    return allTranslatedValuesPrintStatusPending().sort();
}

export function valueFromTranslationPrintStatusPending(translation: string): PrintStatusPending {
    const index = allTranslatedValuesPrintStatusPending().indexOf(translation);
    return allValuesPrintStatusPending()[index] || PrintStatusPending.printed;
}

export enum AdminUserType {
    manager = "manager",
    coordinator = "coordinator",
    seller = "seller",
}

export function translateAdminUserType(enumAdminUserType: AdminUserType): string {
    switch (enumAdminUserType) {
        case AdminUserType.manager: {
            return strings ? strings["enum"]["AdminUserType"]["manager"] || AdminUserType.manager : AdminUserType.manager;
        }
        case AdminUserType.coordinator: {
            return strings ? strings["enum"]["AdminUserType"]["coordinator"] || AdminUserType.coordinator : AdminUserType.coordinator;
        }
        case AdminUserType.seller: {
            return strings ? strings["enum"]["AdminUserType"]["seller"] || AdminUserType.seller : AdminUserType.seller;
        }
    }
    return "";
}

export function allValuesAdminUserType(): AdminUserType[] {
    return [
        AdminUserType.manager,
        AdminUserType.coordinator,
        AdminUserType.seller,
    ];
}

export function allTranslatedValuesAdminUserType(): string[] {
    return [
        translateAdminUserType(AdminUserType.manager),
        translateAdminUserType(AdminUserType.coordinator),
        translateAdminUserType(AdminUserType.seller),
    ];
}

export function allDisplayableValuesAdminUserType(): string[] {
    return allTranslatedValuesAdminUserType().sort();
}

export function valueFromTranslationAdminUserType(translation: string): AdminUserType {
    const index = allTranslatedValuesAdminUserType().indexOf(translation);
    return allValuesAdminUserType()[index] || AdminUserType.manager;
}

export enum Permission {
    printCard = "printCard",
    managePayments = "managePayments",
    manageCourses = "manageCourses",
    manageInstitutions = "manageInstitutions",
    manageStudents = "manageStudents",
    userLogs = "userLogs",
    approveAdminUsers = "approveAdminUsers",
}

export function translatePermission(enumPermission: Permission): string {
    switch (enumPermission) {
        case Permission.printCard: {
            return strings ? strings["enum"]["Permission"]["printCard"] || Permission.printCard : Permission.printCard;
        }
        case Permission.managePayments: {
            return strings ? strings["enum"]["Permission"]["managePayments"] || Permission.managePayments : Permission.managePayments;
        }
        case Permission.manageCourses: {
            return strings ? strings["enum"]["Permission"]["manageCourses"] || Permission.manageCourses : Permission.manageCourses;
        }
        case Permission.manageInstitutions: {
            return strings ? strings["enum"]["Permission"]["manageInstitutions"] || Permission.manageInstitutions : Permission.manageInstitutions;
        }
        case Permission.manageStudents: {
            return strings ? strings["enum"]["Permission"]["manageStudents"] || Permission.manageStudents : Permission.manageStudents;
        }
        case Permission.userLogs: {
            return strings ? strings["enum"]["Permission"]["userLogs"] || Permission.userLogs : Permission.userLogs;
        }
        case Permission.approveAdminUsers: {
            return strings ? strings["enum"]["Permission"]["approveAdminUsers"] || Permission.approveAdminUsers : Permission.approveAdminUsers;
        }
    }
    return "";
}

export function allValuesPermission(): Permission[] {
    return [
        Permission.printCard,
        Permission.managePayments,
        Permission.manageCourses,
        Permission.manageInstitutions,
        Permission.manageStudents,
        Permission.userLogs,
        Permission.approveAdminUsers,
    ];
}

export function allTranslatedValuesPermission(): string[] {
    return [
        translatePermission(Permission.printCard),
        translatePermission(Permission.managePayments),
        translatePermission(Permission.manageCourses),
        translatePermission(Permission.manageInstitutions),
        translatePermission(Permission.manageStudents),
        translatePermission(Permission.userLogs),
        translatePermission(Permission.approveAdminUsers),
    ];
}

export function allDisplayableValuesPermission(): string[] {
    return allTranslatedValuesPermission().sort();
}

export function valueFromTranslationPermission(translation: string): Permission {
    const index = allTranslatedValuesPermission().indexOf(translation);
    return allValuesPermission()[index] || Permission.printCard;
}

export enum UsersColumnType {
    avatar = "avatar",
    name = "name",
    institution = "institution",
    course = "course",
    email = "email",
    phone = "phone",
    cpf = "cpf",
    schooling = "schooling",
    studentId = "studentId",
    seller = "seller",
    cardPrint = "cardPrint",
    rg = "rg",
    birthday = "birthday",
}

export function translateUsersColumnType(enumUsersColumnType: UsersColumnType): string {
    switch (enumUsersColumnType) {
        case UsersColumnType.avatar: {
            return strings ? strings["enum"]["UsersColumnType"]["avatar"] || UsersColumnType.avatar : UsersColumnType.avatar;
        }
        case UsersColumnType.name: {
            return strings ? strings["enum"]["UsersColumnType"]["name"] || UsersColumnType.name : UsersColumnType.name;
        }
        case UsersColumnType.institution: {
            return strings ? strings["enum"]["UsersColumnType"]["institution"] || UsersColumnType.institution : UsersColumnType.institution;
        }
        case UsersColumnType.course: {
            return strings ? strings["enum"]["UsersColumnType"]["course"] || UsersColumnType.course : UsersColumnType.course;
        }
        case UsersColumnType.email: {
            return strings ? strings["enum"]["UsersColumnType"]["email"] || UsersColumnType.email : UsersColumnType.email;
        }
        case UsersColumnType.phone: {
            return strings ? strings["enum"]["UsersColumnType"]["phone"] || UsersColumnType.phone : UsersColumnType.phone;
        }
        case UsersColumnType.cpf: {
            return strings ? strings["enum"]["UsersColumnType"]["cpf"] || UsersColumnType.cpf : UsersColumnType.cpf;
        }
        case UsersColumnType.schooling: {
            return strings ? strings["enum"]["UsersColumnType"]["schooling"] || UsersColumnType.schooling : UsersColumnType.schooling;
        }
        case UsersColumnType.studentId: {
            return strings ? strings["enum"]["UsersColumnType"]["studentId"] || UsersColumnType.studentId : UsersColumnType.studentId;
        }
        case UsersColumnType.seller: {
            return strings ? strings["enum"]["UsersColumnType"]["seller"] || UsersColumnType.seller : UsersColumnType.seller;
        }
        case UsersColumnType.cardPrint: {
            return strings ? strings["enum"]["UsersColumnType"]["cardPrint"] || UsersColumnType.cardPrint : UsersColumnType.cardPrint;
        }
        case UsersColumnType.rg: {
            return strings ? strings["enum"]["UsersColumnType"]["rg"] || UsersColumnType.rg : UsersColumnType.rg;
        }
        case UsersColumnType.birthday: {
            return strings ? strings["enum"]["UsersColumnType"]["birthday"] || UsersColumnType.birthday : UsersColumnType.birthday;
        }
    }
    return "";
}

export function allValuesUsersColumnType(): UsersColumnType[] {
    return [
        UsersColumnType.avatar,
        UsersColumnType.name,
        UsersColumnType.institution,
        UsersColumnType.course,
        UsersColumnType.email,
        UsersColumnType.phone,
        UsersColumnType.cpf,
        UsersColumnType.schooling,
        UsersColumnType.studentId,
        UsersColumnType.seller,
        UsersColumnType.cardPrint,
        UsersColumnType.rg,
        UsersColumnType.birthday,
    ];
}

export function allTranslatedValuesUsersColumnType(): string[] {
    return [
        translateUsersColumnType(UsersColumnType.avatar),
        translateUsersColumnType(UsersColumnType.name),
        translateUsersColumnType(UsersColumnType.institution),
        translateUsersColumnType(UsersColumnType.course),
        translateUsersColumnType(UsersColumnType.email),
        translateUsersColumnType(UsersColumnType.phone),
        translateUsersColumnType(UsersColumnType.cpf),
        translateUsersColumnType(UsersColumnType.schooling),
        translateUsersColumnType(UsersColumnType.studentId),
        translateUsersColumnType(UsersColumnType.seller),
        translateUsersColumnType(UsersColumnType.cardPrint),
        translateUsersColumnType(UsersColumnType.rg),
        translateUsersColumnType(UsersColumnType.birthday),
    ];
}

export function allDisplayableValuesUsersColumnType(): string[] {
    return allTranslatedValuesUsersColumnType().sort();
}

export function valueFromTranslationUsersColumnType(translation: string): UsersColumnType {
    const index = allTranslatedValuesUsersColumnType().indexOf(translation);
    return allValuesUsersColumnType()[index] || UsersColumnType.avatar;
}

export enum ErrorType {
    AccessNotAllowed = "AccessNotAllowed",
    NotFound = "NotFound",
    InvalidArgument = "InvalidArgument",
    MissingArgument = "MissingArgument",
    BadFormattedResponse = "BadFormattedResponse",
    WrongLoginOrPassword = "WrongLoginOrPassword",
    NotLoggedIn = "NotLoggedIn",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    MissingPermission = "MissingPermission",
    DocumentNumberAlreadyInUse = "DocumentNumberAlreadyInUse",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    CpfAlreadyInUse = "CpfAlreadyInUse",
    InvalidCpf = "InvalidCpf",
    RgAlreadyInUse = "RgAlreadyInUse",
    InvalidRg = "InvalidRg",
    UserIsNotManager = "UserIsNotManager",
    UserIsNotCoordinator = "UserIsNotCoordinator",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.WrongLoginOrPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongLoginOrPassword"] || ErrorType.WrongLoginOrPassword : ErrorType.WrongLoginOrPassword;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.MissingPermission: {
            return strings ? strings["enum"]["ErrorType"]["MissingPermission"] || ErrorType.MissingPermission : ErrorType.MissingPermission;
        }
        case ErrorType.DocumentNumberAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["DocumentNumberAlreadyInUse"] || ErrorType.DocumentNumberAlreadyInUse : ErrorType.DocumentNumberAlreadyInUse;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.CpfAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["CpfAlreadyInUse"] || ErrorType.CpfAlreadyInUse : ErrorType.CpfAlreadyInUse;
        }
        case ErrorType.InvalidCpf: {
            return strings ? strings["enum"]["ErrorType"]["InvalidCpf"] || ErrorType.InvalidCpf : ErrorType.InvalidCpf;
        }
        case ErrorType.RgAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["RgAlreadyInUse"] || ErrorType.RgAlreadyInUse : ErrorType.RgAlreadyInUse;
        }
        case ErrorType.InvalidRg: {
            return strings ? strings["enum"]["ErrorType"]["InvalidRg"] || ErrorType.InvalidRg : ErrorType.InvalidRg;
        }
        case ErrorType.UserIsNotManager: {
            return strings ? strings["enum"]["ErrorType"]["UserIsNotManager"] || ErrorType.UserIsNotManager : ErrorType.UserIsNotManager;
        }
        case ErrorType.UserIsNotCoordinator: {
            return strings ? strings["enum"]["ErrorType"]["UserIsNotCoordinator"] || ErrorType.UserIsNotCoordinator : ErrorType.UserIsNotCoordinator;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.AccessNotAllowed,
        ErrorType.NotFound,
        ErrorType.InvalidArgument,
        ErrorType.MissingArgument,
        ErrorType.BadFormattedResponse,
        ErrorType.WrongLoginOrPassword,
        ErrorType.NotLoggedIn,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.MissingPermission,
        ErrorType.DocumentNumberAlreadyInUse,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.CpfAlreadyInUse,
        ErrorType.InvalidCpf,
        ErrorType.RgAlreadyInUse,
        ErrorType.InvalidRg,
        ErrorType.UserIsNotManager,
        ErrorType.UserIsNotCoordinator,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.WrongLoginOrPassword),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.MissingPermission),
        translateErrorType(ErrorType.DocumentNumberAlreadyInUse),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.CpfAlreadyInUse),
        translateErrorType(ErrorType.InvalidCpf),
        translateErrorType(ErrorType.RgAlreadyInUse),
        translateErrorType(ErrorType.InvalidRg),
        translateErrorType(ErrorType.UserIsNotManager),
        translateErrorType(ErrorType.UserIsNotCoordinator),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.AccessNotAllowed;
}

export async function getInstitution(institutionId: string, progress?: (progress: number) => void): Promise<Institution> {
    const args = {
        institutionId: institutionId,
    };
    const ret = await makeRequest({name: "getInstitution", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        state: ret.state,
        city: ret.city,
        isActive: !!ret.isActive,
    };
}

export async function getInstitutions(filter: InstitutionFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<InstitutionResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            state: filter.state === null || filter.state === undefined ? null : filter.state,
            city: filter.city === null || filter.city === undefined ? null : filter.city,
            isActive: filter.isActive === null || filter.isActive === undefined ? null : !!filter.isActive,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getInstitutions", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            name: e.name,
            state: e.state,
            city: e.city,
            isActive: !!e.isActive,
        })),
    };
}

export async function getCourse(courseId: string, progress?: (progress: number) => void): Promise<Course> {
    const args = {
        courseId: courseId,
    };
    const ret = await makeRequest({name: "getCourse", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        isActive: !!ret.isActive,
    };
}

export async function getCourses(filter: CourseFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<CourseResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            isActive: filter.isActive === null || filter.isActive === undefined ? null : !!filter.isActive,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getCourses", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            name: e.name,
            isActive: !!e.isActive,
        })),
    };
}

export async function getAddressFromCep(cep: string, progress?: (progress: number) => void): Promise<Address> {
    const args = {
        cep: cep,
    };
    const ret = await makeRequest({name: "getAddressFromCep", args, progress});
    return {
        cep: ret.cep,
        number: ret.number,
        street: ret.street,
        neighborhood: ret.neighborhood,
        city: ret.city,
        state: ret.state,
        uf: ret.uf === null || ret.uf === undefined ? null : ret.uf,
        latLng: {
            lat: ret.latLng.lat,
            lng: ret.latLng.lng,
        },
    };
}

export async function getCities(filter: CityFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<City[]> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            uf: filter.uf === null || filter.uf === undefined ? null : filter.uf,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getCities", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        state: e.state,
        uf: e.uf,
    }));
}

export async function getCity(cityId: string, progress?: (progress: number) => void): Promise<City> {
    const args = {
        cityId: cityId,
    };
    const ret = await makeRequest({name: "getCity", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        state: ret.state,
        uf: ret.uf,
    };
}

export async function uploadFile(name: string, data: Buffer, progress?: (progress: number) => void): Promise<File> {
    const args = {
        name: name,
        data: data.toString("base64"),
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function uploadImage(image: Buffer, format: ImageFormat | null, crop: Crop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        format: format === null || format === undefined ? null : format,
        crop: crop === null || crop === undefined ? null : {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, crop: Crop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        crop: {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function validadeCard(useCode: string, birthday: Date, progress?: (progress: number) => void): Promise<CardPrint | null> {
    const args = {
        useCode: useCode,
        birthday: typeof(birthday) === "string" ? new Date(new Date(birthday).getTime() - new Date(birthday).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(birthday.getTime() - birthday.getTimezoneOffset() * 60000).toISOString().split("T")[0],
    };
    const ret = await makeRequest({name: "validadeCard", args, progress});
    return ret === null || ret === undefined ? null : {
        id: ret.id.map((e: any) => e),
        year: ret.year || 0,
        useCode: ret.useCode,
        createdAt: new Date(ret.createdAt + "Z"),
        userId: ret.userId,
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        status: ret.status,
        type: ret.type,
        payments: ret.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: ret.signature === null || ret.signature === undefined ? null : {
            cardPrintId: ret.signature.cardPrintId.map((e: any) => e),
            certHash: ret.signature.certHash,
            issuer: ret.signature.issuer,
            holder: ret.signature.holder,
            version: ret.signature.version,
            signature: ret.signature.signature,
            publicKey: ret.signature.publicKey,
            extensions: ret.signature.extensions.map((e: any) => e),
            serialNumber: ret.signature.serialNumber,
            signatureValue: ret.signature.signatureValue,
            attCertValidityPeriod: ret.signature.attCertValidityPeriod,
            attributes: ret.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getLastCardPrintByUser(userId: string, progress?: (progress: number) => void): Promise<CardPrint | null> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getLastCardPrintByUser", args, progress});
    return ret === null || ret === undefined ? null : {
        id: ret.id.map((e: any) => e),
        year: ret.year || 0,
        useCode: ret.useCode,
        createdAt: new Date(ret.createdAt + "Z"),
        userId: ret.userId,
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        status: ret.status,
        type: ret.type,
        payments: ret.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: ret.signature === null || ret.signature === undefined ? null : {
            cardPrintId: ret.signature.cardPrintId.map((e: any) => e),
            certHash: ret.signature.certHash,
            issuer: ret.signature.issuer,
            holder: ret.signature.holder,
            version: ret.signature.version,
            signature: ret.signature.signature,
            publicKey: ret.signature.publicKey,
            extensions: ret.signature.extensions.map((e: any) => e),
            serialNumber: ret.signature.serialNumber,
            signatureValue: ret.signature.signatureValue,
            attCertValidityPeriod: ret.signature.attCertValidityPeriod,
            attributes: ret.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        email: ret.email,
        phone: ret.phone,
    };
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            password: newUser.password === null || newUser.password === undefined ? null : newUser.password,
            avatar: newUser.avatar === null || newUser.avatar === undefined ? null : {
                bytes: newUser.avatar.bytes === null || newUser.avatar.bytes === undefined ? null : newUser.avatar.bytes.toString("base64"),
                image: newUser.avatar.image === null || newUser.avatar.image === undefined ? null : {
                    thumb: {
                        width: newUser.avatar.image.thumb.width || 0,
                        height: newUser.avatar.image.thumb.height || 0,
                        url: newUser.avatar.image.thumb.url,
                    },
                    width: newUser.avatar.image.width || 0,
                    height: newUser.avatar.image.height || 0,
                    url: newUser.avatar.image.url,
                },
                crop: newUser.avatar.crop === null || newUser.avatar.crop === undefined ? null : {
                    x: newUser.avatar.crop.x || 0,
                    y: newUser.avatar.crop.y || 0,
                    width: newUser.avatar.crop.width || 0,
                    height: newUser.avatar.crop.height || 0,
                },
            },
            institutionId: newUser.institutionId,
            courseId: newUser.courseId,
            name: newUser.name,
            birthday: typeof(newUser.birthday) === "string" ? new Date(new Date(newUser.birthday).getTime() - new Date(newUser.birthday).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newUser.birthday.getTime() - newUser.birthday.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            email: newUser.email === null || newUser.email === undefined ? null : newUser.email,
            phone: newUser.phone === null || newUser.phone === undefined ? null : newUser.phone,
            cpf: newUser.cpf === null || newUser.cpf === undefined ? null : newUser.cpf,
            rg: {
                number: newUser.rg.number,
                institution: newUser.rg.institution,
            },
            schooling: newUser.schooling,
            studentId: newUser.studentId,
            address: {
                cep: newUser.address.cep,
                number: newUser.address.number,
                street: newUser.address.street,
                neighborhood: newUser.address.neighborhood,
                city: newUser.address.city,
                state: newUser.address.state,
                uf: newUser.address.uf === null || newUser.address.uf === undefined ? null : newUser.address.uf,
                latLng: {
                    lat: newUser.address.latLng.lat,
                    lng: newUser.address.latLng.lng,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function sendResetPasswordEmail(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordEmail", args, progress});
    return undefined;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getUsersReportsWithStream(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "getUsersReportsWithStream", args: {}, progress});
    return ret;
}

export async function logout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logout", args: {}, progress});
    return undefined;
}

export async function createUserWithPrint(newUser: NewUser, printType: CardPrintType, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            password: newUser.password === null || newUser.password === undefined ? null : newUser.password,
            avatar: newUser.avatar === null || newUser.avatar === undefined ? null : {
                bytes: newUser.avatar.bytes === null || newUser.avatar.bytes === undefined ? null : newUser.avatar.bytes.toString("base64"),
                image: newUser.avatar.image === null || newUser.avatar.image === undefined ? null : {
                    thumb: {
                        width: newUser.avatar.image.thumb.width || 0,
                        height: newUser.avatar.image.thumb.height || 0,
                        url: newUser.avatar.image.thumb.url,
                    },
                    width: newUser.avatar.image.width || 0,
                    height: newUser.avatar.image.height || 0,
                    url: newUser.avatar.image.url,
                },
                crop: newUser.avatar.crop === null || newUser.avatar.crop === undefined ? null : {
                    x: newUser.avatar.crop.x || 0,
                    y: newUser.avatar.crop.y || 0,
                    width: newUser.avatar.crop.width || 0,
                    height: newUser.avatar.crop.height || 0,
                },
            },
            institutionId: newUser.institutionId,
            courseId: newUser.courseId,
            name: newUser.name,
            birthday: typeof(newUser.birthday) === "string" ? new Date(new Date(newUser.birthday).getTime() - new Date(newUser.birthday).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newUser.birthday.getTime() - newUser.birthday.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            email: newUser.email === null || newUser.email === undefined ? null : newUser.email,
            phone: newUser.phone === null || newUser.phone === undefined ? null : newUser.phone,
            cpf: newUser.cpf === null || newUser.cpf === undefined ? null : newUser.cpf,
            rg: {
                number: newUser.rg.number,
                institution: newUser.rg.institution,
            },
            schooling: newUser.schooling,
            studentId: newUser.studentId,
            address: {
                cep: newUser.address.cep,
                number: newUser.address.number,
                street: newUser.address.street,
                neighborhood: newUser.address.neighborhood,
                city: newUser.address.city,
                state: newUser.address.state,
                uf: newUser.address.uf === null || newUser.address.uf === undefined ? null : newUser.address.uf,
                latLng: {
                    lat: newUser.address.latLng.lat,
                    lng: newUser.address.latLng.lng,
                },
            },
        },
        printType: printType,
    };
    const ret = await makeRequest({name: "createUserWithPrint", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function editUserWithPrint(userId: string, editUser: EditUser, printType: CardPrintType, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editUser: {
            avatar: editUser.avatar === null || editUser.avatar === undefined ? null : {
                bytes: editUser.avatar.bytes === null || editUser.avatar.bytes === undefined ? null : editUser.avatar.bytes.toString("base64"),
                image: editUser.avatar.image === null || editUser.avatar.image === undefined ? null : {
                    thumb: {
                        width: editUser.avatar.image.thumb.width || 0,
                        height: editUser.avatar.image.thumb.height || 0,
                        url: editUser.avatar.image.thumb.url,
                    },
                    width: editUser.avatar.image.width || 0,
                    height: editUser.avatar.image.height || 0,
                    url: editUser.avatar.image.url,
                },
                crop: editUser.avatar.crop === null || editUser.avatar.crop === undefined ? null : {
                    x: editUser.avatar.crop.x || 0,
                    y: editUser.avatar.crop.y || 0,
                    width: editUser.avatar.crop.width || 0,
                    height: editUser.avatar.crop.height || 0,
                },
            },
            institutionId: editUser.institutionId,
            courseId: editUser.courseId,
            name: editUser.name,
            birthday: typeof(editUser.birthday) === "string" ? new Date(new Date(editUser.birthday).getTime() - new Date(editUser.birthday).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editUser.birthday.getTime() - editUser.birthday.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            email: editUser.email === null || editUser.email === undefined ? null : editUser.email,
            phone: editUser.phone === null || editUser.phone === undefined ? null : editUser.phone,
            cpf: editUser.cpf === null || editUser.cpf === undefined ? null : editUser.cpf,
            rg: {
                number: editUser.rg.number,
                institution: editUser.rg.institution,
            },
            schooling: editUser.schooling,
            studentId: editUser.studentId,
            address: {
                cep: editUser.address.cep,
                number: editUser.address.number,
                street: editUser.address.street,
                neighborhood: editUser.address.neighborhood,
                city: editUser.address.city,
                state: editUser.address.state,
                uf: editUser.address.uf === null || editUser.address.uf === undefined ? null : editUser.address.uf,
                latLng: {
                    lat: editUser.address.latLng.lat,
                    lng: editUser.address.latLng.lng,
                },
            },
        },
        printType: printType,
    };
    const ret = await makeRequest({name: "editUserWithPrint", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function getUser(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function getUserFor(cpf: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        cpf: cpf,
    };
    const ret = await makeRequest({name: "getUserFor", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function getUsers(filter: UserFilter | null, pageOffset: number, numberPerPage: number, cardPrintFilter: string | null, progress?: (progress: number) => void): Promise<PartialUserResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            email: filter.email === null || filter.email === undefined ? null : filter.email,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            rg: filter.rg === null || filter.rg === undefined ? null : filter.rg,
            uf: filter.uf === null || filter.uf === undefined ? null : filter.uf,
            editedAt: filter.editedAt === null || filter.editedAt === undefined ? null : (typeof filter.editedAt === "string" && (filter.editedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.editedAt as any).replace("Z", "") : filter.editedAt.toISOString().replace("Z", "")),
            schooling: filter.schooling === null || filter.schooling === undefined ? null : filter.schooling,
            institutionId: filter.institutionId === null || filter.institutionId === undefined ? null : filter.institutionId,
            courseId: filter.courseId === null || filter.courseId === undefined ? null : filter.courseId,
            studentId: filter.studentId === null || filter.studentId === undefined ? null : filter.studentId,
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            status: filter.status === null || filter.status === undefined ? null : filter.status,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
        cardPrintFilter: cardPrintFilter === null || cardPrintFilter === undefined ? null : cardPrintFilter,
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            avatar: e.avatar === null || e.avatar === undefined ? null : {
                thumb: {
                    width: e.avatar.thumb.width || 0,
                    height: e.avatar.thumb.height || 0,
                    url: e.avatar.thumb.url,
                },
                width: e.avatar.width || 0,
                height: e.avatar.height || 0,
                url: e.avatar.url,
            },
            institution: e.institution === null || e.institution === undefined ? null : e.institution,
            name: e.name === null || e.name === undefined ? null : e.name,
            studentId: e.studentId === null || e.studentId === undefined ? null : e.studentId,
            seller: e.seller === null || e.seller === undefined ? null : e.seller,
            cardPrintStatus: e.cardPrintStatus === null || e.cardPrintStatus === undefined ? null : e.cardPrintStatus,
            email: e.email === null || e.email === undefined ? null : e.email,
            cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        })),
    };
}

export async function getAllUsers(progress?: (progress: number) => void): Promise<User[]> {
    const ret = await makeRequest({name: "getAllUsers", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        avatar: e.avatar === null || e.avatar === undefined ? null : {
            thumb: {
                width: e.avatar.thumb.width || 0,
                height: e.avatar.thumb.height || 0,
                url: e.avatar.thumb.url,
            },
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        institution: {
            id: e.institution.id,
            name: e.institution.name,
            state: e.institution.state,
            city: e.institution.city,
            isActive: !!e.institution.isActive,
        },
        course: {
            id: e.course.id,
            name: e.course.name,
            isActive: !!e.course.isActive,
        },
        seller: e.seller === null || e.seller === undefined ? null : {
            id: e.seller.id,
            name: e.seller.name,
        },
        cardPrintStatus: e.cardPrintStatus === null || e.cardPrintStatus === undefined ? null : e.cardPrintStatus,
        lastPrint: e.lastPrint === null || e.lastPrint === undefined ? null : new Date(e.lastPrint + "Z"),
        name: e.name,
        birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        email: e.email === null || e.email === undefined ? null : e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        rg: {
            number: e.rg.number,
            institution: e.rg.institution,
        },
        schooling: e.schooling,
        studentId: e.studentId,
        address: {
            cep: e.address.cep,
            number: e.address.number,
            street: e.address.street,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            uf: e.address.uf === null || e.address.uf === undefined ? null : e.address.uf,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
    }));
}

export async function getExportUsers(filter: UserFilter | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            email: filter.email === null || filter.email === undefined ? null : filter.email,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            rg: filter.rg === null || filter.rg === undefined ? null : filter.rg,
            uf: filter.uf === null || filter.uf === undefined ? null : filter.uf,
            editedAt: filter.editedAt === null || filter.editedAt === undefined ? null : (typeof filter.editedAt === "string" && (filter.editedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.editedAt as any).replace("Z", "") : filter.editedAt.toISOString().replace("Z", "")),
            schooling: filter.schooling === null || filter.schooling === undefined ? null : filter.schooling,
            institutionId: filter.institutionId === null || filter.institutionId === undefined ? null : filter.institutionId,
            courseId: filter.courseId === null || filter.courseId === undefined ? null : filter.courseId,
            studentId: filter.studentId === null || filter.studentId === undefined ? null : filter.studentId,
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            status: filter.status === null || filter.status === undefined ? null : filter.status,
        },
    };
    const ret = await makeRequest({name: "getExportUsers", args, progress});
    return ret;
}

export async function editUser(userId: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editUser: {
            avatar: editUser.avatar === null || editUser.avatar === undefined ? null : {
                bytes: editUser.avatar.bytes === null || editUser.avatar.bytes === undefined ? null : editUser.avatar.bytes.toString("base64"),
                image: editUser.avatar.image === null || editUser.avatar.image === undefined ? null : {
                    thumb: {
                        width: editUser.avatar.image.thumb.width || 0,
                        height: editUser.avatar.image.thumb.height || 0,
                        url: editUser.avatar.image.thumb.url,
                    },
                    width: editUser.avatar.image.width || 0,
                    height: editUser.avatar.image.height || 0,
                    url: editUser.avatar.image.url,
                },
                crop: editUser.avatar.crop === null || editUser.avatar.crop === undefined ? null : {
                    x: editUser.avatar.crop.x || 0,
                    y: editUser.avatar.crop.y || 0,
                    width: editUser.avatar.crop.width || 0,
                    height: editUser.avatar.crop.height || 0,
                },
            },
            institutionId: editUser.institutionId,
            courseId: editUser.courseId,
            name: editUser.name,
            birthday: typeof(editUser.birthday) === "string" ? new Date(new Date(editUser.birthday).getTime() - new Date(editUser.birthday).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editUser.birthday.getTime() - editUser.birthday.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            email: editUser.email === null || editUser.email === undefined ? null : editUser.email,
            phone: editUser.phone === null || editUser.phone === undefined ? null : editUser.phone,
            cpf: editUser.cpf === null || editUser.cpf === undefined ? null : editUser.cpf,
            rg: {
                number: editUser.rg.number,
                institution: editUser.rg.institution,
            },
            schooling: editUser.schooling,
            studentId: editUser.studentId,
            address: {
                cep: editUser.address.cep,
                number: editUser.address.number,
                street: editUser.address.street,
                neighborhood: editUser.address.neighborhood,
                city: editUser.address.city,
                state: editUser.address.state,
                uf: editUser.address.uf === null || editUser.address.uf === undefined ? null : editUser.address.uf,
                latLng: {
                    lat: editUser.address.latLng.lat,
                    lng: editUser.address.latLng.lng,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        avatar: ret.avatar === null || ret.avatar === undefined ? null : {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        cardPrintStatus: ret.cardPrintStatus === null || ret.cardPrintStatus === undefined ? null : ret.cardPrintStatus,
        lastPrint: ret.lastPrint === null || ret.lastPrint === undefined ? null : new Date(ret.lastPrint + "Z"),
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        address: {
            cep: ret.address.cep,
            number: ret.address.number,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            uf: ret.address.uf === null || ret.address.uf === undefined ? null : ret.address.uf,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
    };
}

export async function cpfAvailableFor(userId: string | null, cpf: string, progress?: (progress: number) => void): Promise<string> {
    const args = {
        userId: userId === null || userId === undefined ? null : userId,
        cpf: cpf,
    };
    const ret = await makeRequest({name: "cpfAvailableFor", args, progress});
    return ret;
}

export async function emailAvailableFor(userId: string | null, email: string, progress?: (progress: number) => void): Promise<string> {
    const args = {
        userId: userId === null || userId === undefined ? null : userId,
        email: email,
    };
    const ret = await makeRequest({name: "emailAvailableFor", args, progress});
    return ret;
}

export async function createUsersWithCSVRowUserJSON(csvData: IUserRowJson[], progress?: (progress: number) => void): Promise<void> {
    const args = {
        csvData: csvData.map(e => ({
            nome: e.nome,
            instituicao: e.instituicao,
            curso: e.curso,
            email: e.email === null || e.email === undefined ? null : e.email,
            telefone: e.telefone === null || e.telefone === undefined ? null : e.telefone,
            cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
            rg: e.rg,
            escolaridade: e.escolaridade,
            orgaoExpedidor: e.orgaoExpedidor,
            matricula: e.matricula,
            cep: e.cep,
            rua: e.rua,
            numero: e.numero,
            bairro: e.bairro,
            cidade: e.cidade,
            estado: e.estado,
            uf: e.uf,
            nascimento: e.nascimento,
        })),
    };
    await makeRequest({name: "createUsersWithCSVRowUserJSON", args, progress});
    return undefined;
}

export async function loginAdminUser(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "loginAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getAdminUsers(filter: AdminUserFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<AdminUserResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            email: filter.email === null || filter.email === undefined ? null : filter.email,
            name: filter.name === null || filter.name === undefined ? null : filter.name,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            manager: e.manager === null || e.manager === undefined ? null : {
                id: e.manager.id,
                type: e.manager.type === null || e.manager.type === undefined ? null : e.manager.type,
                isSuperAdmin: !!e.manager.isSuperAdmin,
                permissions: e.manager.permissions.map((e: any) => e),
                name: e.manager.name,
                email: e.manager.email,
                phone: e.manager.phone,
            },
            coordinator: e.coordinator === null || e.coordinator === undefined ? null : {
                id: e.coordinator.id,
                type: e.coordinator.type === null || e.coordinator.type === undefined ? null : e.coordinator.type,
                isSuperAdmin: !!e.coordinator.isSuperAdmin,
                permissions: e.coordinator.permissions.map((e: any) => e),
                name: e.coordinator.name,
                email: e.coordinator.email,
                phone: e.coordinator.phone,
            },
            approved: e.approved === null || e.approved === undefined ? null : !!e.approved,
            userConfig: e.userConfig === null || e.userConfig === undefined ? null : {
                usersTableColumnOrder: e.userConfig.usersTableColumnOrder === null || e.userConfig.usersTableColumnOrder === undefined ? null : e.userConfig.usersTableColumnOrder.map((e: any) => e),
            },
            type: e.type === null || e.type === undefined ? null : e.type,
            isSuperAdmin: !!e.isSuperAdmin,
            permissions: e.permissions.map((e: any) => e),
            name: e.name,
            email: e.email,
            phone: e.phone,
        })),
    };
}

export async function getHomeData(progress?: (progress: number) => void): Promise<HomeData[]> {
    const ret = await makeRequest({name: "getHomeData", args: {}, progress});
    return ret.map((e: any) => ({
        name: e.name,
        yearly: e.yearly || 0,
        monthly: e.monthly || 0,
        daily: e.daily || 0,
    }));
}

export async function getHomeDataForAdmin(progress?: (progress: number) => void): Promise<HomeData> {
    const ret = await makeRequest({name: "getHomeDataForAdmin", args: {}, progress});
    return {
        name: ret.name,
        yearly: ret.yearly || 0,
        monthly: ret.monthly || 0,
        daily: ret.daily || 0,
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            managerId: newAdminUser.managerId === null || newAdminUser.managerId === undefined ? null : newAdminUser.managerId,
            coordinatorId: newAdminUser.coordinatorId === null || newAdminUser.coordinatorId === undefined ? null : newAdminUser.coordinatorId,
            password: newAdminUser.password === null || newAdminUser.password === undefined ? null : newAdminUser.password,
            type: newAdminUser.type === null || newAdminUser.type === undefined ? null : newAdminUser.type,
            isSuperAdmin: !!newAdminUser.isSuperAdmin,
            permissions: newAdminUser.permissions.map(e => e),
            name: newAdminUser.name,
            email: newAdminUser.email,
            phone: newAdminUser.phone,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function editAdminUser(adminUserId: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editAdminUser: {
            managerId: editAdminUser.managerId === null || editAdminUser.managerId === undefined ? null : editAdminUser.managerId,
            coordinatorId: editAdminUser.coordinatorId === null || editAdminUser.coordinatorId === undefined ? null : editAdminUser.coordinatorId,
            type: editAdminUser.type === null || editAdminUser.type === undefined ? null : editAdminUser.type,
            isSuperAdmin: !!editAdminUser.isSuperAdmin,
            permissions: editAdminUser.permissions.map(e => e),
            name: editAdminUser.name,
            email: editAdminUser.email,
            phone: editAdminUser.phone,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getPendingApprovalAdminUsers(pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<AdminUserResults> {
    const args = {
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getPendingApprovalAdminUsers", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            manager: e.manager === null || e.manager === undefined ? null : {
                id: e.manager.id,
                type: e.manager.type === null || e.manager.type === undefined ? null : e.manager.type,
                isSuperAdmin: !!e.manager.isSuperAdmin,
                permissions: e.manager.permissions.map((e: any) => e),
                name: e.manager.name,
                email: e.manager.email,
                phone: e.manager.phone,
            },
            coordinator: e.coordinator === null || e.coordinator === undefined ? null : {
                id: e.coordinator.id,
                type: e.coordinator.type === null || e.coordinator.type === undefined ? null : e.coordinator.type,
                isSuperAdmin: !!e.coordinator.isSuperAdmin,
                permissions: e.coordinator.permissions.map((e: any) => e),
                name: e.coordinator.name,
                email: e.coordinator.email,
                phone: e.coordinator.phone,
            },
            approved: e.approved === null || e.approved === undefined ? null : !!e.approved,
            userConfig: e.userConfig === null || e.userConfig === undefined ? null : {
                usersTableColumnOrder: e.userConfig.usersTableColumnOrder === null || e.userConfig.usersTableColumnOrder === undefined ? null : e.userConfig.usersTableColumnOrder.map((e: any) => e),
            },
            type: e.type === null || e.type === undefined ? null : e.type,
            isSuperAdmin: !!e.isSuperAdmin,
            permissions: e.permissions.map((e: any) => e),
            name: e.name,
            email: e.email,
            phone: e.phone,
        })),
    };
}

export async function getNotApprovedAdminUsers(pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<AdminUserResults> {
    const args = {
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getNotApprovedAdminUsers", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            manager: e.manager === null || e.manager === undefined ? null : {
                id: e.manager.id,
                type: e.manager.type === null || e.manager.type === undefined ? null : e.manager.type,
                isSuperAdmin: !!e.manager.isSuperAdmin,
                permissions: e.manager.permissions.map((e: any) => e),
                name: e.manager.name,
                email: e.manager.email,
                phone: e.manager.phone,
            },
            coordinator: e.coordinator === null || e.coordinator === undefined ? null : {
                id: e.coordinator.id,
                type: e.coordinator.type === null || e.coordinator.type === undefined ? null : e.coordinator.type,
                isSuperAdmin: !!e.coordinator.isSuperAdmin,
                permissions: e.coordinator.permissions.map((e: any) => e),
                name: e.coordinator.name,
                email: e.coordinator.email,
                phone: e.coordinator.phone,
            },
            approved: e.approved === null || e.approved === undefined ? null : !!e.approved,
            userConfig: e.userConfig === null || e.userConfig === undefined ? null : {
                usersTableColumnOrder: e.userConfig.usersTableColumnOrder === null || e.userConfig.usersTableColumnOrder === undefined ? null : e.userConfig.usersTableColumnOrder.map((e: any) => e),
            },
            type: e.type === null || e.type === undefined ? null : e.type,
            isSuperAdmin: !!e.isSuperAdmin,
            permissions: e.permissions.map((e: any) => e),
            name: e.name,
            email: e.email,
            phone: e.phone,
        })),
    };
}

export async function preregisterAdminUser(newAdminUser: NewAdminUserPublic, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password === null || newAdminUser.password === undefined ? null : newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            phone: newAdminUser.phone,
        },
    };
    const ret = await makeRequest({name: "preregisterAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function approveAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "approveAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function refuseAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "refuseAdminUser", args, progress});
    return undefined;
}

export async function approveAndEditAdminUser(adminUserId: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editAdminUser: {
            managerId: editAdminUser.managerId === null || editAdminUser.managerId === undefined ? null : editAdminUser.managerId,
            coordinatorId: editAdminUser.coordinatorId === null || editAdminUser.coordinatorId === undefined ? null : editAdminUser.coordinatorId,
            type: editAdminUser.type === null || editAdminUser.type === undefined ? null : editAdminUser.type,
            isSuperAdmin: !!editAdminUser.isSuperAdmin,
            permissions: editAdminUser.permissions.map(e => e),
            name: editAdminUser.name,
            email: editAdminUser.email,
            phone: editAdminUser.phone,
        },
    };
    const ret = await makeRequest({name: "approveAndEditAdminUser", args, progress});
    return {
        id: ret.id,
        manager: ret.manager === null || ret.manager === undefined ? null : {
            id: ret.manager.id,
            type: ret.manager.type === null || ret.manager.type === undefined ? null : ret.manager.type,
            isSuperAdmin: !!ret.manager.isSuperAdmin,
            permissions: ret.manager.permissions.map((e: any) => e),
            name: ret.manager.name,
            email: ret.manager.email,
            phone: ret.manager.phone,
        },
        coordinator: ret.coordinator === null || ret.coordinator === undefined ? null : {
            id: ret.coordinator.id,
            type: ret.coordinator.type === null || ret.coordinator.type === undefined ? null : ret.coordinator.type,
            isSuperAdmin: !!ret.coordinator.isSuperAdmin,
            permissions: ret.coordinator.permissions.map((e: any) => e),
            name: ret.coordinator.name,
            email: ret.coordinator.email,
            phone: ret.coordinator.phone,
        },
        approved: ret.approved === null || ret.approved === undefined ? null : !!ret.approved,
        userConfig: ret.userConfig === null || ret.userConfig === undefined ? null : {
            usersTableColumnOrder: ret.userConfig.usersTableColumnOrder === null || ret.userConfig.usersTableColumnOrder === undefined ? null : ret.userConfig.usersTableColumnOrder.map((e: any) => e),
        },
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        isSuperAdmin: !!ret.isSuperAdmin,
        permissions: ret.permissions.map((e: any) => e),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
    };
}

export async function setUserConfig(userConfig: UserConfig, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userConfig: {
            usersTableColumnOrder: userConfig.usersTableColumnOrder === null || userConfig.usersTableColumnOrder === undefined ? null : userConfig.usersTableColumnOrder.map(e => e),
        },
    };
    await makeRequest({name: "setUserConfig", args, progress});
    return undefined;
}

export async function getCardPrint(id: string[], progress?: (progress: number) => void): Promise<CardPrint> {
    const args = {
        id: id.map(e => e),
    };
    const ret = await makeRequest({name: "getCardPrint", args, progress});
    return {
        id: ret.id.map((e: any) => e),
        year: ret.year || 0,
        useCode: ret.useCode,
        createdAt: new Date(ret.createdAt + "Z"),
        userId: ret.userId,
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        status: ret.status,
        type: ret.type,
        payments: ret.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: ret.signature === null || ret.signature === undefined ? null : {
            cardPrintId: ret.signature.cardPrintId.map((e: any) => e),
            certHash: ret.signature.certHash,
            issuer: ret.signature.issuer,
            holder: ret.signature.holder,
            version: ret.signature.version,
            signature: ret.signature.signature,
            publicKey: ret.signature.publicKey,
            extensions: ret.signature.extensions.map((e: any) => e),
            serialNumber: ret.signature.serialNumber,
            signatureValue: ret.signature.signatureValue,
            attCertValidityPeriod: ret.signature.attCertValidityPeriod,
            attributes: ret.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        email: ret.email,
        phone: ret.phone,
    };
}

export async function getCardPrints(filter: CardPrintFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<PartialCardPrintResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            rg: filter.rg === null || filter.rg === undefined ? null : filter.rg,
            schooling: filter.schooling === null || filter.schooling === undefined ? null : filter.schooling,
            institutionId: filter.institutionId === null || filter.institutionId === undefined ? null : filter.institutionId,
            courseId: filter.courseId === null || filter.courseId === undefined ? null : filter.courseId,
            studentId: filter.studentId === null || filter.studentId === undefined ? null : filter.studentId,
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            isReprint: filter.isReprint === null || filter.isReprint === undefined ? null : !!filter.isReprint,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getCardPrints", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id.map((e: any) => e),
            useCode: e.useCode,
            userId: e.userId,
            name: e.name,
            createdAt: new Date(e.createdAt + "Z"),
            seller: e.seller === null || e.seller === undefined ? null : {
                id: e.seller.id,
                name: e.seller.name,
            },
            status: e.status,
            type: e.type,
            payments: e.payments.map((e: any) => ({
                id: e.id,
                amount: e.amount || 0,
                createdAt: new Date(e.createdAt + "Z"),
                cardPrintId: e.cardPrintId.map((e: any) => e),
                status: e.status,
            })),
        })),
    };
}

export async function getAllCardPrints(progress?: (progress: number) => void): Promise<CardPrint[]> {
    const ret = await makeRequest({name: "getAllCardPrints", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id.map((e: any) => e),
        year: e.year || 0,
        useCode: e.useCode,
        createdAt: new Date(e.createdAt + "Z"),
        userId: e.userId,
        seller: e.seller === null || e.seller === undefined ? null : {
            id: e.seller.id,
            name: e.seller.name,
        },
        status: e.status,
        type: e.type,
        payments: e.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: e.signature === null || e.signature === undefined ? null : {
            cardPrintId: e.signature.cardPrintId.map((e: any) => e),
            certHash: e.signature.certHash,
            issuer: e.signature.issuer,
            holder: e.signature.holder,
            version: e.signature.version,
            signature: e.signature.signature,
            publicKey: e.signature.publicKey,
            extensions: e.signature.extensions.map((e: any) => e),
            serialNumber: e.signature.serialNumber,
            signatureValue: e.signature.signatureValue,
            attCertValidityPeriod: e.signature.attCertValidityPeriod,
            attributes: e.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: e.avatar.thumb.width || 0,
                height: e.avatar.thumb.height || 0,
                url: e.avatar.thumb.url,
            },
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        name: e.name,
        birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        rg: {
            number: e.rg.number,
            institution: e.rg.institution,
        },
        schooling: e.schooling,
        studentId: e.studentId,
        institution: {
            id: e.institution.id,
            name: e.institution.name,
            state: e.institution.state,
            city: e.institution.city,
            isActive: !!e.institution.isActive,
        },
        course: {
            id: e.course.id,
            name: e.course.name,
            isActive: !!e.course.isActive,
        },
        email: e.email,
        phone: e.phone,
    }));
}

export async function getPendingCardPrints(filter: CardPrintFilter | null, pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<CardPrintResults> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            rg: filter.rg === null || filter.rg === undefined ? null : filter.rg,
            schooling: filter.schooling === null || filter.schooling === undefined ? null : filter.schooling,
            institutionId: filter.institutionId === null || filter.institutionId === undefined ? null : filter.institutionId,
            courseId: filter.courseId === null || filter.courseId === undefined ? null : filter.courseId,
            studentId: filter.studentId === null || filter.studentId === undefined ? null : filter.studentId,
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            isReprint: filter.isReprint === null || filter.isReprint === undefined ? null : !!filter.isReprint,
        },
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getPendingCardPrints", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id.map((e: any) => e),
            year: e.year || 0,
            useCode: e.useCode,
            createdAt: new Date(e.createdAt + "Z"),
            userId: e.userId,
            seller: e.seller === null || e.seller === undefined ? null : {
                id: e.seller.id,
                name: e.seller.name,
            },
            status: e.status,
            type: e.type,
            payments: e.payments.map((e: any) => ({
                id: e.id,
                amount: e.amount || 0,
                createdAt: new Date(e.createdAt + "Z"),
                cardPrintId: e.cardPrintId.map((e: any) => e),
                status: e.status,
            })),
            signature: e.signature === null || e.signature === undefined ? null : {
                cardPrintId: e.signature.cardPrintId.map((e: any) => e),
                certHash: e.signature.certHash,
                issuer: e.signature.issuer,
                holder: e.signature.holder,
                version: e.signature.version,
                signature: e.signature.signature,
                publicKey: e.signature.publicKey,
                extensions: e.signature.extensions.map((e: any) => e),
                serialNumber: e.signature.serialNumber,
                signatureValue: e.signature.signatureValue,
                attCertValidityPeriod: e.signature.attCertValidityPeriod,
                attributes: e.signature.attributes.map((e: any) => ({
                    type: e.type,
                    values: e.values.map((e: any) => e),
                })),
            },
            avatar: {
                thumb: {
                    width: e.avatar.thumb.width || 0,
                    height: e.avatar.thumb.height || 0,
                    url: e.avatar.thumb.url,
                },
                width: e.avatar.width || 0,
                height: e.avatar.height || 0,
                url: e.avatar.url,
            },
            name: e.name,
            birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
            cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
            rg: {
                number: e.rg.number,
                institution: e.rg.institution,
            },
            schooling: e.schooling,
            studentId: e.studentId,
            institution: {
                id: e.institution.id,
                name: e.institution.name,
                state: e.institution.state,
                city: e.institution.city,
                isActive: !!e.institution.isActive,
            },
            course: {
                id: e.course.id,
                name: e.course.name,
                isActive: !!e.course.isActive,
            },
            email: e.email,
            phone: e.phone,
        })),
    };
}

export async function getExportCardPrints(filter: CardPrintFilter | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filter: filter === null || filter === undefined ? null : {
            status: filter.status === null || filter.status === undefined ? null : filter.status,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            name: filter.name === null || filter.name === undefined ? null : filter.name,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            rg: filter.rg === null || filter.rg === undefined ? null : filter.rg,
            schooling: filter.schooling === null || filter.schooling === undefined ? null : filter.schooling,
            institutionId: filter.institutionId === null || filter.institutionId === undefined ? null : filter.institutionId,
            courseId: filter.courseId === null || filter.courseId === undefined ? null : filter.courseId,
            studentId: filter.studentId === null || filter.studentId === undefined ? null : filter.studentId,
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            isReprint: filter.isReprint === null || filter.isReprint === undefined ? null : !!filter.isReprint,
        },
    };
    const ret = await makeRequest({name: "getExportCardPrints", args, progress});
    return ret;
}

export async function requestCardPrint(newCardPrint: NewCardPrint, progress?: (progress: number) => void): Promise<CardPrint> {
    const args = {
        newCardPrint: {
            userId: newCardPrint.userId,
            printType: newCardPrint.printType,
        },
    };
    const ret = await makeRequest({name: "requestCardPrint", args, progress});
    return {
        id: ret.id.map((e: any) => e),
        year: ret.year || 0,
        useCode: ret.useCode,
        createdAt: new Date(ret.createdAt + "Z"),
        userId: ret.userId,
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        status: ret.status,
        type: ret.type,
        payments: ret.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: ret.signature === null || ret.signature === undefined ? null : {
            cardPrintId: ret.signature.cardPrintId.map((e: any) => e),
            certHash: ret.signature.certHash,
            issuer: ret.signature.issuer,
            holder: ret.signature.holder,
            version: ret.signature.version,
            signature: ret.signature.signature,
            publicKey: ret.signature.publicKey,
            extensions: ret.signature.extensions.map((e: any) => e),
            serialNumber: ret.signature.serialNumber,
            signatureValue: ret.signature.signatureValue,
            attCertValidityPeriod: ret.signature.attCertValidityPeriod,
            attributes: ret.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        email: ret.email,
        phone: ret.phone,
    };
}

export async function requestCardPrints(newCardPrints: NewCardPrint[], progress?: (progress: number) => void): Promise<CardPrint[]> {
    const args = {
        newCardPrints: newCardPrints.map(e => ({
            userId: e.userId,
            printType: e.printType,
        })),
    };
    const ret = await makeRequest({name: "requestCardPrints", args, progress});
    return ret.map((e: any) => ({
        id: e.id.map((e: any) => e),
        year: e.year || 0,
        useCode: e.useCode,
        createdAt: new Date(e.createdAt + "Z"),
        userId: e.userId,
        seller: e.seller === null || e.seller === undefined ? null : {
            id: e.seller.id,
            name: e.seller.name,
        },
        status: e.status,
        type: e.type,
        payments: e.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: e.signature === null || e.signature === undefined ? null : {
            cardPrintId: e.signature.cardPrintId.map((e: any) => e),
            certHash: e.signature.certHash,
            issuer: e.signature.issuer,
            holder: e.signature.holder,
            version: e.signature.version,
            signature: e.signature.signature,
            publicKey: e.signature.publicKey,
            extensions: e.signature.extensions.map((e: any) => e),
            serialNumber: e.signature.serialNumber,
            signatureValue: e.signature.signatureValue,
            attCertValidityPeriod: e.signature.attCertValidityPeriod,
            attributes: e.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: e.avatar.thumb.width || 0,
                height: e.avatar.thumb.height || 0,
                url: e.avatar.thumb.url,
            },
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        name: e.name,
        birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        rg: {
            number: e.rg.number,
            institution: e.rg.institution,
        },
        schooling: e.schooling,
        studentId: e.studentId,
        institution: {
            id: e.institution.id,
            name: e.institution.name,
            state: e.institution.state,
            city: e.institution.city,
            isActive: !!e.institution.isActive,
        },
        course: {
            id: e.course.id,
            name: e.course.name,
            isActive: !!e.course.isActive,
        },
        email: e.email,
        phone: e.phone,
    }));
}

export async function printCard(cardPrintId: string[], progress?: (progress: number) => void): Promise<CardPrint> {
    const args = {
        cardPrintId: cardPrintId.map(e => e),
    };
    const ret = await makeRequest({name: "printCard", args, progress});
    return {
        id: ret.id.map((e: any) => e),
        year: ret.year || 0,
        useCode: ret.useCode,
        createdAt: new Date(ret.createdAt + "Z"),
        userId: ret.userId,
        seller: ret.seller === null || ret.seller === undefined ? null : {
            id: ret.seller.id,
            name: ret.seller.name,
        },
        status: ret.status,
        type: ret.type,
        payments: ret.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: ret.signature === null || ret.signature === undefined ? null : {
            cardPrintId: ret.signature.cardPrintId.map((e: any) => e),
            certHash: ret.signature.certHash,
            issuer: ret.signature.issuer,
            holder: ret.signature.holder,
            version: ret.signature.version,
            signature: ret.signature.signature,
            publicKey: ret.signature.publicKey,
            extensions: ret.signature.extensions.map((e: any) => e),
            serialNumber: ret.signature.serialNumber,
            signatureValue: ret.signature.signatureValue,
            attCertValidityPeriod: ret.signature.attCertValidityPeriod,
            attributes: ret.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: ret.avatar.thumb.width || 0,
                height: ret.avatar.thumb.height || 0,
                url: ret.avatar.thumb.url,
            },
            width: ret.avatar.width || 0,
            height: ret.avatar.height || 0,
            url: ret.avatar.url,
        },
        name: ret.name,
        birthday: new Date(parseInt(ret.birthday.split("-")[0], 10), parseInt(ret.birthday.split("-")[1], 10) - 1, parseInt(ret.birthday.split("-")[2], 10)),
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        rg: {
            number: ret.rg.number,
            institution: ret.rg.institution,
        },
        schooling: ret.schooling,
        studentId: ret.studentId,
        institution: {
            id: ret.institution.id,
            name: ret.institution.name,
            state: ret.institution.state,
            city: ret.institution.city,
            isActive: !!ret.institution.isActive,
        },
        course: {
            id: ret.course.id,
            name: ret.course.name,
            isActive: !!ret.course.isActive,
        },
        email: ret.email,
        phone: ret.phone,
    };
}

export async function printCards(cardPrintIds: string[][], progress?: (progress: number) => void): Promise<CardPrint[]> {
    const args = {
        cardPrintIds: cardPrintIds.map(e => e.map(e => e)),
    };
    const ret = await makeRequest({name: "printCards", args, progress});
    return ret.map((e: any) => ({
        id: e.id.map((e: any) => e),
        year: e.year || 0,
        useCode: e.useCode,
        createdAt: new Date(e.createdAt + "Z"),
        userId: e.userId,
        seller: e.seller === null || e.seller === undefined ? null : {
            id: e.seller.id,
            name: e.seller.name,
        },
        status: e.status,
        type: e.type,
        payments: e.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: e.signature === null || e.signature === undefined ? null : {
            cardPrintId: e.signature.cardPrintId.map((e: any) => e),
            certHash: e.signature.certHash,
            issuer: e.signature.issuer,
            holder: e.signature.holder,
            version: e.signature.version,
            signature: e.signature.signature,
            publicKey: e.signature.publicKey,
            extensions: e.signature.extensions.map((e: any) => e),
            serialNumber: e.signature.serialNumber,
            signatureValue: e.signature.signatureValue,
            attCertValidityPeriod: e.signature.attCertValidityPeriod,
            attributes: e.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: e.avatar.thumb.width || 0,
                height: e.avatar.thumb.height || 0,
                url: e.avatar.thumb.url,
            },
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        name: e.name,
        birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        rg: {
            number: e.rg.number,
            institution: e.rg.institution,
        },
        schooling: e.schooling,
        studentId: e.studentId,
        institution: {
            id: e.institution.id,
            name: e.institution.name,
            state: e.institution.state,
            city: e.institution.city,
            isActive: !!e.institution.isActive,
        },
        course: {
            id: e.course.id,
            name: e.course.name,
            isActive: !!e.course.isActive,
        },
        email: e.email,
        phone: e.phone,
    }));
}

export async function contactFec(name: string, email: string, message: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        name: name,
        email: email,
        message: message,
    };
    await makeRequest({name: "contactFec", args, progress});
    return undefined;
}

export async function createCourse(newCourse: CourseDetails, progress?: (progress: number) => void): Promise<Course> {
    const args = {
        newCourse: {
            name: newCourse.name,
            isActive: !!newCourse.isActive,
        },
    };
    const ret = await makeRequest({name: "createCourse", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        isActive: !!ret.isActive,
    };
}

export async function editCourse(courseId: string, editCourse: CourseDetails, progress?: (progress: number) => void): Promise<Course> {
    const args = {
        courseId: courseId,
        editCourse: {
            name: editCourse.name,
            isActive: !!editCourse.isActive,
        },
    };
    const ret = await makeRequest({name: "editCourse", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        isActive: !!ret.isActive,
    };
}

export async function createInstitution(newInstitution: InstitutionDetails, progress?: (progress: number) => void): Promise<Institution> {
    const args = {
        newInstitution: {
            name: newInstitution.name,
            state: newInstitution.state,
            city: newInstitution.city,
            isActive: !!newInstitution.isActive,
        },
    };
    const ret = await makeRequest({name: "createInstitution", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        state: ret.state,
        city: ret.city,
        isActive: !!ret.isActive,
    };
}

export async function editInstitution(institutionId: string, editInstitution: InstitutionDetails, progress?: (progress: number) => void): Promise<Institution> {
    const args = {
        institutionId: institutionId,
        editInstitution: {
            name: editInstitution.name,
            state: editInstitution.state,
            city: editInstitution.city,
            isActive: !!editInstitution.isActive,
        },
    };
    const ret = await makeRequest({name: "editInstitution", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        state: ret.state,
        city: ret.city,
        isActive: !!ret.isActive,
    };
}

export async function getUsersLogsReportsWithStream(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "getUsersLogsReportsWithStream", args: {}, progress});
    return ret;
}

export async function getCompleteUserLogsById(userId: string, progress?: (progress: number) => void): Promise<Log[]> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getCompleteUserLogsById", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        text: e.text,
        adminUser: {
            id: e.adminUser.id,
            manager: e.adminUser.manager === null || e.adminUser.manager === undefined ? null : {
                id: e.adminUser.manager.id,
                type: e.adminUser.manager.type === null || e.adminUser.manager.type === undefined ? null : e.adminUser.manager.type,
                isSuperAdmin: !!e.adminUser.manager.isSuperAdmin,
                permissions: e.adminUser.manager.permissions.map((e: any) => e),
                name: e.adminUser.manager.name,
                email: e.adminUser.manager.email,
                phone: e.adminUser.manager.phone,
            },
            coordinator: e.adminUser.coordinator === null || e.adminUser.coordinator === undefined ? null : {
                id: e.adminUser.coordinator.id,
                type: e.adminUser.coordinator.type === null || e.adminUser.coordinator.type === undefined ? null : e.adminUser.coordinator.type,
                isSuperAdmin: !!e.adminUser.coordinator.isSuperAdmin,
                permissions: e.adminUser.coordinator.permissions.map((e: any) => e),
                name: e.adminUser.coordinator.name,
                email: e.adminUser.coordinator.email,
                phone: e.adminUser.coordinator.phone,
            },
            approved: e.adminUser.approved === null || e.adminUser.approved === undefined ? null : !!e.adminUser.approved,
            userConfig: e.adminUser.userConfig === null || e.adminUser.userConfig === undefined ? null : {
                usersTableColumnOrder: e.adminUser.userConfig.usersTableColumnOrder === null || e.adminUser.userConfig.usersTableColumnOrder === undefined ? null : e.adminUser.userConfig.usersTableColumnOrder.map((e: any) => e),
            },
            type: e.adminUser.type === null || e.adminUser.type === undefined ? null : e.adminUser.type,
            isSuperAdmin: !!e.adminUser.isSuperAdmin,
            permissions: e.adminUser.permissions.map((e: any) => e),
            name: e.adminUser.name,
            email: e.adminUser.email,
            phone: e.adminUser.phone,
        },
    }));
}

export async function getPayment(id: string, progress?: (progress: number) => void): Promise<Payment> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPayment", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        cardPrintId: ret.cardPrintId.map((e: any) => e),
        status: ret.status,
    };
}

export async function getPayments(pageOffset: number, numberPerPage: number, progress?: (progress: number) => void): Promise<PaymentResults> {
    const args = {
        pageOffset: pageOffset || 0,
        numberPerPage: numberPerPage || 0,
    };
    const ret = await makeRequest({name: "getPayments", args, progress});
    return {
        pageOffset: ret.pageOffset || 0,
        numberPerPage: ret.numberPerPage || 0,
        total: ret.total || 0,
        results: ret.results.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
    };
}

export async function createPaymentForCardPrint(cardPrintId: string[], progress?: (progress: number) => void): Promise<Payment> {
    const args = {
        cardPrintId: cardPrintId.map(e => e),
    };
    const ret = await makeRequest({name: "createPaymentForCardPrint", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        cardPrintId: ret.cardPrintId.map((e: any) => e),
        status: ret.status,
    };
}

export async function cancelCardPrintPayment(cardPrintId: string[], progress?: (progress: number) => void): Promise<Payment> {
    const args = {
        cardPrintId: cardPrintId.map(e => e),
    };
    const ret = await makeRequest({name: "cancelCardPrintPayment", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        cardPrintId: ret.cardPrintId.map((e: any) => e),
        status: ret.status,
    };
}

export async function getPaymentForCardPrint(cardPrintId: string[], progress?: (progress: number) => void): Promise<Payment> {
    const args = {
        cardPrintId: cardPrintId.map(e => e),
    };
    const ret = await makeRequest({name: "getPaymentForCardPrint", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        cardPrintId: ret.cardPrintId.map((e: any) => e),
        status: ret.status,
    };
}

export async function getUnsignedCards(progress?: (progress: number) => void): Promise<CardPrint[]> {
    const ret = await makeRequest({name: "getUnsignedCards", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id.map((e: any) => e),
        year: e.year || 0,
        useCode: e.useCode,
        createdAt: new Date(e.createdAt + "Z"),
        userId: e.userId,
        seller: e.seller === null || e.seller === undefined ? null : {
            id: e.seller.id,
            name: e.seller.name,
        },
        status: e.status,
        type: e.type,
        payments: e.payments.map((e: any) => ({
            id: e.id,
            amount: e.amount || 0,
            createdAt: new Date(e.createdAt + "Z"),
            cardPrintId: e.cardPrintId.map((e: any) => e),
            status: e.status,
        })),
        signature: e.signature === null || e.signature === undefined ? null : {
            cardPrintId: e.signature.cardPrintId.map((e: any) => e),
            certHash: e.signature.certHash,
            issuer: e.signature.issuer,
            holder: e.signature.holder,
            version: e.signature.version,
            signature: e.signature.signature,
            publicKey: e.signature.publicKey,
            extensions: e.signature.extensions.map((e: any) => e),
            serialNumber: e.signature.serialNumber,
            signatureValue: e.signature.signatureValue,
            attCertValidityPeriod: e.signature.attCertValidityPeriod,
            attributes: e.signature.attributes.map((e: any) => ({
                type: e.type,
                values: e.values.map((e: any) => e),
            })),
        },
        avatar: {
            thumb: {
                width: e.avatar.thumb.width || 0,
                height: e.avatar.thumb.height || 0,
                url: e.avatar.thumb.url,
            },
            width: e.avatar.width || 0,
            height: e.avatar.height || 0,
            url: e.avatar.url,
        },
        name: e.name,
        birthday: new Date(parseInt(e.birthday.split("-")[0], 10), parseInt(e.birthday.split("-")[1], 10) - 1, parseInt(e.birthday.split("-")[2], 10)),
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        rg: {
            number: e.rg.number,
            institution: e.rg.institution,
        },
        schooling: e.schooling,
        studentId: e.studentId,
        institution: {
            id: e.institution.id,
            name: e.institution.name,
            state: e.institution.state,
            city: e.institution.city,
            isActive: !!e.institution.isActive,
        },
        course: {
            id: e.course.id,
            name: e.course.name,
            isActive: !!e.course.isActive,
        },
        email: e.email,
        phone: e.phone,
    }));
}

export async function createCardSignature(newSignature: NewSignature, progress?: (progress: number) => void): Promise<void> {
    const args = {
        newSignature: {
            issuer: newSignature.issuer,
            holder: newSignature.holder,
            signature: newSignature.signature,
            cardPrintId: newSignature.cardPrintId.map(e => e),
            extensions: newSignature.extensions.map(e => e),
            version: newSignature.version,
            publicKey: newSignature.publicKey,
            certHash: newSignature.certHash,
            signatureValue: newSignature.signatureValue,
        },
    };
    await makeRequest({name: "createCardSignature", args, progress});
    return undefined;
}

export async function createMultipleCardSignatures(newSignatures: NewSignature[], progress?: (progress: number) => void): Promise<void> {
    const args = {
        newSignatures: newSignatures.map(e => ({
            issuer: e.issuer,
            holder: e.holder,
            signature: e.signature,
            cardPrintId: e.cardPrintId.map(e => e),
            extensions: e.extensions.map(e => e),
            version: e.version,
            publicKey: e.publicKey,
            certHash: e.certHash,
            signatureValue: e.signatureValue,
        })),
    };
    await makeRequest({name: "createMultipleCardSignatures", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
