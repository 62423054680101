import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import InstitutionStore from "../../../../stores/InstitutionStore";
import AuthStore from "../../../../stores/AuthStore";
import UIStore from "../../../../stores/UIStore";

// Components
import Toolbar from "@material-ui/core/Toolbar";
import Table from "../../../../components/Table";
import Filter from "../../../../components/Filter";

// Resources
import strings from "../../../../resources/strings";

interface IProps {
	institutionStore: InstitutionStore;
	routerStore: RouterStore;
	authStore: AuthStore;
	uiStore: UIStore;
}

@inject("institutionStore", "routerStore", "uiStore")
@observer
export default class InstitutionsContainer extends React.Component<IProps> {
	public async componentDidMount() {
		await this.props.institutionStore.clear();
		await this.props.institutionStore.getInstitutions();
	}

	public render() {
		const { institutionStore } = this.props;

		return (
			<>
				<Table
					headerView={
						<Toolbar>
							<Filter
								disabled={institutionStore.loading}
								fields={institutionStore.institutionFilterStore.fields}
							/>
						</Toolbar>
					}
					title={strings.institutions.table.title}
					loading={institutionStore.loading}
					prevPage={institutionStore.previousPage}
					nextPage={institutionStore.nextPage}
					header={institutionStore.tableHeader}
					data={institutionStore.tableRows}
					pageOffset={institutionStore.pageOffset}
					onAddClick={() => institutionStore.createOrEditInstitution()}
					onEditClick={(institutionId: string) => institutionStore.createOrEditInstitution(institutionId)}
					rowsPerPageConfigs={{
						rowsPerPage: institutionStore.rowsPerPage,
						onRowsPerPageChange: institutionStore.handleSelectElementChange,
						refetchData: institutionStore.fetchData,
						info: institutionStore.tableRowRange,
					}}
				/>
			</>
		);
	}
}
