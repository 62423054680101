import * as React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

// Mobx
import { Provider } from "mobx-react";
import { rootStore } from "./stores/_rootStore";

// Routing
import { Router, Route, Switch, Redirect } from "react-router-dom";
import * as history from "history";
import { syncHistoryWithStore } from "mobx-react-router";
const browserHistory = history.createBrowserHistory();
syncHistoryWithStore(browserHistory, rootStore.routerStore);

// Pages
import Auth from "./pages/Auth";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Recovery from "./pages/Recovery";
import Register from "./pages/Register";

// Date Picker
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

// API
import * as api from "./admin-api-web";
const productionUrl: string = "https://api.souestudante.org/admin";
const stagingUrl: string = "https://api.souestudante.org/admin-staging";
api.setUrl(process.env.NODE_ENV ?
	((): string => {
		switch (process.env.NODE_ENV) {
			case "production": {
				return productionUrl;
			}
			case "staging":
			default: {
				return stagingUrl;
			}
		}
	})() : stagingUrl,
);

ReactDOM.render(
    <Provider {...rootStore}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Router history={rootStore.routerStore.history}>
                <Switch>
                    <Route exact path="/" component={Auth} />
                    <Route path="/dashboard" component={Dashboard}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/recovery" component={Recovery}/>
                    <Route path="/register" component={Register}/>
                </Switch>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById("app"),
);
