import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";
import { uiStore } from "../_rootStore";

export interface IRowItem {
	data: Array<{
		value: string;
	}>;
}

export default class HomeStore extends VariableChangeHandler {
    // Variables
	@observable public loader: boolean = false;
    @observable public homeData: api.HomeData[] = [];
    @observable public homeDataForAdmin: api.HomeData | null = null;

	@action
	public getHomeData = async () => {
		this.loader = true;
		try {
			const response = await api.getHomeData();
			this.homeData = response;
		} catch (err) {
			uiStore.openErrorSnackbar(err);
		} finally {
			this.loader = false;
		}
	}

	@action
	public getUserHomeData = async () => {
		this.loader = true;
		try {
			const request = await api.getHomeDataForAdmin();
			this.homeDataForAdmin = request;
		} catch (err) {
			uiStore.openErrorSnackbar(err);
		} finally {
			this.loader = false;
		}
	}

    @computed
    public get homeDataRow(): IRowItem[] {
        return this.homeData.map((data) => ({
            data: [
                { value: data.name },
                { value: data.yearly.toString() },
                { value: data.monthly.toString()},
                { value: data.daily.toString()},
            ],
        }));
    }
}
