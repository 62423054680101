import { inspect } from "util";

import * as api from "../../admin-api-web";
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { observable, action, computed, reaction } from "mobx";
import { uiStore, routerStore, authStore } from "../_rootStore";

// Services
import AutocompleteAdminUserService from "../../service/AutocompleteAdminUserService";

// Components
import { IRowItem } from "../../components/Table/TableRow";

// Resources
import strings from "../../resources/strings";

// Stores
import AdminUserFilterStore from "./AdminUserFilterStore";

export default class TeamStore extends VariableChangeHandler {
    // Services
    public autocompleteAdminUserService: AutocompleteAdminUserService = new AutocompleteAdminUserService();

    // Sub Stores
    public adminUserFilterStore: AdminUserFilterStore = new AdminUserFilterStore();

    // Control
    @observable public loading: boolean = false;
    @observable public onEditMode: boolean = false;
    @observable public pageOffset: number = 0;
    @observable public selectedAdminUser: api.AdminUser | null = null;

    // Variables
    @observable public adminUsers: api.AdminUser[] = [];

    @observable public name: string = "";
    @observable public email: string = "";
    @observable public password: string = "";
    @observable public phone: string = "";
    @observable public isSuperAdmin: boolean = false;
    @observable public permissions: api.Permission[] = [];
    @observable public type: api.AdminUserType | null = api.AdminUserType.seller;
    @observable public superiorAdminUser: api.LeaderUser | null = null;

    // Methods
    constructor() {
        super();

        reaction(() => this.selectedAdminUser, (selectedAdminUser) => {
            if (selectedAdminUser) {
                const index = this.adminUsers.findIndex((user) => user.id === selectedAdminUser.id);

                if (index !== -1) {
                    this.adminUsers[index] = selectedAdminUser;
                }
            }
        });

        reaction(() => this.adminUserFilterStore.filter, async (filter) => {
            this.pageOffset = 0;
            this.adminUsers = [];
            await this.getAdminUsers(this.pageOffset);
        });
    }

    @observable public rowsPerPage: number = 10;
    @observable public totalOfRows: number = 25;

    @computed
    public get tableRowRange() {

        const page = this.pageOffset + 1;
        let end = this.rowsPerPage * page;
        const start = end - this.rowsPerPage;

        if (end > this.totalOfRows) {
            end =  this.totalOfRows;
        }

        return `${start} - ${end} de ${this.totalOfRows}`;
    }

    @action
    public selectSuperiorAdminUser = async (adminUserId: string) => {
        await this.autocompleteAdminUserService.selectAdminUser(adminUserId);
        this.superiorAdminUser = this.autocompleteAdminUserService.adminUser;
    }

    @action
    public toggleSuperAdmin = () => {
        this.isSuperAdmin = !this.isSuperAdmin;
    }

    @computed
    public get getTypeOptions() {
        return (() => {
            if (!authStore.adminUser) {
                return [];
            }

            if (authStore.isSuperUser) {
                return api.allValuesAdminUserType();
            }

            switch (authStore.adminUser.type) {
                case api.AdminUserType.manager: {
                    return [
                        api.AdminUserType.coordinator,
                        api.AdminUserType.seller,
                    ];
                }
                case api.AdminUserType.coordinator: {
                    return [
                        api.AdminUserType.seller,
                    ];
                }
                default:
                case api.AdminUserType.seller: {
                    return [];
                }
            }
        })().map((adminUserType) => api.translateAdminUserType(adminUserType));
    }

    @computed
    public get tableHeader(): string[] {
        return [
            strings.adminUsers.table.header.name,
            strings.adminUsers.table.header.email,
            strings.adminUsers.table.header.phone,
            strings.adminUsers.table.header.role,
            strings.adminUsers.table.header.canManageCourses,
            strings.adminUsers.table.header.canManageInstitutions,
            strings.adminUsers.table.header.canManageStudents,
            strings.adminUsers.table.header.canPrintCard,
            strings.adminUsers.table.header.canManagePayments,
        ];
    }

    private isAbleTo = (adminUser: api.AdminUser, permission: api.Permission) => {
        const hasPermission = !!adminUser.permissions.find((perm) => perm === permission);
        if (adminUser.isSuperAdmin || hasPermission) {
            return strings.adminUsers.yes;
        } else {
            return strings.adminUsers.no;
        }
    }

    @computed
    public get tableRows(): IRowItem[] {
        return this.adminUsers.map((adminUser) => ({
            id: adminUser.id,
            data: [
                { value: adminUser.name },
                { value: adminUser.email },
                { value: adminUser.phone },
                {
                    value: adminUser.isSuperAdmin ?
                        strings.adminUsers.roles.superAdmin :
                        (adminUser.type ?
                            api.translateAdminUserType(adminUser.type) :
                            ""
                        ),
                },
                { value: this.isAbleTo(adminUser, api.Permission.manageCourses) },
                { value: this.isAbleTo(adminUser, api.Permission.manageInstitutions) },
                { value: this.isAbleTo(adminUser, api.Permission.manageStudents) },
                { value: this.isAbleTo(adminUser, api.Permission.printCard) },
                { value: this.isAbleTo(adminUser, api.Permission.managePayments) },
            ],
        }));
    }

    @action
    public fetchInitialAdminUsers = async () => {
        if (this.adminUsers.length === 0) {
            this.getAdminUsers(0);
        }
    }

    @action
    public fetchData = async () => {
        await this.getAdminUsers(0);
    }

    @action
    public getAdminUsers = async (pageOffset?: number) => {
        if (this.loading) return;

        this.loading = true;

        if (!pageOffset) {
            pageOffset = 0;
        }

        if (pageOffset < 0) {
            this.loading = false;
            return;
        }

        try {
            const newUsers = await api.getAdminUsers(this.adminUserFilterStore.filter, pageOffset, this.rowsPerPage);

            this.totalOfRows = newUsers.total;

            if (newUsers.results.length > 0) {
                this.adminUsers = newUsers.results;
                this.pageOffset = pageOffset;
            } else {
                uiStore.openSnackbar(strings.lists.noMoreResults);
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    @action
    public nextPage = async () => await this.getAdminUsers(this.pageOffset + 1);

    @action
    public previousPage = async () => await this.getAdminUsers(this.pageOffset - 1);

    @action
    public initializeEditor = async (userId: string) => {
        if (userId) {
            this.setEditMode(true);
            await this.selectUser(userId);
        } else {
            this.setEditMode(false);
        }
    }

    @action
    public selectUser = async (adminUserId: string) => {
        if (this.loading)
            return;

        try {
            this.loading = true;
            this.selectedAdminUser = null;

            const selectedAdminUser = await this.adminUsers.find((adminUser) => adminUser.id === adminUserId) || await api.getAdminUser(adminUserId);

            if (selectedAdminUser) {
                this.setUserFields(selectedAdminUser);
                this.selectedAdminUser = selectedAdminUser;
            } else {
                uiStore.openSnackbar(strings.adminUsers.errors.adminUserNotFound);
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }
            uiStore.openSnackbar(errorMessage);
            return null;
        } finally {
            this.loading = false;
        }
        return this.selectedAdminUser;
    }

    @action
    public saveUser = async () => {
        if (this.loading)
            return;

        this.loading = true;

        this.setAdminUserPermissions();

        const adminUser = {
            name: this.name,
            email: this.email,
            phone: this.phone,
            coordinatorId: this.type === api.AdminUserType.seller && this.superiorAdminUser ?
                this.superiorAdminUser.id :
                null,
            managerId: this.type === api.AdminUserType.coordinator && this.superiorAdminUser ?
                this.superiorAdminUser.id :
                null,
            isSuperAdmin: this.isSuperAdmin,
            permissions: this.isSuperAdmin ? [] : this.permissions,
            type: this.isSuperAdmin ? null : this.type,
        };

        try {
            if (this.onEditMode) {
                if (this.selectedAdminUser) {
                    const selectedAdminUser = await api.editAdminUser(this.selectedAdminUser.id, adminUser);
                    this.selectedAdminUser = selectedAdminUser;
                } else {
                    uiStore.openSnackbar(strings.adminUsers.errors.adminUserNotFound);
                }
            } else {
                const selectedAdminUser = await api.createAdminUser({ ...adminUser, password: null });
                this.selectedAdminUser = selectedAdminUser;
            }

            await this.getAdminUsers(0);
            routerStore.replace("/dashboard/team");
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }

    // FIXME: WHEN NEW PERMISSIONS WILL BE ADDED, IT`s GOING TO GENERATE INCONSISTANCIES
    @action
    public setAdminUserPermissions = () => {
        if (this.isSuperAdmin) {
            this.permissions = [];
            return;
        }

        switch (this.type) {
            case api.AdminUserType.manager:
            case api.AdminUserType.coordinator: {
                this.permissions = [
                    api.Permission.manageInstitutions,
                    api.Permission.manageCourses,
                    api.Permission.manageStudents,
                    api.Permission.printCard,
                    api.Permission.managePayments,
                ];

                break;
            }
            case api.AdminUserType.seller: {
                this.permissions = [
                    api.Permission.manageStudents,
                ];

                break;
            }
            default: {
                this.permissions = [];

                break;
            }
        }
    }

    @action public goToNewUser = () => {
        this.onEditMode = false;
        this.clearEditorFields();
        routerStore.push("/dashboard/team/new");
    }

    @action
    public goToEditUser = async (userId: string) => {
        await this.selectUser(userId);
        routerStore.push(`/dashboard/team/edit/${userId}`);
    }

    @action
    public goToUserHistory = async (userId: string) => {
        await this.selectUser(userId);
        routerStore.push(`/dashboard/logs/${userId}`);
    }

    @action
    public setEditMode = (mode: boolean) => {
        this.onEditMode = mode;
    }

    @action
    public setUserFields = async (selectedUser: api.AdminUser) => {
        this.name = selectedUser.name;
        this.email = selectedUser.email;
        this.phone = selectedUser.phone;
        this.superiorAdminUser = selectedUser.coordinator || selectedUser.manager;
        this.isSuperAdmin = selectedUser.isSuperAdmin;
        this.permissions = selectedUser.permissions;
        this.type = selectedUser.isSuperAdmin ? api.AdminUserType.manager : selectedUser.type;
    }

    @action private clearEditorFields = () => {
        this.name = "";
        this.email = "";
        this.phone = "";
        this.superiorAdminUser = null;
        this.isSuperAdmin = false;
        this.permissions = [];
        this.type = api.AdminUserType.seller;
    }

    @action public closeEditor = () => {
        routerStore.replace("/dashboard/team");
        this.clearEditorFields();
    }
    // AdminUser Pending Analysis
    @observable public adminUsersPendingAnalysis: api.AdminUser[] = [];
    @observable public adminUsersPendingAnalysisLoading: boolean = false;
    @observable public adminUsersPendingAnalysisPageOffset: number = 0;

    @computed
    public get adminUserPendingAnalysisTableRows(): IRowItem[] {
        return this.adminUsersPendingAnalysis.map((adminUser) => ({
            id: adminUser.id,
            data: [
                { value: adminUser.name },
                { value: adminUser.email },
                { value: adminUser.phone },
                {
                    value: adminUser.isSuperAdmin ?
                        strings.adminUsers.roles.superAdmin :
                        (adminUser.type ?
                            api.translateAdminUserType(adminUser.type) :
                            ""
                        ),
                },
                { value: this.isAbleTo(adminUser, api.Permission.manageCourses) },
                { value: this.isAbleTo(adminUser, api.Permission.manageInstitutions) },
                { value: this.isAbleTo(adminUser, api.Permission.manageStudents) },
                { value: this.isAbleTo(adminUser, api.Permission.printCard) },
                { value: this.isAbleTo(adminUser, api.Permission.managePayments) },
            ],
        }));
    }

    @action
    public getAdminUsersPendingAnalysis = async (adminUsersPendingAnalysisPageOffset?: number) => {
        if (this.adminUsersPendingAnalysisLoading) return;

        this.adminUsersPendingAnalysisLoading = true;

        if (!adminUsersPendingAnalysisPageOffset) {
            adminUsersPendingAnalysisPageOffset = 0;
        }

        if (adminUsersPendingAnalysisPageOffset < 0) {
            this.adminUsersPendingAnalysisLoading = false;
            return;
        }

        try {
            const newUsersPendingAnalysis = await api.getPendingApprovalAdminUsers(adminUsersPendingAnalysisPageOffset, this.rowsPerPage);

            if (newUsersPendingAnalysis.results.length > 0) {
                this.adminUsersPendingAnalysis = newUsersPendingAnalysis.results;
                this.adminUsersPendingAnalysisPageOffset = adminUsersPendingAnalysisPageOffset;
            }
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.adminUsersPendingAnalysisLoading = false;
        }
    }

    @action
    public nextPageAdminUserPendingAnalysis = async () => await this.getAdminUsersPendingAnalysis(this.adminUsersPendingAnalysisPageOffset + 1);

    @action
    public previousPageAdminUserPendingAnalysis = async () => await this.getAdminUsersPendingAnalysis(this.adminUsersPendingAnalysisPageOffset - 1);

    @action
    public wantToApproveAdminUser = async (adminUserId: string) => {
        uiStore.showDialog(strings.adminUsers.wantToApproveAdminUserDialog, async () => {
            await this.approveAdminUser(adminUserId);
        });
    }

    @action
    public approveAdminUser = async (adminUserId: string) => {
        try {
            await api.approveAdminUser(adminUserId);
            this.adminUsersPendingAnalysis = this.adminUsersPendingAnalysis.filter((adminUser) => adminUser.id !== adminUserId);

            await this.getAdminUsers();
        } catch (e) {
            uiStore.openSnackbar(strings.adminUsers.errors.approvalFailed);
        }
    }

    @action
    public wantToDisapproveAdminUser = async (adminUserId: string) => {
        uiStore.showDialog(strings.adminUsers.wantToDisapproveAdminUserDialog, async () => {
            await this.disapproveAdminUser(adminUserId);
        });
    }

    @action
    public disapproveAdminUser = async (adminUserId: string) => {
        try {
            await api.refuseAdminUser(adminUserId);
            this.adminUsers = this.adminUsers.filter((adminUser) => adminUser.id !== adminUserId);

            await this.getAdminUsersPendingAnalysis();
        } catch (e) {
            uiStore.openSnackbar(strings.adminUsers.errors.refuseFailed);
        }
    }

    @action
    public preregisterAdminUser = async () => {
        if (this.loading) {
            return;
        }

        this.loading = true;
        try {
            if (this.password.length < 6) {
                uiStore.showAlert({
                    title: strings.dialogs.fieldValidation.title,
                    message: strings.adminUsers.small.limit,
                });
                return;
            }

            await api.preregisterAdminUser({
                name: this.name,
                email: this.email,
                phone: this.phone,
                password: this.password,
            });

            routerStore.replace("/login");
        } catch (e) {
            let errorMessage: string;

            if (e.message) {
                errorMessage = e.message;
            } else {
                errorMessage = inspect(e);
            }

            uiStore.openSnackbar(errorMessage);
        } finally {
            this.loading = false;
        }
    }
}
