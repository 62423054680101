// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

// MARK: Stores
import CardPrintStore from "../../../../stores/CardPrintStore";

// MARK: Components
import ConfirmPrint from "../../../../components/ConfirmPrint";
import Table from "../../../../components/Table";
import ActiveSelectionToobar from "./ActiveSelectionTableToolbar";
import CardPrintTableToolbar from "./CardPrintTableToolbar";
import Toolbar from "@material-ui/core/Toolbar";
import Filter from "../../../../components/Filter";

interface IProps {
	match: Match<{ userId: string }>;
	cardPrintStore: CardPrintStore;
}

interface IState {
	isConfirmingPrint: boolean;
}

@inject("cardPrintStore")
@observer
export default class CardPrintTable extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);

		this.state = {
			isConfirmingPrint: false,
		};
	}

	public componentDidMount = async () => {
		await this.props.cardPrintStore.clear();
		await this.props.cardPrintStore.getCardPrints();
	}

	public render() {
		const { cardPrintStore } = this.props;

		return (
			!this.state.isConfirmingPrint ?
				<Table
				headerView={
					<Toolbar>
						<div className="usersTableHeaderViewContainer">
							<Filter
								disabled={cardPrintStore.loading}
								fields={cardPrintStore.userFilterStore.fields}
							/>
						</div>
					</Toolbar>
				}
					title={strings.cardPrint.table.title}
					data={cardPrintStore.tableRows}
					loading={cardPrintStore.loading}
					nextPage={cardPrintStore.nextPage}
					header={cardPrintStore.tableHeader}
					pageOffset={cardPrintStore.pageOffset}
					prevPage={cardPrintStore.previousPage}
					onRowSelect={(id) => cardPrintStore.toggleSelection(id)}
					selectAll={cardPrintStore.selectAll}
					rowsPerPageConfigs={{
						rowsPerPage: cardPrintStore.rowsPerPage,
						onRowsPerPageChange: cardPrintStore.handleSelectElementChange,
						refetchData: cardPrintStore.getData,
						info: cardPrintStore.tableRowRange,
					}}
					toolbar={
						<CardPrintTableToolbar
							cardPrintStore={cardPrintStore}
						/>
					}
					activeSelectionToolbar={
						<ActiveSelectionToobar
							setSelectedToPaid={cardPrintStore.setSelectionToPaid}
							setSelectedToPending={cardPrintStore.setSelectionToPending}
							numberOfSelected={cardPrintStore.selectionLength}
							onDeSelectAll={cardPrintStore.clearSelection}
							onPrintRequested={() =>  this.setState({ isConfirmingPrint: true })}
						/>
					}
				/> :
				<ConfirmPrint
					confirmDialog={strings.cardPrint.confirmPrint.confirmPrint.dialog}
					title={strings.cardPrint.confirmPrint.confirmPrint.title}
					confirmButtonText={strings.cardPrint.confirmPrint.confirmPrint.button}
					cardsInfo={cardPrintStore.selection.map((cardPrint) => ({ ...cardPrint, id: cardPrint.id.join() }))}
					loading={cardPrintStore.loading}
					onConfirm={async () => {
						await cardPrintStore.printSelected();
						this.setState({ isConfirmingPrint: false });
					}}
					onCancel={() => {
						this.setState({ isConfirmingPrint: false });
					}}
				/>
		);
	}
}
