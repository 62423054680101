import * as api from "../../../admin-api-web";
import VariableChangeHandler from "../../../resources/VariableChangeHandler";
import { observable, action, computed } from "mobx";

// Components
import { IFilterField } from "../../../components/Filter";

// Resources
import strings from "../../../resources/strings";

export default class InstitutionFilterStore extends VariableChangeHandler {
    // Variables
    @observable public name: string | null = null;
    @observable public state: api.StateUF | null = null;
    @observable public city: string | null = null;
    @observable public isActive: boolean | null = null;

    // Methods
    @computed
    public get filter(): api.InstitutionFilter {
        return {
            name: this.name,
            state: this.state,
            city: this.city,
            isActive: this.isActive,
        };
    }

    @computed
    public get fields(): IFilterField[] {
        return [
            {
                id: "name",
                value: strings.institutions.filter.name,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.name,
                clear: () => {
                    this.name = null;
                },
            },
            {
                id: "state",
                value: strings.institutions.filter.state,
                type: "enumPicker",
                enumPicker: {
                    onChange: this.handleValueChange,
                    options: api.allDisplayableValuesStateUF(),
                    valueFromOption: api.valueFromTranslationStateUF,
                },
                currentValue: this.state ? api.translateStateUF(this.state) : null,
                clear: () => {
                    this.state = null;
                },
            },
            {
                id: "city",
                value: strings.institutions.filter.city,
                type: "textField",
                textField: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.city,
                clear: () => {
                    this.city = null;
                },
            },
            {
                id: "isActive",
                value: strings.institutions.filter.isActive,
                type: "checkbox",
                checkbox: {
                    onChange: this.handleValueChange,
                },
                currentValue: this.isActive,
                clear: () => {
                    this.isActive = null;
                },
            },
        ];
    }

    @action
    public clear = () => {
        this.name = null;
    }
}
