// MARK: API
import * as api from "../admin-api-web";

// MARK: Libraries
import moment from "moment";

export interface ICardInfo {
	id: string;
	studentId: string;
	avatar: api.Image | null;
	name: string;
	institution: api.Institution;
	schooling: api.Schooling;
	course: api.Course;
	cpf: string | null;
	rg: api.RG;
	birthday: Date;
	useCode?: string | null;
}

export interface ICardInfoString {
	id: string;
	studentId: string;
	avatar: string;
	name: string;
	institution: string;
	schooling: string;
	course: string;
	cpf: string;
	rg: string;
	birthday: string;
	useCode: string;
	qrcodeUrl: string;
}

export function formatCardInfo(cardInfo: ICardInfo): ICardInfoString {
	const useCode = (cardInfo.useCode || "XXXXXX");

    return {
        ...cardInfo,
        avatar: cardInfo.avatar ? cardInfo.avatar.url : "/userPlaceholder.svg",
        name: cardInfo.name.slice(0, 34).toUpperCase(),
        institution: cardInfo.institution.name.slice(0, 34).toUpperCase(),
        course: cardInfo.course.name.slice(0, 34).toUpperCase(),
        cpf: cardInfo.cpf ? cardInfo.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4") : "Não possui cpf",
        rg: cardInfo.rg.number,
		birthday: moment(cardInfo.birthday).format("DD/MM/YYYY"),
		schooling: api.translateSchooling(cardInfo.schooling).toUpperCase(),
		qrcodeUrl: `souestudante.org/verify-document/${useCode}/${moment(cardInfo.birthday).format("DD-MM-YYYY")}`,
		useCode,
    };
}
